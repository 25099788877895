import React from "react";
import { Link, useMatch } from "react-router-dom";

import { Icon } from "@/components/Icon";
import { Nav, NavBody, NavHeader, NavItem } from "@/components/Nav";
import { HasGroup } from "@/containers/HasGroup";

const NavRouterLink = (props: { to: string; children: React.ReactNode }) => {
  const current = useMatch(props.to);

  return (
    <Link to={props.to}>
      <NavItem aria-selected={!!current}>{props.children}</NavItem>
    </Link>
  );
};

export const TasksNav = () => {
  return (
    <Nav aria-label="Tasks">
      <NavHeader>Tasks</NavHeader>
      <NavBody>
        {/*<HasGroup group="cleaner">*/}
        {/*  <NavRouterLink to="/tasks/cleaning">*/}
        {/*    <Icon name="magic" />*/}
        {/*    Cleaning*/}
        {/*  </NavRouterLink>*/}
        {/*</HasGroup>*/}
        <HasGroup group="validator">
          <NavRouterLink to="/tasks/validating">
            <Icon name="check-circle-fill" />
            Validating
          </NavRouterLink>
        </HasGroup>
        {/*<NavRouterLink to="/tasks/has-issues">*/}
        {/*  <Icon name="warning" />*/}
        {/*  Has issues*/}
        {/*</NavRouterLink>*/}
        <HasGroup group="quality_controller">
          <NavRouterLink to="/tasks/quality-control">
            <Icon name="lock-shield" />
            Quality control
          </NavRouterLink>
        </HasGroup>
        <HasGroup group="potential_duplicate_validator">
          <NavRouterLink to="/tasks/potential-duplicate">
            <Icon name="square-on-square" />
            Potential duplicate
          </NavRouterLink>
        </HasGroup>
      </NavBody>
    </Nav>
  );
};
