import React from "react";
import { useController, useFormContext } from "react-hook-form";

import {
  EnumSelect,
  EnumSelectProps,
  EnumSelectState,
  useEnumSelectState,
} from "@/components/Select/EnumSelect";
import { TextInputLabel } from "@/components/TextInput";
import { ErrorHandler } from "@/containers/fields/ErrorHandler";

interface EnumFieldProps<T> extends Omit<EnumSelectProps<T>, "state"> {
  label?: string;
  name: string;
  required?: boolean;
  labelSelector?: EnumSelectState<T>["labelSelector"];
  valueSelector?: EnumSelectState<T>["valueSelector"];
  items: EnumSelectState<T>["items"];
  hint?: React.ReactNode;
  validate?: Record<string, (v: any) => boolean>;
  className?: string;
}

export const EnumField = <T extends { value: any; label: string }>(
  props: EnumFieldProps<T>,
) => {
  const {
    label,
    name,
    validate,
    required = false,
    valueSelector = (item) => item.value,
    labelSelector = (item) => item.label,
    hint,
    scale,
    className,
    items,
    ...rest
  } = props;

  const { control } = useFormContext();

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: { value: required, message: "This field is required" },
      validate,
    },
  });

  const select = useEnumSelectState({
    value: items.find((item) => item.value === value) || null,
    onChange: (value) => {
      onChange(value?.value ?? null);
    },
    items,
    labelSelector,
    valueSelector,
    title: label,
    required,
  });

  return (
    <div className={className}>
      {label && (
        <TextInputLabel required={required} scale={scale}>
          {label}
        </TextInputLabel>
      )}
      <EnumSelect
        state={select}
        scale={scale}
        aria-invalid={error ? "true" : "false"}
        {...rest}
      />
      <ErrorHandler error={error} />
    </div>
  );
};
