import { DialogStore } from "@ariakit/react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import React, { useEffect, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Button } from "@/components/Button";
import { Card } from "@/components/Card";
import { Dialog, DialogHeading, useDialogStore } from "@/components/Dialog";
import { Icon } from "@/components/Icon";
import { Loader } from "@/components/Loader";
import {
  Tile,
  TileEdit,
  TileHeader,
  TileRead,
  useTileStore,
} from "@/components/Tile";
import { Tooltip } from "@/components/Tooltip";
import { useAxios } from "@/containers/AxiosContext";
import { EnumField } from "@/containers/fields/EnumField";
import {
  PlaceInfoForm,
  PlaceInfoFormRef,
} from "@/containers/place/PlaceInfoForm";
import { CardNavTitle } from "@/containers/routes/CardNav";
import { City } from "@/requests/City";
import { CommercialInfo } from "@/requests/CommercialInfo";
import { useImage } from "@/requests/Image";
import { Location } from "@/requests/Location";
import { Period } from "@/requests/Period";
import {
  HasIssueChoices,
  Place,
  PlaceIssueForm,
  usePlace,
} from "@/requests/Place";
import { SessionPlaceReportReason } from "@/requests/SessionPlace";
import { Type } from "@/requests/Type";
import { useVirtualizer } from "@/utils/useVirtualizer";

type ExpandPlaceInfo = {
  city: City;
  commercial_info: CommercialInfo;
  periods: Period;
  locations: Location;
  types: Type;
};

type PlaceInfoPlace = Place<ExpandPlaceInfo>;

const PlaceInfoImagesDialog = (props: {
  place: PlaceInfoPlace;
  dialog: DialogStore;
}) => {
  const { authAxios } = useAxios();
  const image = useImage(authAxios);

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ["place-images", props.place.id],
      queryFn: ({ pageParam: page }) =>
        image.get({ params: { page, place: props.place.id } }),
      initialPageParam: 1,
      getNextPageParam: (lastPage: any) => lastPage.next,
    });

  const items = data?.pages.map((page) => page.results).flat() ?? [];
  const totalCount = data?.pages[0].count ?? 0;

  const { listRef, virtualItems, rowVirtualizer } = useVirtualizer({
    items,
    hasNextPage,
    isLoading: isFetchingNextPage,
    horizontal: true,
    fetchNextPage,
    estimateSize: () => 300,
  });

  return (
    <Dialog store={props.dialog} scale="lg">
      <DialogHeading store={props.dialog}>
        Place images ({totalCount})
      </DialogHeading>
      <div
        ref={listRef}
        className="mt-4 flex min-h-96 flex-1 flex-col overflow-auto"
      >
        {!!virtualItems.length && (
          <div
            className="flex min-h-96 items-center justify-center"
            style={{
              width: `${rowVirtualizer.getTotalSize()}px`,
              position: "relative",
            }}
          >
            {virtualItems.map((virtualItem) => {
              const item = items[virtualItem.index];
              return (
                <div
                  key={virtualItem.index}
                  className="flex items-center"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: virtualItem.size,
                    height: "100%",
                    transform: `translateX(${virtualItem.start}px)`,
                  }}
                >
                  <img src={item.image} alt="" width={300} className="px-2" />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Dialog>
  );
};

const PlaceInfoHeader = (props: { place: PlaceInfoPlace }) => {
  const dialog = useDialogStore();

  const onGoogleClick = () => {
    const city = props.place.locations.find((l) => l.type === "locality");
    if (!city) return;
    window.open(
      `https://www.google.com/search?q=${props.place.name}+${city.name}&source=lmns&bih=947&biw=1920&hl=fr&sa=X&ved=2ahUKEwiDqoXg8cL_AhVRpycCHewiDFIQ_AUoAHoECAEQAA`,
      "_blank",
    );
  };

  return (
    <CardNavTitle>
      <div className="flex flex-1 items-center justify-between">
        {props.place.name}
        <div className="flex gap-2">
          <Tooltip tooltip="View place images">
            <Button
              onClick={() => dialog.show()}
              iconOnly
              circle
              variant="secondary"
            >
              <Icon name="photo" />
            </Button>
          </Tooltip>
          <Tooltip tooltip="Search the place on google">
            <Button onClick={onGoogleClick} iconOnly circle>
              <Icon name="google" />
            </Button>
          </Tooltip>
        </div>
      </div>
      <PlaceInfoImagesDialog dialog={dialog} place={props.place} />
    </CardNavTitle>
  );
};

export const PlaceHasIssues = (props: { place: Place<ExpandPlaceInfo> }) => {
  const tile = useTileStore();

  const { authAxios } = useAxios();
  const place = usePlace(authAxios);

  const [hasIssueState, setHasIssueState] = React.useState<{
    has_issues: boolean;
    has_issues_reason: SessionPlaceReportReason | null;
  }>({
    has_issues: props.place.has_issues,
    has_issues_reason: props.place.has_issues_reason,
  });

  const methods = useForm<PlaceIssueForm>({
    values: {
      has_issues: props.place.has_issues,
      has_issues_reason: props.place.has_issues_reason,
    },
  });

  const onSubmit = (data: PlaceIssueForm) => {
    tile.setState("read");
    place.patch(props.place.id, { data: { ...data, has_issues: true } });
    setHasIssueState({
      has_issues: true,
      has_issues_reason: data.has_issues_reason,
    });
    toast.success("Place reported");
  };

  const onDeleteIssue = () => {
    tile.setState("read");
    place.patch(props.place.id, { data: { has_issues: false } });
    setHasIssueState({
      has_issues: false,
      has_issues_reason: null,
    });
    toast.success("Place unreported");
  };

  useEffect(() => {
    methods.reset({
      has_issues: props.place.has_issues,
      has_issues_reason: props.place.has_issues_reason,
    });
    setHasIssueState({
      has_issues: props.place.has_issues,
      has_issues_reason: props.place.has_issues_reason,
    });
  }, [props.place.has_issues, props.place.has_issues_reason, methods]);

  return (
    <Tile store={tile}>
      <TileHeader>
        Has issues
        <div>
          {tile.state === "edit" ? (
            <div className="gap-2">
              {hasIssueState.has_issues && (
                <Tooltip tooltip="Delete issue">
                  <Button
                    variant="danger"
                    iconOnly
                    appearance="text"
                    onClick={onDeleteIssue}
                  >
                    <Icon name="trash" />
                  </Button>
                </Tooltip>
              )}
              <Tooltip tooltip="Cancel">
                <Button
                  variant="text"
                  iconOnly
                  appearance="text"
                  onClick={() => tile.setState("read")}
                >
                  <Icon name="xmark" />
                </Button>
              </Tooltip>
              <Tooltip tooltip="Confirm issue">
                <Button
                  iconOnly
                  appearance="text"
                  onClick={methods.handleSubmit(onSubmit)}
                >
                  <Icon name="checkmark" />
                </Button>
              </Tooltip>
            </div>
          ) : (
            <Tooltip
              tooltip={
                !hasIssueState.has_issues
                  ? "Add an issue"
                  : "Edit the issue reason"
              }
            >
              <Button
                iconOnly
                appearance="text"
                onClick={() => tile.setState("edit")}
              >
                <Icon name={!hasIssueState.has_issues ? "plus" : "pencil"} />
              </Button>
            </Tooltip>
          )}
        </div>
      </TileHeader>
      <TileRead store={tile}>
        {!hasIssueState.has_issues ? (
          <div className="text-grey-on">No issue reported</div>
        ) : (
          HasIssueChoices.find(
            (choice) => choice.value === hasIssueState.has_issues_reason,
          )?.label
        )}
      </TileRead>
      <TileEdit store={tile}>
        <FormProvider {...methods}>
          <EnumField
            name="has_issues_reason"
            scale="sm"
            required
            items={HasIssueChoices}
          />
        </FormProvider>
      </TileEdit>
    </Tile>
  );
};

export const PlaceInfo = () => {
  const { authAxios } = useAxios();
  const place = usePlace<ExpandPlaceInfo>(authAxios);

  const { placeId } = useParams();

  const formRef = useRef<PlaceInfoFormRef>(null);

  const [isLoadingSubmit, setIsLoadingSubmit] = React.useState<boolean>(false);

  const { data, isLoading } = useQuery({
    queryKey: ["place-info", placeId],
    queryFn: () =>
      place.getById(placeId ?? "", {
        params: {
          expand: ["commercial_info", "types", "locations", "periods", "city"],
        },
      }),
    enabled: !!placeId,
  });

  const onSubmit = () => {
    setIsLoadingSubmit(false);
    toast.success("Place modified");
  };

  return (
    <Card className="flex w-1/2 flex-initial flex-col">
      {!isLoading && data ? (
        <>
          <PlaceInfoHeader place={data} />
          <PlaceInfoForm
            ref={formRef}
            data={data}
            onSubmit={onSubmit}
            setIsLoading={setIsLoadingSubmit}
          >
            <PlaceHasIssues place={data} />
          </PlaceInfoForm>
          <div className="mt-4 flex justify-end">
            <Button
              onClick={() => formRef.current?.onSubmit()}
              isLoading={isLoadingSubmit}
            >
              Save
            </Button>
          </div>
        </>
      ) : (
        <>
          {placeId && (
            <div className="flex flex-1 items-center justify-center">
              <Loader />
            </div>
          )}
        </>
      )}
    </Card>
  );
};
