import React, { Suspense, memo } from "react";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import { Loader } from "@/components/Loader";
import { AppNav } from "@/containers/AppNav";
import { useIsSuperuser } from "@/containers/IsSuperuser";
import { UserMeProvider } from "@/containers/UserMeContext";

import * as routes from "./routes";

const MainLayout = () => {
  return (
    <UserMeProvider>
      <div className="flex h-screen">
        <AppNav />
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </div>
    </UserMeProvider>
  );
};

const IndexNavigate = () => {
  const isSuperuser = useIsSuperuser();

  if (isSuperuser) {
    return <Navigate to="/dashboard" />;
  }
  return <Navigate to="/tasks" />;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <IndexNavigate />,
      },
      {
        path: "/dashboard",
        element: <routes.Dashboard />,
      },
      {
        index: true,
        path: "/tasks/*",
        element: <routes.Tasks />,
      },
      {
        path: "/places/:cityId?/:placeId?/*",
        element: <routes.Places />,
      },
      {
        path: "/users/*",
        element: <routes.Users />,
      },
      {
        path: "/admin/*",
        element: <routes.Admin />,
      },
      {
        path: "/profile/:id/*",
        element: <routes.Profile />,
      },
      {
        path: "users/:id/*",
        element: <routes.User />,
      },
    ],
  },
  {
    path: "/login",
    element: <routes.Login />,
  },
]);

export const Router = memo(() => {
  return <RouterProvider router={router} />;
});
