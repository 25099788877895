import { Button as AriakitButton } from "@ariakit/react";
import clsx from "clsx";
import * as React from "react";

export const Nav = (props: {
  children: React.ReactNode;
  "aria-label": string;
  className?: string;
}) => {
  return (
    <nav
      className={clsx(
        props.className,
        "relative flex h-full min-w-48 select-none flex-col border-r bg-white",
      )}
      aria-label={props["aria-label"]}
    >
      {props.children}
    </nav>
  );
};

export const NavHeader = (props: { children: React.ReactNode }) => {
  return (
    <div className="box-border w-full pb-4 pl-4 pt-6 text-sm font-bold uppercase ">
      {props.children}
    </div>
  );
};

export const NavBody = (props: { children: React.ReactNode }) => {
  return (
    <div className="relative h-full overflow-y-auto border-b border-grey-border-light">
      {props.children}
    </div>
  );
};
export const NavItem = (props: { children: React.ReactNode }) => {
  return (
    <AriakitButton
      className={clsx(
        "aria-selected:cursor-default aria-selected:border-r-4 aria-selected:border-primary-bg-strong aria-selected:bg-primary-bg-light aria-selected:font-semibold aria-selected:text-primary-on",
        "box-border flex w-full cursor-pointer items-center justify-start gap-3 py-3 pl-3 text-sm",
        "hover:bg-text-bg-hover-transparent hover:text-text-on-hover",
      )}
      {...props}
    />
  );
};
export const NavFooter = (props: { children: React.ReactNode }) => {
  return (
    <div className="box-border flex items-center justify-center bg-grey-bg-light py-4 text-xs text-grey-on">
      {props.children}
    </div>
  );
};
