import { AxiosInstance } from "axios";
import { useMemo } from "react";

import Entity, { EntityReturn } from "@/requests/AbstractRessources";

export interface SessionSetting {
  id: string;
  session_length: number;
  session_control_number: number;
  session_refused_number: number;
}

export interface SessionSettingForm {
  id?: string;
  session_length?: number;
  session_control_number?: number;
  session_refused_number?: number;
}

export interface SessionSettingReturn extends EntityReturn<SessionSetting> {
  current(): Promise<SessionSetting>;
}

const SessionSetting = (axios: AxiosInstance): SessionSettingReturn => {
  return {
    ...Entity("session-settings", axios),
    current() {
      return this.request({
        method: "GET",
        url: `${this.url}current/`,
      });
    },
  };
};

export const useSessionSetting = (axios: AxiosInstance) => {
  return useMemo(() => SessionSetting(axios), [axios]);
};
