import L, { LeafletEvent } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";

import { GoogleLocation } from "@/requests/GoogleLocation";

type Position = [number, number];

type SetPositionProps = {
  lat: number;
  lng: number;
};

type Marker = {
  position: Position;
  setPosition?: (position: SetPositionProps) => void;
  draggable: boolean;
  fyndee?: boolean;
};

const DraggableMarker = (props: { marker: Marker }) => {
  const eventHandlers = useMemo(
    () => ({
      dragend: (e: LeafletEvent) => {
        if (!props.marker.setPosition) return;
        props.marker.setPosition(e.target.getLatLng());
      },
    }),
    [props.marker],
  );

  const icon = L.icon({
    iconUrl: props.marker.fyndee
      ? require("../../images/fyndee-pin.png")
      : require("../../images/google-pin.png"),
    iconSize: [63, 85],
  });

  return (
    <Marker
      draggable={props.marker.draggable}
      eventHandlers={eventHandlers}
      position={props.marker.position}
      icon={icon}
    />
  );
};

export const Map = (props: { markers: Marker[] }) => {
  const { markers } = props;

  const { getValues, watch } = useFormContext();
  const map = useMap();

  const placeId = watch("id");

  useEffect(() => {
    if (placeId) {
      map.setView([getValues("latitude"), getValues("longitude")], 18);
    }
  }, [map, placeId, markers, getValues]);

  return (
    <>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {markers.map((marker, index) => {
        return <DraggableMarker key={index} marker={marker} />;
      })}
    </>
  );
};

export const MapField = (props: { suggestion?: GoogleLocation }) => {
  const { watch, setValue } = useFormContext();

  const latitude = watch("latitude");
  const longitude = watch("longitude");

  const markers: Marker[] = useMemo(() => {
    const markers: Marker[] = [];
    if (props.suggestion) {
      markers.push({
        position: [props.suggestion?.latitude, props.suggestion?.longitude],
        draggable: false,
      });
    }
    if (latitude && longitude) {
      markers.push({
        position: [latitude, longitude],
        setPosition: ({ lat, lng }: SetPositionProps) => {
          setValue("latitude", lat);
          setValue("longitude", lng);
        },
        draggable: true,
        fyndee: true,
      });
    }
    return markers;
  }, [latitude, longitude, setValue, props.suggestion]);

  const center: Position = useMemo(
    () => [latitude, longitude],
    [latitude, longitude],
  );

  if (!latitude || !longitude) return null;

  return (
    <div className="z-10 h-96">
      <MapContainer className="h-96" center={center} zoom={18}>
        <Map markers={markers} />
      </MapContainer>
    </div>
  );
};
