import { useMutation } from "@tanstack/react-query";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Button } from "@/components/Button";
import {
  Slider,
  SliderBody,
  SliderDismiss,
  SliderFooter,
  SliderHeading,
  SliderStore,
} from "@/components/Slider";
import { PasswordField } from "@/containers/fields/PasswordField";
import { ExpandUserField } from "@/containers/routes/users/Users";
import { User, UserResetPasswordForm, UserReturn } from "@/requests/User";

export const UserEditPasswordSlider = (props: {
  slider: SliderStore;
  user: UserReturn;
  userEntity: User<ExpandUserField> | null;
}) => {
  const mutation = useMutation({
    mutationFn: (data: UserResetPasswordForm) => props.user.resetPassword(data),
    onSuccess: () => {
      toast.success(`${props.userEntity?.username}'s password edited`);
      props.slider.hide();
    },
    onError: () => {
      toast.error("An error occurred");
    },
  });

  const methods = useForm<UserResetPasswordForm>();

  const onSubmit = (data: UserResetPasswordForm) => {
    if (!props.userEntity) return;
    mutation.mutate({ ...data, id: props.userEntity.id });
  };

  if (!props.userEntity) return null;

  return (
    <Slider store={props.slider}>
      <SliderHeading>Edit password</SliderHeading>
      <SliderBody className="gap-4">
        <FormProvider {...methods}>
          <PasswordField
            name="password"
            label="Password"
            getValues={methods.getValues}
          />
          <PasswordField
            name="password2"
            label="Confirm password"
            getValues={methods.getValues}
            validate={{
              sameAsPassword: (value: string) =>
                methods.getValues("password") === value ||
                ("Passwords must match" as any),
            }}
          />
        </FormProvider>
      </SliderBody>
      <SliderFooter>
        <SliderDismiss store={props.slider} variant="text" appearance="text">
          Cancel
        </SliderDismiss>
        <Button
          onClick={methods.handleSubmit(onSubmit)}
          isLoading={mutation.isPending}
        >
          Save modifications
        </Button>
      </SliderFooter>
    </Slider>
  );
};
