import { useInfiniteQuery } from "@tanstack/react-query";
import React from "react";
import { Link } from "react-router-dom";

import { Chip } from "@/components/Chip";
import {
  Dialog,
  DialogHeading,
  DialogStore,
  useDialogStore,
} from "@/components/Dialog";
import { useAxios } from "@/containers/AxiosContext";
import { useIsSuperuser } from "@/containers/IsSuperuser";
import { List, useListState } from "@/containers/list/List";
import { Location } from "@/requests/Location";
import { Place } from "@/requests/Place";
import { Session } from "@/requests/Session";
import { SessionPlace, useSessionPlace } from "@/requests/SessionPlace";

type SessionPlaceExpand = { place: Place<{ locations: Location }> };

const SessionPlaceDialogContext = React.createContext<{
  open: (session: Session) => void;
  session: Session | null;
  dialog: DialogStore;
} | null>(null);

export const SessionPlaceDialogProvider = (props: {
  children: React.ReactNode;
}) => {
  const dialog = useDialogStore();
  const [session, setSession] = React.useState<Session | null>(null);

  const open = (session: Session) => {
    dialog.show();
    setSession(session);
  };

  return (
    <SessionPlaceDialogContext.Provider value={{ open, session, dialog }}>
      {props.children}
    </SessionPlaceDialogContext.Provider>
  );
};

export const useSessionPlaceDialog = () => {
  const context = React.useContext(SessionPlaceDialogContext);
  if (!context) {
    throw new Error(
      "useSessionPlaceDialog must be used within a SessionPlaceDialogProvider",
    );
  }
  return context;
};

const SessionPlaceTd = (props: {
  item: SessionPlace<SessionPlaceExpand>;
  children: React.ReactNode;
}) => {
  const { item } = props;
  const isSuperuser = useIsSuperuser();
  if (!isSuperuser) {
    return <>{props.children}</>;
  }
  const location = item.place.locations.find(
    (location) => location.type === "locality",
  );
  if (!location) return <>{props.children}</>;
  return (
    <>
      <Link target="_blank" to={`/places/${location.id}/${item.place.id}`}>
        {props.children}
      </Link>
    </>
  );
};

const placeNameTdProps = {
  render: (props: { item: SessionPlace<SessionPlaceExpand> }) => {
    return (
      <SessionPlaceTd item={props.item}>{props.item.place.name}</SessionPlaceTd>
    );
  },
  minWidth: 400,
};

const getSessionPlaceColor = (
  sessionPlace: SessionPlace<SessionPlaceExpand>,
) => {
  if (sessionPlace.reviewed) {
    if (sessionPlace.status === "accepted") {
      return "success";
    }
    return "danger";
  }
  return "secondary";
};

const getSessionPlaceStatus = (
  sessionPlace: SessionPlace<SessionPlaceExpand>,
) => {
  if (sessionPlace.reviewed) {
    return sessionPlace.status.toLocaleUpperCase();
  }
  return "Not reviewed";
};

const placeReviewedTdProps = {
  render: (props: { item: SessionPlace<SessionPlaceExpand> }) => {
    return (
      <SessionPlaceTd item={props.item}>
        <Chip scale="sm" tint={getSessionPlaceColor(props.item)}>
          {getSessionPlaceStatus(props.item)}
        </Chip>
      </SessionPlaceTd>
    );
  },
  minWidth: 100,
};

const placeRefusedCommentTdProps = {
  render: (props: { item: SessionPlace<SessionPlaceExpand> }) => {
    return (
      <SessionPlaceTd item={props.item}>
        {props.item.refused_comment}
      </SessionPlaceTd>
    );
  },
  minWidth: 300,
};

const sessionPlaceHeaders = [
  {
    render: () => "Place",
    minWidth: 400,
  },
  {
    render: () => "Reviewed",
    minWidth: 100,
  },
  {
    render: () => "Refused comment",
    minWidth: 300,
  },
];

export const SessionPlacesDialog = () => {
  const { session, dialog } = useSessionPlaceDialog();
  const { authAxios } = useAxios();
  const sessionPlace = useSessionPlace<SessionPlaceExpand>(authAxios);

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ["session-places", session?.id],
      initialPageParam: 1,
      getNextPageParam: (lastPage: any) => lastPage.next,
      queryFn: ({ pageParam: page }) =>
        sessionPlace.get({
          params: {
            page,
            session: session?.id,
            expand: ["place.locations"],
          },
        }),
      enabled: !!session,
    });

  const sessionList = useListState({
    items: data?.pages.flatMap((page) => page.results) ?? [],
    fetchNextPage,
    isLoading: isFetchingNextPage,
    hasNextPage,
    totalCount: data?.pages[0].count ?? 0,
    columns: [
      placeNameTdProps,
      placeReviewedTdProps,
      placeRefusedCommentTdProps,
    ],
    headers: sessionPlaceHeaders,
  });

  return (
    <Dialog store={dialog} scale="lg">
      <DialogHeading store={dialog}>Session places</DialogHeading>
      <List state={sessionList} isLoading={!data && isFetching} />
    </Dialog>
  );
};
