import { Button } from "@ariakit/react";
import { clsx } from "clsx";
import * as React from "react";
import { ButtonHTMLAttributes, forwardRef } from "react";

import { getBetterContrast } from "@/utils/colors";

export type ChipScale = "sm" | "md" | "lg";

export type ChipTint =
  | "primary"
  | "secondary"
  | "danger"
  | "text"
  | "success"
  | "warning"
  | string;

const tints: Record<ChipTint, string> = {
  primary: /* tw */ `bg-primary-bg-light text-primary-on`,
  secondary: /* tw */ `bg-secondary-bg-light text-secondary-on`,
  text: /* tw */ `bg-text-bg-light text-text-on`,
  danger: /* tw */ `bg-danger-bg-light text-danger-on`,
  success: /* tw */ `bg-success-bg-light text-success-on`,
  warning: /* tw */ `bg-warning-bg-light text-warning-on`,
};

const hoverTints: Record<ChipTint, string> = {
  primary: /* tw */ `hover:bg-primary-bg-hover-transparent`,
  secondary: /* tw */ `hover:bg-secondary-bg-hover-transparent`,
  text: /* tw */ `hover:bg-text-bg-hover-transparent`,
  danger: /* tw */ `hover:bg-danger-bg-hover-transparent`,
  success: /* tw */ `hover:bg-success-bg-hover-transparent`,
  warning: /* tw */ `hover:bg-warning-bg-hover-transparent`,
};

export interface ChipProps extends ButtonHTMLAttributes<HTMLDivElement> {
  /**
   * Chip tint.
   * @default "secondary"
   */
  tint?: ChipTint;

  /**
   * Chip scale.
   * @default "md"
   */
  scale?: ChipScale;

  /**
   * Chip scale.
   * @default false
   */
  asButton?: boolean;
}

const scales: Record<ChipScale, string> = {
  sm: /* tw */ `text-xs gap-1.5 leading-normal py-px`,
  md: /* tw */ `text-sm gap-2 leading-6 py-1`,
  lg: /* tw */ `text-lg gap-3 leading-8 py-2`,
};

const ChipContainer = forwardRef<HTMLDivElement, ChipProps>(
  (
    {
      className,
      asButton = false,
      tint = "secondary",
      style,
      children,
      ...props
    },
    ref,
  ) => {
    if (asButton) {
      const hoverTintClassName = hoverTints[tint];

      return (
        <Button
          ref={ref}
          as="div"
          className={clsx(className, hoverTintClassName)}
          style={style}
          {...props}
        >
          {children}
        </Button>
      );
    }
    return (
      <div ref={ref} className={className} style={style} {...props}>
        {children}
      </div>
    );
  },
);

export const Chip = forwardRef<HTMLDivElement, ChipProps>(
  (
    {
      tint = "primary",
      scale = "md",
      asButton = false,
      className,
      style,
      children,
      ...props
    },
    ref,
  ) => {
    const tintClassname = tints[tint];

    return (
      <ChipContainer
        ref={ref}
        className={clsx(
          className,
          "inline-flex select-none items-center overflow-hidden overflow-ellipsis whitespace-nowrap rounded px-2 text-sm font-semibold",
          scales[scale],
          tintClassname ?? "text-text-on",
        )}
        style={
          tintClassname
            ? style
            : {
                backgroundColor: tint,
                color: getBetterContrast(tint, "#fff", "#090B0F"),
                ...style,
              }
        }
        asButton={asButton}
        tint={tint}
        {...props}
      >
        {children}
      </ChipContainer>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  Chip.displayName = "Chip";
}
