import {
  Dialog as AriakitDialog,
  DialogDismiss as AriakitDialogDismiss,
  DialogDismissProps as AriakitDialogDismissProps,
  DialogHeading as AriakitDialogHeading,
  DialogHeadingProps as AriakitDialogHeadingProps,
  DialogProps as AriakitDialogProps,
  DialogStoreProps,
  useDialogStore as useAriakitDialogStore,
} from "@ariakit/react";
import clsx from "clsx";
import React from "react";

import { Button, ButtonProps } from "@/components/Button";
import { Icon } from "@/components/Icon";

export type { DialogStore } from "@ariakit/react";

export const useDialogStore = (props?: DialogStoreProps) => {
  return useAriakitDialogStore({ ...props, animated: true });
};

const DialogBackdrop = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div
      ref={ref}
      className={clsx(
        "fixed inset-0 z-backdrop flex items-center justify-center bg-text-bg bg-opacity-50 opacity-0",
        "data-[enter]:opacity-100",
      )}
      {...props}
    />
  );
});

type DialogDismissProps = {
  children: React.ReactNode;
} & AriakitDialogDismissProps &
  ButtonProps;

export const DialogDismiss = (props: DialogDismissProps) => {
  return <AriakitDialogDismiss render={<Button />} {...props} />;
};

type DialogHeadingProps = {
  children: React.ReactNode;
} & AriakitDialogHeadingProps;

export const DialogHeading = (props: DialogHeadingProps) => {
  return (
    <AriakitDialogHeading
      className="mb-4 flex items-center justify-between border-b pb-2 text-lg"
      {...props}
    >
      {props.children}
      <DialogDismiss iconOnly appearance="text">
        <Icon name="xmark" className="h-4 w-4" />
      </DialogDismiss>
    </AriakitDialogHeading>
  );
};

export const DialogFooter = (props: { children: React.ReactNode }) => {
  return (
    <div className="mt-4 flex items-center justify-between border-t pt-2">
      {props.children}
    </div>
  );
};

type DialogScale = "sm" | "md" | "lg";

type DialogProps = {
  scale?: DialogScale;
} & AriakitDialogProps;

const scales: Record<DialogScale, string> = {
  sm: /* tw */ `inset-x-1/3`,
  md: /* tw */ `inset-x-1/4`,
  lg: /* tw */ `inset-x-[10%]`,
};

export const Dialog = React.forwardRef<HTMLDivElement, DialogProps>(
  (props, ref) => {
    const { scale = "md", ...rest } = props;
    return (
      <AriakitDialog
        ref={ref}
        className={clsx(
          "fixed z-dialog flex h-fit max-h-[95vh] scale-50 flex-col justify-between rounded-lg bg-white p-4 opacity-0",
          "inset-y-1/2 -translate-y-1/2",
          "transform transition-all duration-300 ease-in-out",
          "data-[enter]:scale-100 data-[enter]:opacity-100",
          scales[scale],
        )}
        backdrop={<DialogBackdrop />}
        {...rest}
      />
    );
  },
);
