import clsx from "clsx";
import React from "react";

type TableSectionProps = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLTableSectionElement>;

type TableSection = TableSectionProps & {
  className?: string;
};

export const TableHead = (props: TableSection) => {
  const { className, ...rest } = props;
  // @ts-ignore
  return <thead {...rest} className={clsx(className, "sticky top-0 z-30")} />;
};

export const TableBody = (props: TableSectionProps) => (
  // @ts-ignore
  <tbody {...props} />
);

type TableProps = {
  children: React.ReactNode;
  className?: string;
} & React.HTMLAttributes<HTMLTableElement>;

export const Table = React.forwardRef<HTMLDivElement, TableProps>(
  (props, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(
          "relative flex flex-1 flex-col overflow-auto rounded border",
          props.className,
        )}
      >
        <table className="max-y-full relative h-fit w-full" {...props}>
          {props.children}
        </table>
      </div>
    );
  },
);

type TrProps = {
  children: React.ReactNode;
  isHeader?: boolean;
} & React.HTMLAttributes<HTMLTableRowElement>;

export const Tr = React.forwardRef<HTMLTableRowElement, TrProps>(
  (props, ref) => {
    const { isHeader, ...rest } = props;
    return (
      <tr
        ref={ref}
        className={clsx("group flex w-full items-center border-b")}
        data-header={isHeader ? "true" : undefined}
        {...rest}
      />
    );
  },
);

export type TdProps = {
  children?: React.ReactNode;
  isAction?: boolean;
} & React.HTMLAttributes<HTMLTableCellElement>;

export const Td = (props: TdProps) => {
  const { isAction, ...rest } = props;

  return (
    <td
      className={clsx(
        "left-0 right-0 line-clamp-2 flex h-full w-full items-center bg-white p-4",
        "data-[action]:sticky data-[action]:z-20 data-[action]:min-w-40",
        "group-data-[header]:border-b group-data-[header]:bg-grey-bg group-data-[header]:px-4 group-data-[header]:py-3 group-data-[header]:text-sm group-data-[header]:text-grey-on-stronger",
        "group-hover:bg-grey-bg-hover-light group-data-[header]:whitespace-nowrap",
      )}
      data-action={isAction ? "true" : undefined}
      {...rest}
    />
  );
};
