import { useComboboxStore } from "@ariakit/react";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import dayjs from "dayjs";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { Button } from "@/components/Button";
import { Icon } from "@/components/Icon";
import { useSliderStore } from "@/components/Slider";
import { Tile, TileHeader } from "@/components/Tile";
import { useAxios } from "@/containers/AxiosContext";
import { HasGroup } from "@/containers/HasGroup";
import { IsSuperuser } from "@/containers/IsSuperuser";
import { useUserAccount } from "@/containers/account/Account";
import { UserTextInputTileField } from "@/containers/account/AccountInfos";
import { AccountInvoicesSlider } from "@/containers/account/AccountInvoicesSlider";
import {
  List,
  ListTitle,
  ListToolbar,
  useListState,
} from "@/containers/list/List";
import { Invoice, useInvoice } from "@/requests/Invoice";
import { UserForm, useUser } from "@/requests/User";
import { validations } from "@/utils/validations";

const invoiceIdTdProps = {
  render: (props: { item: Invoice }) => {
    return <div>{props.item.id}</div>;
  },
  minWidth: 400,
};

const invoiceCreatedAtTdProps = {
  render: (props: { item: Invoice }) => {
    return <div>{dayjs(props.item.created_at).format("DD/MM/YYYY")}</div>;
  },
  minWidth: 200,
};

const invoicePriceTdProps = {
  render: (props: { item: Invoice }) => {
    return <div>{props.item.price} €</div>;
  },
  minWidth: 200,
};

const invoiceHeaders = [
  {
    render: () => "Invoice ID",
    minWidth: 400,
  },
  {
    render: () => "Created at",
    minWidth: 200,
  },
  {
    render: () => "Price",
    minWidth: 200,
  },
];

export const AccountInvoices = () => {
  const queryClient = useQueryClient();
  const slider = useSliderStore();

  const combobox = useComboboxStore();
  const search = combobox.useState("value");

  const { authAxios } = useAxios();
  const invoice = useInvoice(authAxios);
  const user = useUser(authAxios);

  const { userAccount } = useUserAccount();

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ["account-invoices", userAccount.id, search],
      initialPageParam: 1,
      getNextPageParam: (lastPage: any) => lastPage.next,
      queryFn: ({ pageParam: page }) =>
        invoice.get({
          params: {
            page,
            search,
            user: userAccount.id,
          },
        }),
    });

  const mutation = useMutation({
    mutationFn: (data: UserForm) => user.patch(userAccount.id, { data }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["user", String(userAccount.id)],
      });
    },
  });

  const invoiceList = useListState({
    items: data?.pages.flatMap((page) => page.results) ?? [],
    fetchNextPage,
    isLoading: isFetchingNextPage,
    hasNextPage,
    totalCount: data?.pages[0].count ?? 0,
    columns: [invoiceIdTdProps, invoiceCreatedAtTdProps, invoicePriceTdProps],
    headers: invoiceHeaders,
  });

  const methods = useForm<UserForm>();

  return (
    <>
      <FormProvider {...methods}>
        <div className="mb-5 flex flex-col gap-4">
          <div className="flex gap-6">
            <UserTextInputTileField
              name="validation_price"
              label="Place price (€)"
              mutation={mutation}
              validate={{
                decimal: (value: string) =>
                  validations.decimal(value) as boolean,
              }}
            />
            <HasGroup group="validator">
              <Tile>
                <TileHeader>Sessions left to pay</TileHeader>
                <div className="flex flex-col">
                  <span>Number: {userAccount.sessions_left_to_pay.count}</span>
                  <span>
                    Amount: {userAccount.sessions_left_to_pay.price} €
                  </span>
                </div>
              </Tile>
            </HasGroup>
            <HasGroup group="quality_controller">
              <Tile>
                <TileHeader>Quality control sessions left to pay</TileHeader>
                <div className="flex flex-col">
                  <span>
                    Number:{" "}
                    {userAccount.quality_control_sessions_left_to_pay.count}
                  </span>
                  <span>
                    Amount:{" "}
                    {userAccount.quality_control_sessions_left_to_pay.price} €
                  </span>
                </div>
              </Tile>
            </HasGroup>
          </div>
        </div>
      </FormProvider>
      <ListTitle state={invoiceList}>Invoices</ListTitle>
      <ListToolbar combobox={combobox}>
        <IsSuperuser>
          <Button className="gap-2" onClick={slider.show}>
            <Icon name="plus" />
            Add an invoice
          </Button>
        </IsSuperuser>
      </ListToolbar>
      <List state={invoiceList} isLoading={!data && isFetching} />
      <AccountInvoicesSlider slider={slider} />
    </>
  );
};
