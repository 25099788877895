import { useCallback } from "react";
import {
  UseFieldArrayInsert,
  UseFieldArrayRemove,
  useFieldArray,
  useFormContext,
} from "react-hook-form";

import { Button } from "@/components/Button";
import { Icon } from "@/components/Icon";
import { TextInputLabel } from "@/components/TextInput";
import { EnumField } from "@/containers/fields/EnumField";
import { TimePickerField } from "@/containers/fields/TimePickerField";
import { Period } from "@/requests/Period";

const days = [
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
  { value: 7, label: "Sunday" },
];

const PeriodRow = (props: {
  remove: UseFieldArrayRemove;
  index: number;
  insert: UseFieldArrayInsert<any, any>;
}) => {
  const { getValues } = useFormContext();

  const onRemoveClick = useCallback(() => {
    props.remove(props.index);
  }, [props]);

  const onDuplicateClick = useCallback(() => {
    props.insert(props.index, { ...getValues(`periods.${props.index}`) });
  }, [props, getValues]);

  return (
    <div className="flex w-full items-center justify-between gap-6">
      <EnumField
        items={days}
        label="Day"
        name={`periods.${props.index}.day`}
        validate={{
          duplicatedPeriod: (value: number) => {
            const periods = getValues("periods");
            const currentPeriod = periods[props.index];
            const duplicatedPeriod = periods.find(
              (period: Period, index: number) =>
                index !== props.index &&
                period.day === value &&
                period.start_hour === currentPeriod.start_hour &&
                period.end_hour === currentPeriod.end_hour,
            );
            return (!duplicatedPeriod ||
              "This period already exists") as unknown as boolean;
          },
        }}
        className="flex-1"
        scale="sm"
        required
      />
      <TimePickerField
        label="Start hour"
        name={`periods.${props.index}.start_hour`}
        className="flex-1"
        scale="sm"
        required
      />
      <TimePickerField
        label="End hour"
        name={`periods.${props.index}.end_hour`}
        className="flex-1"
        scale="sm"
        required
      />
      <div className="mt-6 flex items-center justify-center">
        <Button
          iconOnly
          variant="danger"
          appearance="text"
          onClick={onRemoveClick}
        >
          <Icon name="trash" />
        </Button>
        <Button iconOnly appearance="text" onClick={onDuplicateClick}>
          <Icon name="square-on-square" />
        </Button>
      </div>
    </div>
  );
};

export const PlacePeriods = () => {
  const { control } = useFormContext();
  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: "periods",
  });

  return (
    <div className="flex flex-1 flex-col items-start gap-6">
      <div className="flex flex-col gap-2">
        <TextInputLabel scale="sm">Periods</TextInputLabel>
        <Button
          variant="primary"
          appearance="text"
          onClick={() =>
            append({ day: 1, start_hour: "00:00", end_hour: "00:00" })
          }
        >
          <Icon name="plus" className="mr-1" />
          Add period
        </Button>
      </div>
      {fields.map((field, index) => (
        <PeriodRow
          key={field.id}
          remove={remove}
          insert={insert}
          index={index}
        />
      ))}
    </div>
  );
};
