import IcoMoon, { IconProps as IcoMoonIconProps } from "react-icomoon";

import iconSet from "./selection.json";

type IconProps = {
  name: string;
  size?: number;
} & Omit<IcoMoonIconProps, "icon">;

export const Icon = (props: IconProps) => {
  const { size = 16 } = props;
  return <IcoMoon iconSet={iconSet} icon={props.name} size={size} {...props} />;
};
