import { useQuery } from "@tanstack/react-query";

import { Card } from "@/components/Card";
import { Loader } from "@/components/Loader";
import { useAxios } from "@/containers/AxiosContext";
import { useStatistics } from "@/requests/Statistics";

const DashboardCard = (props: { label: string; value: string | number }) => {
  return (
    <Card className="flex w-full flex-col items-center justify-center gap-2">
      <div className="text-xl font-semibold">{props.value}</div>
      <div className="text-base">{props.label}</div>
    </Card>
  );
};

export const Dashboard = () => {
  const { authAxios } = useAxios();
  const statistics = useStatistics(authAxios);

  const { data, isLoading } = useQuery({
    queryKey: ["statistics"],
    queryFn: statistics.get,
  });

  if (isLoading || !data)
    return (
      <div className="flex flex-1 items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <div
      className="my-6 flex flex-1 flex-col gap-4 px-6"
      style={{ width: "calc(100% - 48px)" }}
    >
      <h2 className="mb-4 font-semibold">Dashboard</h2>
      <div className="flex w-full gap-4">
        <DashboardCard label="Place number" value={data.place_number} />
        <DashboardCard label="Clean places" value={data.place_clean_number} />
        <DashboardCard
          label="Validated place number"
          value={data.validated_place_number}
        />
      </div>
      <div className="flex w-full gap-4">
        <DashboardCard label="Image number" value={data.image_number} />
        <DashboardCard
          label="Image clean number"
          value={data.image_clean_number}
        />
        <DashboardCard label="Users" value={data.user_number} />
      </div>
      <div className="flex w-full gap-4">
        <DashboardCard
          label="Place that has issues"
          value={data.has_issue_place_number}
        />
        <DashboardCard
          label="Sessions waiting for review"
          value={data.session_left_to_clean}
        />
        <div className="flex w-full" />
      </div>
    </div>
  );
};
