import { Button as AriakitButton } from "@ariakit/react";
import clsx from "clsx";
import * as React from "react";

import { Tooltip } from "./Tooltip";

export type NavbarSide = "left" | "right";
export type NavbarTheme = "dark" | "light";

const NavbarContext = React.createContext<{
  side: NavbarSide;
  theme: NavbarTheme;
} | null>(null);

const useNavbarContext = () => {
  const value = React.useContext(NavbarContext);
  if (!value) {
    throw new Error("NavbarContext is not set");
  }
  return value;
};

const NavbarThemes: Record<NavbarTheme, string> = {
  dark: /* tw */ `bg-text-bg-stronger `,
  light: /* tw */ `bg-grey-bg-light ring-1 ring-grey-border-light`,
};

export const Navbar = (props: {
  children: React.ReactNode;
  "aria-label": string;
  side: "left" | "right";
  className?: string;
  theme: NavbarTheme;
}) => {
  const value = React.useMemo(
    () => ({ side: props.side, theme: props.theme }),
    [props.side, props.theme],
  );

  return (
    <NavbarContext.Provider value={value}>
      <nav
        className={clsx(
          props.className,
          "no-print z-navbar relative flex h-full min-w-12 select-none flex-col",
          NavbarThemes[props.theme],
        )}
        aria-label={props["aria-label"]}
      >
        {props.children}
      </nav>
    </NavbarContext.Provider>
  );
};

export const NavbarHeader = (props: { children: React.ReactNode }) => {
  return <div className="shrink-0 py-2">{props.children}</div>;
};

export const NavbarBody = (props: { children: React.ReactNode }) => {
  const { theme } = useNavbarContext();
  const [scrollable, setScrollable] = React.useState(false);
  const NavbarBodyTheme: Record<NavbarTheme, string> = {
    dark: clsx("scrollbar-dark", scrollable && "border-b-text-border-strong"),
    light: clsx("scrollbar-light", scrollable && "border-b-grey-border-light"),
  };
  const containerRef = React.useRef<HTMLDivElement>(null);
  const updateScrollable = React.useCallback(() => {
    const container = containerRef.current;
    if (!container) return;
    setScrollable(container.scrollHeight > container.clientHeight);
  }, []);
  React.useEffect(() => {
    const handleResize = () => {
      updateScrollable();
    };
    window.addEventListener("resize", handleResize);
    updateScrollable();
    return () => window.removeEventListener("resize", handleResize);
  }, [updateScrollable]);
  return (
    <div
      ref={containerRef}
      className={clsx(
        "flex-shrink flex-grow overflow-auto",
        scrollable && "border-b",
        NavbarBodyTheme[theme],
      )}
      style={
        scrollable
          ? {
              boxShadow: "rgb(0 0 0 / 10%) 0px -10px 10px inset",
            }
          : undefined
      }
    >
      {props.children}
    </div>
  );
};

export const NavbarFooter = (props: { children: React.ReactNode }) => {
  return <div className="shrink-0">{props.children}</div>;
};

export const NavbarGroup = (props: { children: React.ReactNode }) => {
  return <div className="py-2 last:border-b-0">{props.children}</div>;
};

const getTooltipPlacement = (side: NavbarSide) => {
  switch (side) {
    case "left":
      return "right";
    case "right":
      return "left";
  }
};

const NavbarItemButtonThemes: Record<NavbarTheme, string> = {
  dark: clsx(
    "text-white/70",
    "hover:bg-white/10 hover:text-white",
    "aria-selected:bg-white/10 aria-selected:text-white",
    "aria-expanded:bg-white/20 aria-pressed:bg-white/20 aria-current-page:bg-white/20 active:bg-white/20",
    "aria-expanded:hover:bg-white/20 aria-pressed:hover:bg-white/20 aria-current-page:hover:bg-white/20 active:hover:bg-white/20",
    "aria-expanded:text-white aria-pressed:text-white aria-current-page:text-white active:text-white",
    "aria-expanded:hover:text-white aria-pressed:hover:text-white aria-current-page:hover:text-white active:hover:text-white",
  ),
  light: clsx(
    "text-grey-on",
    "hover:bg-grey-bg-hover-light hover:text-grey-on-hover",
    "aria-expanded:bg-white aria-pressed:bg-white aria-current-page:bg-white active:bg-white",
    "aria-expanded:hover:bg-white aria-pressed:hover:bg-white aria-current-page:hover:bg-white active:hover:bg-white",
    "aria-expanded:text-text-on aria-pressed:text-text-on aria-current-page:text-text-on active:text-text-on",
    "aria-expanded:hover:text-text-on aria-pressed:hover:text-text-on aria-current-page:hover:text-text-on active:hover:text-text-on",
  ),
};

export const NavbarItemButton = (props: {
  children: React.ReactElement;
  className?: string;
  title?: string;
  hideTooltip?: boolean;
  "aria-selected": boolean;
}) => {
  const { side, theme } = useNavbarContext();
  return (
    <Tooltip
      tooltip={props.hideTooltip ? null : props.title}
      gutter={12}
      placement={getTooltipPlacement(side)}
    >
      <AriakitButton
        className={clsx(
          props.className,
          "relative mx-2 my-1 flex items-center justify-center rounded py-2 text-xl transition",
          NavbarItemButtonThemes[theme],
        )}
        aria-label={props.title}
        aria-selected={props["aria-selected"]}
      >
        {(buttonProps) =>
          React.cloneElement(React.Children.only(props.children), buttonProps)
        }
      </AriakitButton>
    </Tooltip>
  );
};
