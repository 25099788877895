export const validations = {
  email: (v: string) =>
    !!v?.toLowerCase().match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/) ||
    "Email must be valid",
  passwordLength: (v: string) =>
    v?.length >= 8 || "Password must be at least 8 characters",
  differentThanUsername: (v: string, getValues: (value: string) => string) =>
    !v?.includes(getValues("username")) ||
    "Password must be different than username",
  minLengthDigit: (v: string) =>
    /\d/.test(v) || "Password must contain at least one digit",
  minLengthSpecial: (v: string) =>
    /[~!@#$%^&*()_+{}\":;'[]/.test(v) ||
    "Password must contain at least one special character",
  minLengthUpper: (v: string) =>
    /[A-Z]/.test(v) || "Password must contain at least one uppercase character",
  minLengthChar: (v: string) =>
    /[a-z]/.test(v) || "Password must contain at least one lowercase character",
  url: (v: string) => {
    if (!v) return undefined;
    return (
      !!v
        .toLowerCase()
        .match(
          /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
        ) || "Url must be valid"
    );
  },
  phone: (v: string) => {
    if (!v) return undefined;
    return (
      !!v.toLowerCase().match(/^\+(\d{1,3})([\s-]?\d{1,4}){1,5}$/) ||
      "Phone must be valid"
    );
  },
  number: (v: string) => {
    if (!v) return undefined;
    return !!v.toLowerCase().match(/^\d+$/) || "Number must be valid";
  },
  decimal: (v: string) => {
    if (!v) return undefined;
    return (
      !!v.toLowerCase().match(/^\d+(\.\d{1,2})?$/) || "Decimal must be valid"
    );
  },
};
