import clsx from "clsx";
import * as React from "react";
import { forwardRef } from "react";

export type PopoverCardProps = React.HTMLAttributes<HTMLDivElement>;

export const PopoverCard = forwardRef<HTMLDivElement, PopoverCardProps>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(
          className,
          "z-popover select-none rounded-md border border-grey-border-light bg-white shadow-md focus:outline-none",
        )}
        {...props}
      />
    );
  },
);
