import { DialogStore } from "@ariakit/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Button } from "@/components/Button";
import {
  Dialog,
  DialogDismiss,
  DialogFooter,
  DialogHeading,
  useDialogStore,
} from "@/components/Dialog";
import { Icon } from "@/components/Icon";
import { Loader } from "@/components/Loader";
import { Tooltip } from "@/components/Tooltip";
import { useAxios } from "@/containers/AxiosContext";
import { EnumField } from "@/containers/fields/EnumField";
import {
  PlaceInfoForm,
  PlaceInfoFormRef,
} from "@/containers/place/PlaceInfoForm";
import { CardNav, CardNavTitle } from "@/containers/routes/CardNav";
import { City } from "@/requests/City";
import { Location } from "@/requests/Location";
import { HasIssueChoices, Place } from "@/requests/Place";
import {
  SessionPlaceReportForm,
  useSessionPlace,
} from "@/requests/SessionPlace";

type ValidatingPlace = Place<{ city: City; locations: Location }>;

const ValidatingHeader = (props: { place: ValidatingPlace }) => {
  const city = props.place.locations.find((l) => l.type === "locality");
  if (!city) return null;
  const onGoogleClick = () => {
    window.open(
      `https://www.google.com/search?q=${props.place.name}+${city.name}&source=lmns&bih=947&biw=1920&hl=fr&sa=X&ved=2ahUKEwiDqoXg8cL_AhVRpycCHewiDFIQ_AUoAHoECAEQAA`,
      "_blank",
    );
  };

  return (
    <CardNavTitle>
      <div className="flex flex-1 items-center justify-between">
        {props.place.name}
        <div className="flex gap-2">
          <Tooltip tooltip="Search the place on google">
            <Button onClick={onGoogleClick} iconOnly circle>
              <Icon name="google" />
            </Button>
          </Tooltip>
        </div>
      </div>
    </CardNavTitle>
  );
};

const HasIssuesDialog = (props: {
  dialog: DialogStore;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const queryClient = useQueryClient();
  const { authAxios } = useAxios();
  const sessionPlace = useSessionPlace(authAxios);

  const methods = useForm<SessionPlaceReportForm>();

  const mutation = useMutation({
    mutationFn: (data: SessionPlaceReportForm) => sessionPlace.report(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["validating-place"] });
      props.setIsLoading(false);
      toast.success("Place reported");
    },
    onError: () => {
      props.setIsLoading(false);
      toast.error("An error occurred");
    },
  });

  const onSubmit = async (data: SessionPlaceReportForm) => {
    props.setIsLoading(true);
    props.dialog.hide();
    methods.reset();
    await mutation.mutateAsync(data);
  };

  return (
    <Dialog store={props.dialog}>
      <DialogHeading>Report this place</DialogHeading>
      <FormProvider {...methods}>
        <EnumField name="reason" required items={HasIssueChoices} />
      </FormProvider>
      <DialogFooter>
        <DialogDismiss variant="text" appearance="text">
          Cancel
        </DialogDismiss>
        <Button onClick={methods.handleSubmit(onSubmit)}>Report</Button>
      </DialogFooter>
    </Dialog>
  );
};

export const Validating = () => {
  const dialog = useDialogStore();

  const queryClient = useQueryClient();
  const { authAxios } = useAxios();
  const sessionPlace = useSessionPlace(authAxios);

  const placeInfoRef = React.useRef<PlaceInfoFormRef>(null);

  const [isSubmitLoading, setIsSubmitLoading] = React.useState<boolean>(false);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["validating-place"],
    queryFn: () => sessionPlace.current(),
    retry: 0,
  });

  const onSubmit = async () => {
    await sessionPlace.send();
    await queryClient.invalidateQueries({ queryKey: ["validating-place"] });
    setIsSubmitLoading(false);
    toast.success("Place validated");
  };

  return (
    <CardNav title="Validating">
      {isLoading && (
        <div className="flex flex-1 items-center justify-center">
          <Loader />
        </div>
      )}
      {!isLoading && data && !isError && (
        <>
          <ValidatingHeader place={data.place} />
          <PlaceInfoForm
            ref={placeInfoRef}
            data={data.place}
            onSubmit={onSubmit}
            setIsLoading={setIsSubmitLoading}
          />
          <HasIssuesDialog dialog={dialog} setIsLoading={setIsSubmitLoading} />
          <div className="mt-4 flex items-center justify-between">
            <Button
              variant="danger"
              isLoading={isSubmitLoading}
              onClick={() => dialog.show()}
            >
              Report this place
            </Button>
            <Button
              onClick={() => placeInfoRef.current?.onSubmit()}
              isLoading={isSubmitLoading}
            >
              Submit
            </Button>
          </div>
        </>
      )}
      {isError && (
        <div className="flex flex-1 items-center justify-center font-semibold">
          No more places available, feel free to contact the support
        </div>
      )}
    </CardNav>
  );
};
