import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useComboboxStore } from "@/components/Combobox";
import { useToolbarState } from "@/components/Toolbar";
import { Tooltip } from "@/components/Tooltip";
import { useAxios } from "@/containers/AxiosContext";
import { HasOneField } from "@/containers/fields/HasOneField";
import { BooleanFilter } from "@/containers/filters/BooleanFilter";
import { CardNav } from "@/containers/routes/CardNav";
import {
  Entities,
  EntitiesHeader,
  EntitiesList,
  useEntitiesState,
} from "@/containers/routes/places/Entities";
import { PlaceInfo } from "@/containers/routes/places/PlaceInfo";
import { PlaceLegendCard } from "@/containers/routes/places/PlaceLegend";
import { useCountry } from "@/requests/Country";
import { Location, useLocation } from "@/requests/Location";
import { Place, PlaceFilter, usePlace } from "@/requests/Place";
import { useHasOverflow } from "@/utils/useHasOverflow";

const cityFields = ["id", "name"];

const CityLabel = (props: { city: Location }) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  const hasOverflow = useHasOverflow({ ref: divRef, dimension: "x" });
  return (
    <Tooltip tooltip={hasOverflow ? props.city.name : null}>
      <span className="truncate" ref={divRef}>
        {props.city.name}
      </span>
    </Tooltip>
  );
};

const CityEntities = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { authAxios } = useAxios();
  const location = useLocation(authAxios);

  const countryId = searchParams.get("countryId");

  const combobox = useComboboxStore();
  const search = combobox.useState("value");
  const toolbar = useToolbarState({ count: 0 });

  const { cityId: value } = useParams();

  const { data: initialData } = useQuery({
    queryKey: ["places-city", "initialQuery", value],
    queryFn: () => {
      return location.getById(value ?? "", {
        params: { fields: cityFields, type: "locality" },
      });
    },
    enabled: !!value,
  });

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ["places-cities", search, countryId],
      initialPageParam: 1,
      queryFn: ({ pageParam: page }) =>
        location.get({
          params: {
            page,
            search,
            fields: cityFields,
            country: countryId,
            type: "locality",
          },
        }),
      getNextPageParam: (lastPage: any) => lastPage.next,
    });

  const computedData = useMemo(() => {
    if (!data) return { items: [], hasMore: false, totalCount: 0 };
    return {
      items: data?.pages.map((page) => page.results).flat() ?? [],
      hasMore: hasNextPage,
      totalCount: data?.pages[0].count ?? 0,
    };
  }, [data, hasNextPage]);

  const computedValue = useMemo(() => {
    const fetchedValue = computedData?.items.find((item) => item.id === value);
    if (fetchedValue) return fetchedValue;
    return initialData ?? null;
  }, [initialData, computedData, value]);

  const setValue = (value: string) => {
    navigate({
      pathname: `/places/${value}`,
      search: searchParams.toString(),
    });
  };

  const entitiesState = useEntitiesState({
    value: computedValue,
    onChange: (value) => setValue(value.id),
    combobox,
    data: computedData,
    labelSelector: (item) => <CityLabel city={item} />,
    valueSelector: (item) => item?.id,
    fetchNextPage: fetchNextPage,
    loading: isFetchingNextPage,
    title: "Cities",
    toolbar,
  });

  return (
    <Entities className="flex w-1/4 flex-1 flex-col">
      <EntitiesHeader state={entitiesState}>Here are filters</EntitiesHeader>
      <EntitiesList
        state={entitiesState}
        isLoading={!isFetchingNextPage && isFetching}
      />
    </Entities>
  );
};

const PlaceLabel = (props: { place: Place }) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  const hasOverflow = useHasOverflow({ ref: divRef, dimension: "x" });

  return (
    <div className="flex w-full items-center justify-between gap-2">
      <Tooltip tooltip={hasOverflow ? props.place.name : null}>
        <span className="truncate" ref={divRef}>
          {props.place.name}
        </span>
      </Tooltip>
      <div className="flex min-w-fit justify-end gap-2">
        {!props.place.is_clean && (
          <Tooltip tooltip="Not clean">
            <div className="h-2 w-2 rounded-full bg-primary-on" />
          </Tooltip>
        )}
        {!props.place.has_been_verified && (
          <Tooltip tooltip="Unverified">
            <div className="h-2 w-2 rounded-full bg-secondary-on" />
          </Tooltip>
        )}
        {props.place.has_issues && (
          <Tooltip tooltip="Has issues">
            <div className="h-2 w-2 rounded-full bg-danger-on" />
          </Tooltip>
        )}
        {!props.place.is_usable && (
          <Tooltip tooltip="Not usable">
            <div className="h-2 w-2 rounded-full bg-text-on" />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

const placeFields: string[] = [
  "id",
  "name",
  "has_issues",
  "has_been_verified",
  "is_clean",
  "is_usable",
];

export const PlaceEntities = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { authAxios } = useAxios();
  const place = usePlace(authAxios);

  const combobox = useComboboxStore();
  const search = combobox.useState("value");
  const toolbar = useToolbarState({ count: 0 });

  const { placeId: value, cityId } = useParams();

  const methods = useForm<PlaceFilter>();

  const filters = methods.watch();

  const { data: initialData } = useQuery({
    queryKey: ["places-place", "initialQuery", value],
    queryFn: () =>
      place.getById(value ?? "", {
        params: {
          fields: placeFields,
        },
      }),
    enabled: !!value,
  });

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ["places-place", search, cityId, filters],
      initialPageParam: 1,
      queryFn: ({ pageParam: page }) =>
        place.get({
          params: {
            page,
            search,
            locations: cityId,
            ...filters,
            fields: placeFields,
          },
        }),
      getNextPageParam: (lastPage: any) => lastPage.next,
    });

  const computedData = useMemo(() => {
    if (!data || !cityId) return { items: [], hasMore: false, totalCount: 0 };
    return {
      items: data?.pages.map((page) => page.results).flat() ?? [],
      hasMore: hasNextPage,
      totalCount: data?.pages[0].count ?? 0,
    };
  }, [data, hasNextPage, cityId]);

  const computedValue = useMemo(() => {
    const fetchedValue = computedData?.items.find((item) => item.id === value);
    if (fetchedValue) return fetchedValue;
    return initialData ?? null;
  }, [initialData, computedData, value]);

  const setValue = (value: string) => {
    navigate({
      pathname: `/places/${cityId}/${value}`,
      search: searchParams.toString(),
    });
  };

  const entitiesState = useEntitiesState({
    value: computedValue,
    onChange: (value) => setValue(value.id),
    combobox,
    data: computedData,
    labelSelector: (item) => <PlaceLabel place={item} />,
    valueSelector: (item) => item?.id,
    fetchNextPage: fetchNextPage,
    loading: isFetchingNextPage,
    title: "Places",
    toolbar,
  });

  return (
    <Entities className="flex w-1/4 flex-1 flex-col">
      <EntitiesHeader state={entitiesState} hasFilters>
        <FormProvider {...methods}>
          <BooleanFilter
            name="is_clean"
            label="Clean"
            trueLabel="Is clean"
            falseLabel="Not clean"
          />
          <BooleanFilter
            name="has_been_verified"
            label="Verified"
            trueLabel="Verified"
            falseLabel="Not verified"
          />
          <BooleanFilter
            name="has_issues"
            label="Has issues"
            trueLabel="Has issues"
            falseLabel="No issues"
          />
          <BooleanFilter
            name="is_usable"
            label="Is usable"
            trueLabel="Is usable"
            falseLabel="Not usable"
          />
          <BooleanFilter
            name="has_window_image"
            label="Has window image"
            trueLabel="Has window image"
            falseLabel="Hasn't window image"
          />
        </FormProvider>
      </EntitiesHeader>
      <EntitiesList
        state={entitiesState}
        isLoading={!isFetchingNextPage && isFetching}
      />
    </Entities>
  );
};

const PlaceCountrySelect = () => {
  const navigate = useNavigate();
  const [currentParams] = useSearchParams();

  const initialValueRef = React.useRef<string | null>(null);

  initialValueRef.current = currentParams.get("countryId")
    ? currentParams.get("countryId")
    : initialValueRef.current;

  const { cityId, placeId } = useParams();

  const { authAxios } = useAxios();
  const country = useCountry(authAxios);

  const methods = useForm<{ countryId: string | null }>({
    defaultValues: { countryId: initialValueRef.current },
  });

  const query = useCallback(
    async (options?: any) => {
      return await country.get({ params: options?.params });
    },
    [country],
  );

  const countryId = methods.watch("countryId");

  useEffect(() => {
    if (countryId !== initialValueRef.current && countryId) {
      navigate({
        pathname: "/places",
        search: `?countryId=${countryId}`,
      });
    } else if (!countryId) {
      navigate({
        pathname: `/places${cityId ? `/${cityId}` : ""}${
          placeId ? `/${placeId}` : ""
        }`,
      });
    }
  }, [countryId, navigate, cityId, placeId]);

  return (
    <FormProvider {...methods}>
      <div className="w-48">
        <HasOneField
          placeholder="Select a country"
          name="countryId"
          query={query}
        />
      </div>
    </FormProvider>
  );
};

export const Places = () => {
  return (
    <CardNav title="Places">
      <div className="mb-5 flex items-center gap-3">
        <PlaceCountrySelect />
        <PlaceLegendCard variant="primary">Not clean</PlaceLegendCard>
        <PlaceLegendCard variant="secondary">Unverified</PlaceLegendCard>
        <PlaceLegendCard variant="danger">Has issues</PlaceLegendCard>
        <PlaceLegendCard variant="text">Not usable</PlaceLegendCard>
      </div>
      <div className="flex w-full flex-1 gap-3 overflow-hidden">
        <CityEntities />
        <PlaceEntities />
        <PlaceInfo />
      </div>
    </CardNav>
  );
};
