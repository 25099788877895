import {
  DisclosureContent,
  DisclosureContentProps,
  DisclosureStore,
  DisclosureStoreProps,
  useDisclosureStore,
} from "@ariakit/react";
import clsx from "clsx";
import React from "react";

export type ToolbarStore = DisclosureStore;

export type ToolbarStateProps = DisclosureStoreProps & {
  count: number;
};

export type ToolbarState = {
  count: number;
  store: ToolbarStore;
};

export const useToolbarState = (props: ToolbarStateProps): ToolbarState => {
  const { count, ...storeProps } = props;
  return {
    count: props.count,
    store: useDisclosureStore(storeProps),
  };
};

export type ToolbarProps = React.PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement>
>;

export const Toolbar = React.forwardRef<HTMLDivElement, ToolbarProps>(
  ({ children, className, ...props }: ToolbarProps, ref) => {
    return (
      <div
        ref={ref}
        role="toolbar"
        aria-orientation="horizontal"
        className={clsx(className, `bg-white pb-2`)}
        {...props}
      >
        {children}
      </div>
    );
  },
);

export type ToolbarControlsProps = React.PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement>
>;

export const ToolbarControls = React.forwardRef<
  HTMLDivElement,
  ToolbarControlsProps
>(({ children, className, ...props }: ToolbarControlsProps, ref) => {
  return (
    <div
      ref={ref}
      className={clsx(className, "flex flex-1 flex-wrap items-center gap-2")}
      {...props}
    >
      {children}
    </div>
  );
});

export type ToolbarDrawerProps =
  React.PropsWithChildren<DisclosureContentProps>;

export const ToolbarDrawer = React.forwardRef<
  HTMLDivElement,
  ToolbarDrawerProps
>((props, ref) => {
  return (
    <DisclosureContent
      ref={ref}
      className={clsx("flex gap-2 overflow-auto whitespace-nowrap p-4")}
      {...props}
    />
  );
});
