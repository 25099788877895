import {
  Tooltip as AriakitTooltip,
  TooltipProps as AriakitTooltipProps,
  TooltipAnchor,
  TooltipStoreProps,
  useTooltipStore as useAriakitTooltipStore,
} from "@ariakit/react";
import * as React from "react";

export const useTooltipStore = useAriakitTooltipStore;

type TooltipProps = {
  children: React.ReactNode;
  tooltip: React.ReactNode;
  placement?: TooltipStoreProps["placement"];
} & AriakitTooltipProps;

export const Tooltip = (props: TooltipProps) => {
  const store = useTooltipStore({
    placement: props.placement,
  });
  return (
    <>
      <TooltipAnchor
        store={store}
        render={React.cloneElement(React.Children.only(props.children as any))}
        {...props}
      />
      {props.tooltip && (
        <AriakitTooltip
          store={store}
          gutter={props.gutter ?? 8}
          className="z-tooltip whitespace-pre-wrap rounded bg-text-bg-hover-stronger px-2 py-1 text-sm text-white"
          {...props}
        >
          {props.tooltip}
        </AriakitTooltip>
      )}
    </>
  );
};
