import { useMutation, useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { toast } from "react-toastify";

import { useAxios } from "@/containers/AxiosContext";
import { HasOneField } from "@/containers/fields/HasOneField";
import { MapField } from "@/containers/fields/MapField";
import { TextAreaField } from "@/containers/fields/TextAreaField";
import { TextInputField } from "@/containers/fields/TextInputField";
import {
  PlaceImages,
  usePlaceImageState,
} from "@/containers/place/PlaceImages";
import { PlacePeriods } from "@/containers/place/PlacePeriods";
import { EntityOptions } from "@/requests/AbstractRessources";
import { City } from "@/requests/City";
import {
  CommercialInfo,
  CommercialInfoForm,
  useCommercialInfo,
} from "@/requests/CommercialInfo";
import { useGoogleLocation } from "@/requests/GoogleLocation";
import { ImageForm, useImage } from "@/requests/Image";
import { Location, LocationType, useLocation } from "@/requests/Location";
import { OpenaiType, useOpenaiType } from "@/requests/OpenaiType";
import {
  Period,
  PeriodFinalForm,
  PeriodForm,
  usePeriod,
} from "@/requests/Period";
import {
  Place,
  PlaceFinalForm,
  PlaceForm,
  PlaceInitialForm,
  usePlace,
} from "@/requests/Place";
import { Type, useType } from "@/requests/Type";
import { usePrevious } from "@/utils/usePrevious";
import { validations } from "@/utils/validations";

type TypeFamily = "parent_type" | "child_type";

const TypeField = (props: {
  type: TypeFamily;
  parentType?: string;
  suggestion?: OpenaiType;
}) => {
  const { authAxios } = useAxios();
  const type = useType(authAxios);

  const { setValue } = useFormContext();

  const previousParentType = usePrevious(props.parentType);

  const query = useCallback(
    async (options?: EntityOptions) => {
      return await type.get({
        params: {
          ...options?.params,
          is_parent: props.type === "parent_type",
          parent: props.parentType,
        },
      });
    },
    [type, props.type, props.parentType],
  );

  useEffect(() => {
    if (
      props.type === "parent_type" &&
      props.parentType &&
      previousParentType &&
      props.parentType !== previousParentType
    ) {
      setValue("child_type", null);
    }
  }, [props.type, props.parentType, setValue, previousParentType]);

  return (
    <HasOneField<Type>
      label={props.type === "parent_type" ? "Type" : "Sub type"}
      name={props.type}
      hint={`Suggestion: ${props.suggestion?.[
        props.type === "parent_type" ? "type" : "sub_type"
      ]}`}
      query={query}
      invalidQueryValues={[props.parentType]}
      required
      scale="sm"
      className="flex-1"
    />
  );
};

export const LocationField = (props: { type: LocationType }) => {
  const { authAxios } = useAxios();
  const location = useLocation(authAxios);

  const query = useCallback(
    async (options?: EntityOptions) => {
      return await location.get({
        params: {
          ...options?.params,
          type: props.type,
        },
      });
    },
    [location, props.type],
  );

  const label = useMemo(() => {
    switch (props.type) {
      case "administrative_area_level_2":
        return "Region";
      case "administrative_area_level_1":
        return "County";
      case "postal_code":
        return "Zip code";
      case "locality":
        return "City";
      default:
        return "Location";
    }
  }, [props.type]);

  return (
    <HasOneField<Location>
      label={label}
      name={props.type}
      query={query}
      disabled
      scale="sm"
      className="flex-1"
    />
  );
};

type ExpandedPlace = Place<{
  city: City;
  commercial_info: CommercialInfo;
  periods: Period;
  locations: Location;
  types: Type;
}>;

const useOpenaiTypeSuggestion = (props: { place: ExpandedPlace }) => {
  const { authAxios } = useAxios();
  const type = useOpenaiType(authAxios);

  const { data } = useQuery({
    queryKey: ["openai-type-suggestion", props.place.id],
    queryFn: () =>
      type.get({
        params: {
          place: props.place.id,
        },
      }),
    enabled: !!props.place,
  });

  return data;
};

const useGoogleLocationSuggestion = (props: { place: ExpandedPlace }) => {
  const { authAxios } = useAxios();
  const location = useGoogleLocation(authAxios);

  const { data } = useQuery({
    queryKey: ["google-location-suggestion", props.place.id],
    queryFn: () =>
      location.get({
        params: {
          place: props.place.id,
        },
      }),
    enabled: !!props.place,
  });

  return data;
};

type PlaceInfoFormProps = {
  data: Place<{
    city: City;
    commercial_info: CommercialInfo;
    periods: Period;
    locations: Location;
    types: Type;
  }>;
  onSubmit?: () => void;
  setIsLoading?: (isLoading: boolean) => void;
  children?: React.ReactNode;
  className?: string;
};

export type PlaceInfoFormRef = {
  onSubmit: () => Promise<void>;
  onSubmitAsync: (submitMethod: () => Promise<void>) => void;
  scrollToTop: () => void;
};

export const PlaceInfoForm = React.forwardRef<
  PlaceInfoFormRef,
  PlaceInfoFormProps
>((props, ref) => {
  const { data, setIsLoading } = props;

  const { authAxios } = useAxios();
  const place = usePlace(authAxios);
  const image = useImage(authAxios);
  const period = usePeriod(authAxios);
  const commercialInfo = useCommercialInfo(authAxios);

  const { data: images } = useQuery({
    queryKey: ["place-window-images", data.id],
    queryFn: () =>
      image.get({
        params: {
          place: data.id,
          is_window_image: true,
          fields: ["id", "image", "name", "place", "is_window_image"],
        },
      }),
  });

  const locationSuggestion = useGoogleLocationSuggestion({ place: data });

  const placeImages = usePlaceImageState({
    initialImages: images?.results ?? [],
    placeId: data.id,
  });
  const formRef = React.useRef<HTMLDivElement>(null);

  const toLocalString = (hour: number, minute: number) => {
    return `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;
  };

  const values: PlaceInitialForm | undefined = useMemo(() => {
    if (!data) return undefined;
    return {
      id: data.id,
      name: data.name,
      latitude: data.latitude,
      longitude: data.longitude,
      description: data.commercial_info.description,
      commercial_info_id: data.commercial_info.id,
      phone: data.commercial_info.phone,
      web_site: data.commercial_info.web_site,
      parent_type: data.types.find((type) => !type.parent)?.id,
      child_type: data.types.find((type) => type.parent)?.id,
      administrative_area_level_2: data.locations.find(
        (location) => location.type === "administrative_area_level_2",
      )?.id,
      administrative_area_level_1: data.locations.find(
        (location) => location.type === "administrative_area_level_1",
      )?.id,
      postal_code: data.locations.find(
        (location) => location.type === "postal_code",
      )?.id,
      locality: data.locations.find((location) => location.type === "locality")
        ?.id,
      periods: data.periods.map((period) => ({
        day: period.day,
        start_hour: toLocalString(period.start_hour, period.start_min),
        end_hour: toLocalString(period.end_hour, period.end_min),
      })),
    };
  }, [data]);

  const methods = useForm<PlaceInitialForm>({ values });

  const placeMutation = useMutation({
    mutationFn: (data: PlaceForm) => place.patch(data.id, { data }),
  });

  const periodMutation = useMutation({
    mutationFn: (data: PeriodForm[]) => period.bulkUpdate(data[0].place, data),
  });

  const commercialInfoMutation = useMutation({
    mutationFn: (data: CommercialInfoForm) =>
      commercialInfo.patch(data.id, { data }),
  });

  const imageMutation = useMutation({
    mutationFn: (dataImage: ImageForm[]) =>
      image.bulkUpdate(data.id, dataImage),
  });

  const onSubmit = useCallback(
    async (data: PlaceFinalForm) => {
      try {
        setIsLoading?.(true);
        const placePromise = placeMutation.mutateAsync({
          id: data.id,
          name: data.name,
          types: [data.parent_type, data.child_type],
          latitude: data.latitude,
          longitude: data.longitude,
        });
        const periodPromise = data.periods.length
          ? periodMutation.mutateAsync(
              data.periods.map((period: PeriodFinalForm) => ({
                id: period.id,
                day: period.day,
                start_hour: parseInt(period.start_hour.split(":")[0]),
                start_min: parseInt(period.start_hour.split(":")[1]),
                end_hour: parseInt(period.end_hour.split(":")[0]),
                end_min: parseInt(period.end_hour.split(":")[1]),
                place: data.id,
              })),
            )
          : null;
        const commercialInfoPromise = commercialInfoMutation.mutateAsync({
          id: data.commercial_info_id,
          description: data.description,
          phone: data.phone,
          web_site: data.web_site,
          place: data.id,
        });

        const imagePromise = imageMutation.mutateAsync(
          await placeImages.getBase64Images(),
        );

        await Promise.all([
          placePromise,
          commercialInfoPromise,
          periodPromise,
          imagePromise,
        ]);
        props.onSubmit?.();
        methods.reset();
        formRef.current?.scrollTo(0, 0);
      } catch (e) {
        props.setIsLoading?.(false);
        toast.error("An error occurred");
      }
    },
    [
      placeMutation,
      periodMutation,
      commercialInfoMutation,
      props,
      setIsLoading,
      imageMutation,
      placeImages,
      methods,
    ],
  );

  useImperativeHandle(
    ref,
    () => ({
      // @ts-ignore
      onSubmit: methods.handleSubmit(onSubmit),
      onSubmitAsync: (submitMethod) =>
        methods.handleSubmit(async (data) => {
          await onSubmit(data as PlaceFinalForm);
          await submitMethod();
          setIsLoading?.(false);
        })(),
      scrollToTop: () => formRef.current?.scrollTo(0, 0),
    }),
    [methods, onSubmit, setIsLoading],
  );

  const typeSuggestion = useOpenaiTypeSuggestion({ place: data });

  const parentType = methods.watch("parent_type");

  return (
    <div
      ref={formRef}
      className={clsx(
        "flex w-full flex-1 shrink flex-col gap-6 overflow-auto",
        props.className,
      )}
    >
      {props.children}
      <FormProvider {...methods}>
        <TextInputField label="Name" name="name" required scale="sm" />
        <TextAreaField
          label="Description"
          name="description"
          disabled
          rows={10}
          scale="sm"
        />
        <div className="flex gap-4">
          <TextInputField
            label="Phone"
            name="phone"
            scale="sm"
            className="flex-1"
            placeholder="e.g.: 06 12 34 56 78"
            validate={{
              phone: (value: string) => validations.phone(value) as boolean,
            }}
          />
          <TextInputField
            label="Website"
            name="web_site"
            scale="sm"
            className="flex-1"
            placeholder="e.g.: https://musee-france.fr"
            validate={{
              url: (value: string) => validations.url(value) as boolean,
            }}
          />
        </div>
        <div className="flex gap-6">
          <TypeField type="parent_type" suggestion={typeSuggestion} />
          {parentType && (
            <TypeField
              type="child_type"
              parentType={parentType}
              suggestion={typeSuggestion}
            />
          )}
        </div>
        <PlacePeriods />
        <div className="flex gap-6">
          <LocationField type="locality" />
          <LocationField type="administrative_area_level_1" />
        </div>
        <div className="flex gap-6">
          <LocationField type="administrative_area_level_2" />
          <LocationField type="postal_code" />
        </div>
        <div className="flex gap-6">
          <TextInputField
            label="Latitude"
            name="latitude"
            className="flex-1"
            scale="sm"
            required
          />
          <TextInputField
            label="Longitude"
            name="longitude"
            scale="sm"
            className="flex-1"
            required
          />
        </div>
        <MapField suggestion={locationSuggestion} />
        <PlaceImages state={placeImages} />
      </FormProvider>
    </div>
  );
});
