import React from "react";

import { useUserMe } from "@/containers/UserMeContext";

export const useHasGroup = (props: { group: string | string[] }) => {
  const { userMe } = useUserMe();

  if (!userMe) return false;
  if (!userMe.groups) return false;
  if (userMe.is_superuser) return true;

  const groupsLookup = Array.isArray(props.group) ? props.group : [props.group];

  const groups = userMe.groups.map((group) => group.name);

  return groupsLookup.some((group) => groups.includes(group));
};

export const HasGroup = (props: {
  group: string | string[];
  children: React.ReactNode;
}) => {
  const hasGroup = useHasGroup({ group: props.group });

  if (!hasGroup) return null;

  return <>{props.children}</>;
};
