import { AxiosInstance } from "axios";
import { useMemo } from "react";

import { City } from "@/requests/City";
import { CommercialInfo } from "@/requests/CommercialInfo";
import { Location } from "@/requests/Location";
import { Period, PeriodFinalForm, PeriodInitialForm } from "@/requests/Period";
import { SessionPlaceReportReason } from "@/requests/SessionPlace";
import { Type } from "@/requests/Type";

import Entity, { EntityReturn, ExpandableField } from "./AbstractRessources";

export interface Place<T = string> {
  id: string;
  name: string;
  city: ExpandableField<T, "city">;
  latitude: number;
  longitude: number;
  types: ExpandableField<T, "types">[];
  locations: ExpandableField<T, "locations">[];
  commercial_info: ExpandableField<T, "commercial_info">;
  periods: ExpandableField<T, "periods">[];
  has_been_verified: boolean;
  user: ExpandableField<T, "user">;
  validator_user: ExpandableField<T, "validator_user">;
  is_clean: boolean;
  is_usable: boolean;
  has_issues: boolean;
  has_issues_reason: SessionPlaceReportReason;
}

export interface PlaceInitialForm {
  id?: string;
  name?: string;
  city?: string;
  latitude?: number;
  longitude?: number;
  description?: string;
  web_site?: string;
  phone?: string;
  ticket_site?: string;
  parent_type?: string;
  child_type?: string;
  administrative_area_level_2?: string;
  locality?: string;
  administrative_area_level_1?: string;
  postal_code?: string;
  commercial_info_id: string;
  periods?: PeriodInitialForm[];
}

export interface PlaceFinalForm extends PlaceInitialForm {
  id: string;
  name: string;
  city: string;
  latitude: number;
  longitude: number;
  description: string;
  parent_type: string;
  child_type: string;
  administrative_area_level_2: string;
  locality: string;
  administrative_area_level_1: string;
  postal_code: string;
  periods: PeriodFinalForm[];
}

export interface PlaceForm {
  id: string;
  name: string;
  types: string[];
  latitude: number;
  longitude: number;
}

export interface PlaceFilter {
  is_clean?: boolean;
  has_issues?: boolean;
  is_usable?: boolean;
  has_been_verified?: boolean;
}

export interface PlaceIssueForm {
  has_issues_reason: SessionPlaceReportReason;
  has_issues: boolean;
}

export interface PlaceReturn<T> extends EntityReturn<Place<T>> {
  getCurrentPlace: () => Promise<Place<{ city: City }>>;
  getCurrentValidatingPlace: () => Promise<
    Place<{
      city: City;
      commercial_info: CommercialInfo;
      types: Type;
      locations: Location;
      periods: Period;
    }>
  >;
  submitIssue: () => Promise<Place<T>>;
  submitValidationIssue: () => Promise<Place<T>>;
  validate: () => Promise<Place<T>>;
}

export const HasIssueChoices = [
  { label: "Form error", value: "form_error" },
  { label: "This place does not belong to Fyndee", value: "unwanted_place" },
  { label: "No close type", value: "no_type" },
  { label: "Place closed", value: "place_closed" },
  { label: "Place not found", value: "place_not_found" },
  { label: "Place inside another", value: "place_inside_another" },
  { label: "Duplicate place", value: "duplicate_place" },
];

const Place = <T>(axios: AxiosInstance): PlaceReturn<T> => {
  return {
    ...Entity("places", axios),

    getCurrentPlace() {
      return this.request({ method: "GET", url: `${this.url}current-place/` });
    },

    getCurrentValidatingPlace() {
      return this.request({
        method: "GET",
        url: `${this.url}current-validating-place/`,
      });
    },

    submitIssue() {
      return this.request({ method: "GET", url: `${this.url}submit-issue/` });
    },

    submitValidationIssue() {
      return this.request({
        method: "GET",
        url: `${this.url}submit-validation-issue/`,
      });
    },

    validate() {
      return this.request({ method: "GET", url: `${this.url}validate/` });
    },
  };
};

export const usePlace = <T>(axios: AxiosInstance) => {
  return useMemo(() => Place<T>(axios), [axios]);
};
