import React, { useCallback } from "react";

import { Button } from "@/components/Button";
import { Icon } from "@/components/Icon";
import { TextInputScale } from "@/components/TextInput";
import { TextInputField } from "@/containers/fields/TextInputField";
import { validations } from "@/utils/validations";

type PasswordName = "password" | "password2";
type PasswordLabel = "Password" | "Confirm password";

export const PasswordField = (props: {
  name: PasswordName;
  label: PasswordLabel;
  getValues: (value: string) => string;
  validate?: Record<string, any>;
  scale?: TextInputScale;
}) => {
  const [type, setType] = React.useState<"password" | "text">("password");
  const toggle = useCallback(() => {
    setType((type) => (type === "password" ? "text" : "password"));
  }, []);

  return (
    <TextInputField
      label={props.label}
      name={props.name}
      type={type}
      required
      validate={{
        passwordLength: (value) => validations.passwordLength(value) as boolean,
        differentThanUsername: (value) =>
          validations.differentThanUsername(value, props.getValues) as boolean,
        minLengthDigit: (value) => validations.minLengthDigit(value) as boolean,
        minLengthSpecial: (value) =>
          validations.minLengthSpecial(value) as boolean,
        minLengthUpper: (value) => validations.minLengthUpper(value) as boolean,
        minLengthChar: (value) => validations.minLengthChar(value) as boolean,
        ...props.validate,
      }}
      scale={props.scale}
      placeholder="e.g. fYndEe46!#"
      autoComplete="new-password"
      endAdornment={({ buttonScale }) => (
        <Button appearance="text" iconOnly scale={buttonScale} variant="text">
          <Icon
            name={type === "password" ? "eye" : "eye-slash"}
            onClick={toggle}
          />
        </Button>
      )}
    />
  );
};
