import { AxiosInstance } from "axios";
import { useMemo } from "react";

import Entity, { EntityReturn } from "./AbstractRessources";

interface Token {
  access: string;
  refresh: string;
}

export interface TokenForm {
  username: string;
  password: string;
}

const Token = (axios: AxiosInstance): EntityReturn<Token> => {
  return {
    ...Entity("token", axios),
  };
};

export const useToken = (axios: AxiosInstance) => {
  return useMemo(() => Token(axios), [axios]);
};
