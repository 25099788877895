import * as React from "react";
import { useFormContext } from "react-hook-form";

import {
  TextArea,
  TextAreaProps,
  TextInputGroup,
  TextInputGroupProps,
  TextInputLabel,
} from "@/components/TextInput";
import { ErrorHandler } from "@/containers/fields/ErrorHandler";

interface TextAreaFieldProps extends TextAreaProps {
  label?: string;
  name: string;
  required?: boolean;
  hint?: React.ReactNode;
  validate?: Record<string, (v: any) => boolean>;
  startAdornment?: TextInputGroupProps["startAdornment"];
  startAdornmentSize?: TextInputGroupProps["startAdornmentSize"];
  endAdornment?: TextInputGroupProps["endAdornment"];
  endAdornmentSize?: TextInputGroupProps["endAdornmentSize"];
}

export const TextAreaField = (props: TextAreaFieldProps) => {
  const {
    label,
    name,
    validate,
    required = false,
    scale = "md",
    hint,
    startAdornment,
    startAdornmentSize,
    endAdornment,
    endAdornmentSize,
    className,
    ...rest
  } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={className}>
      {label && (
        <TextInputLabel required={required} scale={scale}>
          {label}
        </TextInputLabel>
      )}
      <TextInputGroup
        scale={scale}
        startAdornment={startAdornment}
        startAdornmentSize={startAdornmentSize}
        endAdornment={endAdornment}
        endAdornmentSize={endAdornmentSize}
      >
        <TextArea
          scale={scale}
          aria-invalid={errors[name] ? "true" : "false"}
          {...rest}
          {...register(name, {
            required: { value: required, message: "This field is required" },
            validate,
          })}
        />
      </TextInputGroup>
      {!errors[name] && hint && (
        <div className="mt-2 text-sm text-grey-on">{hint}</div>
      )}
      <ErrorHandler name={name} errors={errors} />
    </div>
  );
};
