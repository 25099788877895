import { AxiosInstance } from "axios";

export type ExpandableField<T, K extends string> = T extends {
  [P in K]: infer U;
}
  ? U
  : string;

export interface Data {
  [key: string]: any;
}

export interface Params {
  fields?: string[];
  page?: number;
  page_size?: number;
  ordering?: string;
  search?: string;
  expand?: string[];
  [key: string]: any;
}

export interface EntityOptions {
  params?: Params;
  headers?: Record<string, any>;
  data?: Data;
  method?: string;
  url?: string;
}

export interface EntityResponse<T> {
  count?: number;
  results: T[];
  next?: number | null;
}

export interface EntityReturn<T> {
  url: string;
  request: (options?: EntityOptions) => Promise<any>;
  get: (options?: EntityOptions) => Promise<EntityResponse<T>>;
  list: (options?: EntityOptions) => Promise<T[]>;
  getById: (id: string | number, options?: EntityOptions) => Promise<T>;
  create: (data: Data, options?: EntityOptions) => Promise<T>;
  delete: (id: string | number, options?: EntityOptions) => Promise<T>;
  edit: (id: string | number, options?: EntityOptions) => Promise<T>;
  patch: (id: string | number, options?: EntityOptions) => Promise<T>;
}

function paramsToString(params: Params) {
  const stringParams = { ...params };
  Object.keys(params).forEach((param) => {
    stringParams[param] = Array.isArray(params[param])
      ? params[param].join(",")
      : params[param];
  });
  return stringParams;
}

const Entity = (
  entityName: string,
  axios: AxiosInstance,
): EntityReturn<any> => {
  return {
    url: `${process.env.REACT_APP_API_URL}${entityName}/`,
    async request({
      params = {},
      headers = {},
      data = {},
      method = "get",
      // @ts-ignore
      url = this.url,
    }: EntityOptions = {}): Promise<any> {
      const requestObject: EntityOptions = {
        method,
        url,
        headers,
        params: paramsToString(params),
      };
      if (Object.keys(data).length) {
        requestObject["data"] = data;
      }
      return axios(requestObject)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    async get({ params = {}, headers = {} }: EntityOptions = {}) {
      const { count, results, next } = await this.request({
        headers,
        params,
      }).catch((error) => {
        throw error;
      });
      return {
        count,
        results,
        next: params?.page && next ? params.page + 1 : null,
      };
    },
    async list({ params = {}, headers = {} }: EntityOptions = {}) {
      const { results } = await this.request({
        params,
        headers,
      }).catch((error) => {
        throw error;
      });
      return results;
    },
    getById(
      id: string | number,
      { params = {}, headers = {} }: EntityOptions = {},
    ) {
      return this.request({
        url: this.url + id + "/",
        headers,
        params,
      });
    },
    create(data: Data = {}, { params = {}, headers = {} }: EntityOptions = {}) {
      return this.request({
        method: "post",
        params,
        data,
        headers,
      });
    },
    delete(
      id: string | number,
      { data = {}, params = {} }: EntityOptions = {},
    ) {
      return this.request({
        method: "delete",
        url: this.url + id + "/",
        params,
        data,
      });
    },
    edit(id: string | number, { data = {}, params = {} }: EntityOptions = {}) {
      return this.request({
        method: "put",
        url: this.url + id + "/",
        params,
        data,
      });
    },
    patch(id: string | number, { data = {}, params = {} }: EntityOptions = {}) {
      for (const key in data) {
        if (data[key] === null) {
          delete data[key];
        }
      }
      return this.request({
        method: "patch",
        url: this.url + id + "/",
        params,
        data,
      });
    },
  };
};

export default Entity;
