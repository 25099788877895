import { AxiosInstance } from "axios";
import { useMemo } from "react";

import Entity, { EntityReturn } from "@/requests/AbstractRessources";
import { Place } from "@/requests/Place";

export interface Image {
  id: string;
  name: string;
  place: string;
  image: string;
  is_window_image: boolean;
}

export interface ImageForm {
  id: string | null;
  name: string;
  place: string;
  image: string;
  is_window_image: boolean;
  width?: number;
  height?: number;
}

export interface ImageReturn extends EntityReturn<Image> {
  bulkUpdate(placeId: Place["id"], data: ImageForm[]): Promise<Image[]>;
}

const Image = (axios: AxiosInstance): ImageReturn => {
  return {
    ...Entity("images", axios),
    bulkUpdate(placeId, data) {
      return this.request({
        method: "PUT",
        url: `${this.url}place/${placeId}/bulk-update/`,
        data,
      });
    },
  };
};

export const useImage = (axios: AxiosInstance) => {
  return useMemo(() => Image(axios), [axios]);
};
