import { ComboboxStore } from "@ariakit/react";
import { useVirtualizer } from "@tanstack/react-virtual";
import clsx from "clsx";
import React from "react";

import { Button } from "@/components/Button";
import { Card } from "@/components/Card";
import { EmptyList } from "@/components/EmptyList";
import { Icon } from "@/components/Icon";
import { Loader } from "@/components/Loader";
import { SearchBar } from "@/components/SearchBar";
import {
  Toolbar,
  ToolbarControls,
  ToolbarDrawer,
  ToolbarState,
} from "@/components/Toolbar";
import { Tooltip } from "@/components/Tooltip";

type Result<TItem> = {
  items: TItem[];
  hasMore: boolean;
  totalCount: number;
};

interface EntitiesStateProps<TItem> {
  value: TItem | null;
  title: string;
  data: Result<TItem> | null;
  loading: boolean;
  fetchNextPage: () => void;
  labelSelector: (item: TItem) => string | React.ReactNode;
  valueSelector: (item: TItem | null) => string | undefined;
  onChange: (item: TItem) => void;
  combobox: ComboboxStore;
  toolbar: ToolbarState;
}

export interface EntitiesState<TItem> {
  combobox: ComboboxStore;
  title: string;
  data: Result<TItem>;
  value: TItem | null;
  loading: boolean;
  fetchNextPage: () => void;
  labelSelector: (item: TItem) => string | React.ReactNode;
  valueSelector: (item: TItem | null) => string | undefined;
  onChange: (item: TItem) => void;
  toolbar: ToolbarState;
}

export const useEntitiesState = <TItem,>(
  props: EntitiesStateProps<TItem>,
): EntitiesState<TItem> => {
  return {
    ...props,
    data: props.data ?? { items: [], hasMore: false, totalCount: 0 },
  };
};

export const EntitiesHeader = <TItem,>(props: {
  children: React.ReactNode;
  state: EntitiesState<TItem>;
  hasFilters?: boolean;
}) => {
  const [isSearching, setIsSearching] = React.useState<boolean>(false);
  const { title, toolbar, combobox, data } = props.state;

  return (
    <>
      <Toolbar className="border-b">
        <ToolbarControls className="justify-between">
          {isSearching ? (
            <div className="flex items-center gap-2">
              <Tooltip tooltip="Leave search">
                <Button
                  appearance="text"
                  iconOnly
                  variant="text"
                  onClick={() => setIsSearching(false)}
                >
                  <Icon name="chevron-left" />
                </Button>
              </Tooltip>
              <SearchBar placeholder="Search..." store={combobox} />
            </div>
          ) : (
            <>
              <div className="flex items-center">
                <Tooltip tooltip="Search">
                  <Button
                    appearance="text"
                    className="mr-3"
                    iconOnly
                    onClick={() => setIsSearching(true)}
                  >
                    <Icon name="magnifyingglass" />
                  </Button>
                </Tooltip>
                <span className="font-semibold">
                  {title} ({data.totalCount})
                </span>
              </div>
              {props.hasFilters && (
                <Tooltip tooltip="Filters">
                  <Button
                    circle
                    iconOnly
                    onClick={() => toolbar.store.setOpen((open) => !open)}
                  >
                    <Icon name="slider-horizontal" />
                  </Button>
                </Tooltip>
              )}
            </>
          )}
        </ToolbarControls>
      </Toolbar>
      <ToolbarDrawer
        store={toolbar.store}
        className="mt-2 flex flex-wrap items-center gap-2 overflow-hidden overflow-ellipsis whitespace-pre-wrap"
      >
        {props.children}
      </ToolbarDrawer>
    </>
  );
};

type EntitiesListProps<TItem> = {
  state: EntitiesState<TItem>;
  isLoading?: boolean;
};

const useEntitiesList = <TItem,>(props: EntitiesListProps<TItem>) => {
  const {
    state: { data, loading, fetchNextPage, valueSelector },
  } = props;
  const listRef = React.useRef<HTMLDivElement>(null);
  const hasMore = data?.hasMore ?? false;
  // const totalCount = data?.totalCount ?? 0;
  const displayCount = data?.items.length ?? 0;

  const rowVirtualizer = useVirtualizer({
    count: displayCount,
    getScrollElement: () => listRef.current,
    estimateSize: () => 32,
    overscan: 5,
  });

  const virtualItems = rowVirtualizer.getVirtualItems();

  const selected = data.items.find(
    (item) => valueSelector(item) === valueSelector(props.state.value),
  )
    ? null
    : props.state.value;

  const lastItem = virtualItems[virtualItems.length - 1];
  React.useEffect(() => {
    if (lastItem && lastItem.index >= displayCount - 1 && hasMore && !loading) {
      fetchNextPage();
    }
  }, [lastItem, hasMore, loading, fetchNextPage, displayCount]);

  return { listRef, virtualItems, rowVirtualizer, selected };
};

export const EntityItem = <TItem,>(props: {
  state: EntitiesState<TItem>;
  item: TItem;
}) => {
  return (
    <div
      aria-selected={
        props.state.valueSelector(props.state.value) ===
        props.state.valueSelector(props.item)
      }
      className="flex h-full w-full items-center rounded px-2 hover:bg-grey-bg-light aria-selected:bg-primary-bg-light aria-selected:font-semibold aria-selected:text-primary-on"
    >
      {props.state.labelSelector(props.item)}
    </div>
  );
};

export const EntitiesList = <TItem,>(props: EntitiesListProps<TItem>) => {
  const { listRef, virtualItems, rowVirtualizer, selected } =
    useEntitiesList<TItem>(props);

  if (props.isLoading && !rowVirtualizer.getTotalSize()) return <Loader />;

  if (!rowVirtualizer.getTotalSize() && !props.isLoading) return <EmptyList />;

  return (
    <div ref={listRef} className="mt-4 flex flex-1 flex-col overflow-auto">
      {selected && (
        <button className="flex w-full border-b leading-8">
          <EntityItem state={props.state} item={selected} />
        </button>
      )}
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {!!virtualItems.length && (
          <>
            {virtualItems.map((virtualItem) => {
              const isLoading =
                virtualItem.index === props.state.data.items.length - 1 &&
                props.state.loading;
              const item = props.state.data?.items[virtualItem.index];
              return (
                <button
                  key={virtualItem.index}
                  onClick={() => props.state.onChange(item)}
                  className="border-b leading-8"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: `${virtualItem.size}px`,
                    transform: `translateY(${virtualItem.start}px)`,
                  }}
                >
                  {isLoading ? (
                    <Loader scale="sm" />
                  ) : (
                    <EntityItem state={props.state} item={item} />
                  )}
                </button>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export const Entities = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <Card
      className={clsx(
        "flex flex-1 flex-col rounded-lg border p-4",
        props.className,
      )}
    >
      {props.children}
    </Card>
  );
};
