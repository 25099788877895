import { ComboboxStore } from "@ariakit/react";
import { VirtualItem, Virtualizer } from "@tanstack/react-virtual";
import React from "react";

import { Badge } from "@/components/Badge";
import { EmptyList } from "@/components/EmptyList";
import { Loader } from "@/components/Loader";
import { SearchBar } from "@/components/SearchBar";
import { Table, TableBody, TableHead, Td, Tr } from "@/components/Table";
import {
  Toolbar,
  ToolbarControls,
  ToolbarDrawer,
  ToolbarState,
} from "@/components/Toolbar";
import { useVirtualizer } from "@/utils/useVirtualizer";

export interface Column<T> {
  render: (props: { item: T }) => React.ReactNode;
  minWidth?: number;
}

export interface ColumnHeader {
  render: () => React.ReactNode;
  minWidth?: number;
}

interface ListStateProps<T> {
  items: T[];
  totalCount: number;
  columns: Column<T>[];
  headers: ColumnHeader[];
  action?: Column<T>;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isLoading: boolean;
}

interface ListState<T> {
  virtualItems: VirtualItem[];
  totalCount: number;
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  listRef: React.RefObject<HTMLDivElement>;
  isLoading: boolean;
  headers: ColumnHeader[];
  action?: Column<T>;
  columns: Column<T>[];
  items: T[];
}

export const useListState = <T,>(props: ListStateProps<T>): ListState<T> => {
  const {
    items,
    headers,
    totalCount,
    action,
    hasNextPage,
    fetchNextPage,
    isLoading,
    columns,
  } = props;

  const { listRef, virtualItems, rowVirtualizer } = useVirtualizer({
    items,
    hasNextPage,
    isLoading,
    fetchNextPage,
    estimateSize: () => 80,
  });

  return {
    items,
    listRef,
    headers,
    action,
    totalCount,
    virtualItems,
    rowVirtualizer,
    isLoading,
    columns,
  };
};

const ListHeaders = <T,>(props: { state: ListState<T> }) => {
  return (
    <Tr isHeader>
      {props.state.headers.map((header, index) => (
        <Td
          key={index}
          style={{
            minWidth: header.minWidth ?? 200,
          }}
        >
          {header.render()}
        </Td>
      ))}
      {props.state.action && <Td isAction>Actions</Td>}
    </Tr>
  );
};

export const ListTitle = <T,>(props: {
  children: React.ReactNode;
  state: ListState<T>;
}) => {
  return (
    <div className="mb-6 flex items-center gap-2 text-xl font-semibold">
      {props.children} <Badge>{props.state.totalCount}</Badge>
    </div>
  );
};

export const ListToolbar = (props: {
  combobox: ComboboxStore;
  children?: React.ReactNode;
}) => {
  return (
    <Toolbar>
      <ToolbarControls className="justify-between">
        <SearchBar placeholder="Search..." store={props.combobox} />
        {props.children}
      </ToolbarControls>
    </Toolbar>
  );
};

export const ListToolbarDrawer = (props: {
  toolbar: ToolbarState;
  children: React.ReactNode;
}) => {
  return (
    <ToolbarDrawer
      store={props.toolbar.store}
      className="mt-2 flex flex-wrap items-center gap-2 overflow-hidden overflow-ellipsis whitespace-pre-wrap"
    >
      {props.children}
    </ToolbarDrawer>
  );
};

export const List = <T,>(props: {
  state: ListState<T>;
  isLoading: boolean;
}) => {
  if (props.isLoading)
    return (
      <div className="flex w-full flex-1 items-center justify-center">
        <Loader />
      </div>
    );
  if (!props.isLoading && !props.state.items.length) return <EmptyList />;
  return (
    <Table ref={props.state.listRef}>
      <TableHead className="relative">
        <ListHeaders state={props.state} />
      </TableHead>
      <TableBody
        style={{
          position: "relative",
          height: props.state.rowVirtualizer.getTotalSize(),
        }}
      >
        {props.state.virtualItems.map((virtualItem) => {
          const item = props.state.items[virtualItem.index];
          const isLoading =
            virtualItem.index === props.state.items.length - 1 &&
            props.state.isLoading;
          return (
            <Tr
              key={virtualItem.index}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              {isLoading ? (
                <Td className="flex w-full items-center justify-center">
                  <Loader />
                </Td>
              ) : (
                <>
                  {props.state.columns.map((column, index) => (
                    <Td
                      key={index}
                      style={{
                        minWidth: column.minWidth ?? 200,
                      }}
                    >
                      {column.render({ item })}
                    </Td>
                  ))}
                  {props.state.action && (
                    <Td isAction>{props.state.action.render({ item })}</Td>
                  )}
                </>
              )}
            </Tr>
          );
        })}
      </TableBody>
    </Table>
  );
};
