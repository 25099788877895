import { UseMutationResult, useMutation } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Button } from "@/components/Button";
import { Icon } from "@/components/Icon";
import {
  Tile,
  TileEdit,
  TileHeader,
  TileRead,
  TileStore,
  useTileStore,
} from "@/components/Tile";
import { Tooltip } from "@/components/Tooltip";
import { useAxios } from "@/containers/AxiosContext";
import { IsSuperuser } from "@/containers/IsSuperuser";
import { useUserAccount } from "@/containers/account/Account";
import { HasManyField } from "@/containers/fields/HasManyField";
import { PasswordField } from "@/containers/fields/PasswordField";
import { TextInputField } from "@/containers/fields/TextInputField";
import { EntityOptions } from "@/requests/AbstractRessources";
import { Group, useGroup } from "@/requests/Group";
import {
  User,
  UserForm,
  UserResetPasswordForm,
  UserReturn,
  useUser,
} from "@/requests/User";
import { validations } from "@/utils/validations";

export const AccountTileHeader = (props: {
  store: TileStore;
  children: React.ReactNode;
  onEditClick?: () => void;
}) => {
  const onLocalEditClick = () => {
    if (props.store.state === "edit" && props.onEditClick) {
      props.onEditClick();
    } else {
      props.store.setState(props.store.state === "edit" ? "read" : "edit");
    }
  };
  return (
    <TileHeader>
      {props.children}
      <IsSuperuser>
        <div className="gap-2">
          <Tooltip tooltip={props.store.state === "edit" ? "Cancel" : "Edit"}>
            <Button
              variant={props.store.state === "edit" ? "text" : "primary"}
              iconOnly
              appearance="text"
              onClick={() =>
                props.store.setState(
                  props.store.state === "edit" ? "read" : "edit",
                )
              }
            >
              <Icon name={props.store.state === "edit" ? "xmark" : "pencil"} />
            </Button>
          </Tooltip>
          {props.store.state === "edit" && (
            <Tooltip tooltip={props.store.state === "edit" ? "Save" : "Edit"}>
              <Button iconOnly appearance="text" onClick={onLocalEditClick}>
                <Icon name="checkmark" />
              </Button>
            </Tooltip>
          )}
        </div>
      </IsSuperuser>
    </TileHeader>
  );
};

type UserTextInputTileFieldName =
  | "username"
  | "email"
  | "first_name"
  | "last_name"
  | "validation_price";

export const UserTextInputTileField = <T extends UserForm>(props: {
  name: UserTextInputTileFieldName;
  label: string;
  mutation: UseMutationResult<User, Error, UserForm>;
  validate?: Record<string, (v: any) => boolean>;
}) => {
  const { userAccount, setUserAccount } = useUserAccount();
  const tile = useTileStore();

  const methods = useForm<T | any>({
    values: { [props.name]: userAccount[props.name] },
  });

  const onSubmit = async (data: T) => {
    try {
      tile.setState("read");
      props.mutation.mutate(data);
      setUserAccount({ ...userAccount, [props.name]: data[props.name] });
      toast.success(`${props.label} updated`);
    } catch (e) {
      toast.error(`Error while updating ${props.label}`);
    }
  };

  return (
    <Tile store={tile}>
      <AccountTileHeader
        store={tile}
        onEditClick={methods.handleSubmit(onSubmit)}
      >
        {props.label}
      </AccountTileHeader>
      <TileEdit store={tile}>
        <FormProvider {...methods}>
          <TextInputField
            name={props.name}
            required
            scale="sm"
            validate={props.validate}
          />
        </FormProvider>
      </TileEdit>
      <TileRead store={tile}>{userAccount[props.name]}</TileRead>
    </Tile>
  );
};

type ExpandAccountUserGroupTile = { groups: Group };

const AccountGroupTile = () => {
  const { authAxios } = useAxios();

  const user = useUser<ExpandAccountUserGroupTile>(authAxios);
  const group = useGroup(authAxios);

  const { userAccount, setUserAccount } = useUserAccount();

  const tile = useTileStore();

  const query = useCallback(
    async (options?: EntityOptions) => {
      return await group.get({ params: options?.params });
    },
    [group],
  );

  const mutation = useMutation({
    mutationFn: (data: UserForm) =>
      user.patch(userAccount.id, { data, params: { expand: ["groups"] } }),
  });

  const methods = useForm<UserForm>({
    defaultValues: { groups: userAccount.groups.map((group) => group.id) },
  });

  const onSubmit = async (data: UserForm) => {
    try {
      tile.setState("read");
      const newUser = await mutation.mutateAsync(data);
      setUserAccount({ ...userAccount, groups: newUser.groups });
      toast.success(`Group updated`);
    } catch (e) {
      toast.error(`Error while updating group`);
    }
  };

  return (
    <Tile store={tile}>
      <AccountTileHeader
        store={tile}
        onEditClick={methods.handleSubmit(onSubmit)}
      >
        Rôles
      </AccountTileHeader>
      <TileEdit store={tile}>
        <FormProvider {...methods}>
          <HasManyField scale="sm" name="groups" query={query} />
        </FormProvider>
      </TileEdit>
      <TileRead store={tile}>
        {userAccount.groups.map((group) => group.name).join(",")}
      </TileRead>
    </Tile>
  );
};

const AccountPasswordTile = (props: { user: UserReturn }) => {
  const { userAccount } = useUserAccount();

  const tile = useTileStore();

  const mutation = useMutation({
    mutationFn: (data: UserResetPasswordForm) => props.user.resetPassword(data),
    onMutate: () => {
      tile.setState("read");
    },
    onSuccess: () => {
      toast.success(`${userAccount.username}'s password edited`);
    },
    onError: () => {
      toast.error("An error occurred");
    },
  });

  const methods = useForm<UserResetPasswordForm>();

  const onSubmit = (data: UserResetPasswordForm) => {
    mutation.mutate({ ...data, id: userAccount.id });
  };

  return (
    <Tile store={tile}>
      <AccountTileHeader
        store={tile}
        onEditClick={methods.handleSubmit(onSubmit)}
      >
        Password
      </AccountTileHeader>
      <TileEdit store={tile}>
        <FormProvider {...methods}>
          <PasswordField
            name="password"
            label="Password"
            scale="sm"
            getValues={methods.getValues}
          />
          <PasswordField
            name="password2"
            scale="sm"
            label="Confirm password"
            getValues={methods.getValues}
            validate={{
              sameAsPassword: (value: string) =>
                methods.getValues("password") === value ||
                ("Passwords must match" as any),
            }}
          />
        </FormProvider>
      </TileEdit>
      <TileRead store={tile}>••••••••••••••••</TileRead>
    </Tile>
  );
};

export const AccountInfosCard = (props: { user: UserReturn }) => {
  const { user } = props;
  const { userAccount } = useUserAccount();

  const mutation = useMutation({
    mutationFn: (data: UserForm) => user.patch(userAccount.id, { data }),
  });
  return (
    <div className="flex flex-1 flex-col gap-6 overflow-auto">
      <div className="flex gap-6">
        <UserTextInputTileField
          mutation={mutation}
          label="Username"
          name="username"
        />
        <UserTextInputTileField
          mutation={mutation}
          label="First name"
          name="first_name"
        />
      </div>
      <div className="flex gap-6">
        <UserTextInputTileField
          mutation={mutation}
          label="Last name"
          name="last_name"
        />
        <UserTextInputTileField
          mutation={mutation}
          label="Email"
          name="email"
          validate={{
            email: (value: string) => validations.email(value) as boolean,
          }}
        />
      </div>
      <div className="flex gap-6">
        <AccountPasswordTile user={user} />
        <AccountGroupTile />
      </div>
    </div>
  );
};

export const AccountInfos = () => {
  const { authAxios } = useAxios();
  const user = useUser(authAxios);

  return <AccountInfosCard user={user} />;
};
