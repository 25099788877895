import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { useComboboxStore } from "@/components/Combobox";
import {
  RemoteSelect,
  RemoteSelectProps,
  RemoteSelectState,
  useRemoteSelectState,
} from "@/components/Select/RemoteSelect";
import { TextInputLabel } from "@/components/TextInput";
import { ErrorHandler } from "@/containers/fields/ErrorHandler";
import { EntityOptions, EntityResponse } from "@/requests/AbstractRessources";

interface HasManyFieldProps<T> extends Omit<RemoteSelectProps<T>, "state"> {
  label?: string;
  name: string;
  query: (options?: EntityOptions) => Promise<EntityResponse<T>>;
  labelSelector?: RemoteSelectState<T>["labelSelector"];
  valueSelector?: RemoteSelectState<T>["valueSelector"];
  required?: boolean;
  hint?: React.ReactNode;
  validate?: Record<string, (v: any) => boolean>;
  className?: string;
}

export const HasManyField = <T extends { id: any; name: string }>(
  props: HasManyFieldProps<T>,
) => {
  const {
    valueSelector = (item) => item.id,
    labelSelector = (item) => item.name,
    label,
    name,
    validate,
    required = false,
    hint,
    scale,
    className,
    ...rest
  } = props;

  const combobox = useComboboxStore();
  const search = combobox.useState("value");

  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    register(name, {
      required: { value: required, message: "This field is required" },
      validate,
    });
  }, [register, validate, required, name]);

  const formValue: any[] = watch(name);

  const { data: initialData } = useQuery({
    queryKey: ["has-many-field", formValue, name],
    queryFn: () => props.query({ params: { id__in: formValue } }),
  });

  const { data, fetchNextPage, isFetching, hasNextPage } = useInfiniteQuery({
    queryKey: ["has-many-field", search, name],
    queryFn: ({ pageParam: page }) =>
      props.query({
        params: {
          search,
          page,
        },
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage: any) => lastPage.next,
  });

  const computedData = useMemo(() => {
    if (!data) return { items: [], hasMore: false, totalCount: 0 };
    return {
      items: data?.pages.map((page) => page.results).flat() ?? [],
      hasMore: hasNextPage,
      totalCount: data?.pages[0].count ?? 0,
    };
  }, [data, hasNextPage]);

  const value = useMemo(() => {
    const fetchedValue = computedData.items.filter(
      (item) => formValue?.find((v) => v === item.id),
    );
    if (fetchedValue?.length === formValue?.length) return fetchedValue;
    return (
      initialData?.results?.filter(
        (item) => formValue?.find((v) => v === item.id),
      ) ?? []
    );
  }, [formValue, initialData, computedData]);

  const select = useRemoteSelectState({
    value,
    onChange: (value) => {
      setValue(
        name,
        value.map((v: T) => v.id),
      );
    },
    valueSelector,
    labelSelector,
    title: label,
    combobox,
    required,
    appearance: "chip",
    data: computedData,
    loading: isFetching,
    fetchMore: () => {
      fetchNextPage();
    },
    getItem: (id) => {
      const initialItem = initialData?.results?.find((item) => item.id === id);
      if (initialItem) return initialItem;
      const item = computedData.items.find((item) => item.id === id);
      if (item) return item;
      throw new Error("Item not found");
    },
  });

  return (
    <div className={className}>
      {label && (
        <TextInputLabel required={required} scale={scale}>
          {label}
        </TextInputLabel>
      )}

      <RemoteSelect state={select} scale={scale} {...rest} />
      {!errors[name] && hint && (
        <div className="mt-1 text-sm text-grey-on">{hint}</div>
      )}
      <ErrorHandler name={name} errors={errors} />
    </div>
  );
};
