import dayjs from "dayjs";
import { useMemo } from "react";

import {
  EnumSelect,
  EnumSelectProps,
  useEnumSelectState,
} from "@/components/Select/EnumSelect";

export const generateSlots = () => {
  return Array.from({ length: 48 }, (_, index) =>
    dayjs()
      .hour(Math.floor(index / 2))
      .minute(index % 2 === 0 ? 0 : 30)
      .format("HH:mm"),
  );
};

export type TimePickerObject = {
  value: string;
  label: string;
};

export type TimePickerProps = {
  value: string;
  onChange: (value: string) => void;
  required?: boolean;
  title?: string;
} & Omit<EnumSelectProps<TimePickerObject>, "state">;

export const TimePicker = (props: TimePickerProps) => {
  const { value, onChange, required, title, ...rest } = props;
  const slots = generateSlots();

  const items = useMemo(() => {
    return slots.map((slot) => ({ value: slot, label: slot }));
  }, [slots]);

  const select = useEnumSelectState({
    value: items.find((item) => item.value === value) || null,
    onChange: (value) => {
      onChange(value?.value ?? "");
    },
    items,
    labelSelector: (item) => item.label,
    valueSelector: (item) => item.value,
    title,
    required,
  });

  return <EnumSelect state={select} {...rest} />;
};
