import { AxiosInstance } from "axios";
import { useMemo } from "react";

import Entity, {
  Data,
  EntityReturn,
  ExpandableField,
  Params,
} from "./AbstractRessources";

export interface User<T = string> {
  id: string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  image_cleaned: number;
  image_price: number;
  verified_places: number;
  is_superuser: boolean;
  is_usable: boolean;
  groups: ExpandableField<T, "groups">[];
  quality_controlled_place: number;
  quality_control_sessions_left_to_pay: {
    price: number;
    count: number;
  };
  sessions_left_to_pay: {
    price: number;
    count: number;
  };
  validation_price: number;
}

export interface UserForm {
  id?: string;
  username?: string;
  password?: string;
  password2?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  groups?: string[];
  validation_price?: number;
}

export interface UserResetPasswordForm {
  id: string;
  password: string;
  password2: string;
}

export interface UserReturn<T = string> extends EntityReturn<User<T>> {
  getForm: () => UserForm;
  register: (data: Data) => Promise<User<T>>;
  emailExist: (data: Data) => Promise<{ status: string }>;
  usernameExist: (data: Data) => Promise<{ status: string }>;
  resetPassword: (data: Data) => Promise<{ status: string }>;
  me: (params: Params) => Promise<User<T>>;
}

const User = <T>(axios: AxiosInstance): UserReturn<T> => {
  return {
    ...Entity("users", axios),

    getForm(): UserForm {
      return {
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        password: "",
        password2: "",
        groups: [],
      };
    },

    register(data: Data) {
      return this.request({
        method: "POST",
        url: `${this.url}register/`,
        data,
      });
    },

    emailExist(data: Data) {
      return this.request({
        method: "POST",
        url: `${this.url}email-exist/`,
        data,
      });
    },

    usernameExist(data: Data) {
      return this.request({
        method: "POST",
        url: `${this.url}username-exist/`,
        data,
      });
    },

    resetPassword(data: Data) {
      return this.request({
        method: "POST",
        url: `${this.url}reset-password/`,
        data,
      });
    },

    me(params: Params) {
      return this.request({ method: "GET", url: `${this.url}me/`, params });
    },
  };
};

export const useUser = <T>(axios: AxiosInstance) => {
  return useMemo(() => User<T>(axios), [axios]);
};
