import { useMutation, useQuery } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Button } from "@/components/Button";
import { Loader } from "@/components/Loader";
import { useAxios } from "@/containers/AxiosContext";
import { TextInputField } from "@/containers/fields/TextInputField";
import { CardNav } from "@/containers/routes/CardNav";
import {
  SessionSettingForm,
  useSessionSetting,
} from "@/requests/SessionSetting";

export const Admin = () => {
  const { authAxios } = useAxios();
  const sessionSetting = useSessionSetting(authAxios);

  const { data, isLoading } = useQuery({
    queryKey: ["admin", "session-setting"],
    queryFn: () => sessionSetting.current(),
  });

  const mutation = useMutation({
    mutationFn: (data: SessionSettingForm) => sessionSetting.create(data),
    onSuccess: () => {
      toast.success("Session settings updated");
    },
    onError: () => {
      toast.error("An error occurred");
    },
  });

  const methods = useForm<SessionSettingForm>({
    values: {
      session_length: data?.session_length,
      session_control_number: data?.session_control_number,
      session_refused_number: data?.session_refused_number,
    },
  });

  const onSubmit = (data: SessionSettingForm) => {
    mutation.mutate(data);
  };

  if (isLoading)
    return (
      <div className="flex w-full items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <CardNav title="Admin">
      <div className="flex flex-1 flex-col justify-between">
        <div className="flex flex-col gap-3">
          <FormProvider {...methods}>
            <TextInputField
              name="session_length"
              label="Session length"
              required
              validate={{
                number: (value: string) =>
                  (!isNaN(Number(value)) || "Must be a number") as boolean,
              }}
            />
            <TextInputField
              name="session_control_number"
              label="Session control number"
              required
              validate={{
                number: (value: string) =>
                  (!isNaN(Number(value)) || "Must be a number") as boolean,
              }}
            />
            <TextInputField
              name="session_refused_number"
              label="Session refused number"
              required
              validate={{
                number: (value: string) =>
                  (!isNaN(Number(value)) || "Must be a number") as boolean,
              }}
            />
          </FormProvider>
        </div>
        <div className="flex justify-end">
          <Button
            onClick={methods.handleSubmit(onSubmit)}
            isLoading={mutation.isPending}
          >
            Submit
          </Button>
        </div>
      </div>
    </CardNav>
  );
};
