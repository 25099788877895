import {
  Combobox as AriakitCombobox,
  ComboboxItem as AriakitComboboxItem,
  ComboboxItemProps as AriakitComboboxItemProps,
  ComboboxPopover as AriakitComboboxPopover,
  ComboboxPopoverProps as AriakitComboboxPopoverProps,
  ComboboxProps as AriakitComboboxProps,
  ComboboxSeparator as AriakitComboboxSeparator,
  ComboboxSeparatorProps as AriakitComboboxSeparatorProps,
  ComboboxStoreProps,
  useComboboxStore as useAriakitComboboxStore,
} from "@ariakit/react";
import clsx from "clsx";
import * as React from "react";
import { forwardRef } from "react";

import {
  ItemClassNameProps,
  ListSeparatorClassNameProps,
  getItemClassName,
  getListSeparatorClassName,
} from "./Menu";
import { PopoverCard } from "./Popover";

export type { ComboboxStore, ComboboxStoreProps } from "@ariakit/react";

export const useComboboxStore = ({ ...props }: ComboboxStoreProps = {}) => {
  return useAriakitComboboxStore(props);
};

export type ComboboxItemProps = AriakitComboboxItemProps & ItemClassNameProps;

export const ComboboxItem = forwardRef<HTMLDivElement, ComboboxItemProps>(
  ({ focusOnHover = true, variant, className, ...props }, ref) => {
    const itemClassName = getItemClassName({ variant, className });
    return (
      <AriakitComboboxItem
        ref={ref}
        focusOnHover={focusOnHover}
        className={itemClassName}
        {...props}
      />
    );
  },
);

export type ComboboxProps = AriakitComboboxProps<"input">;

export const Combobox = forwardRef<HTMLInputElement, ComboboxProps>(
  (props, ref) => {
    return <AriakitCombobox ref={ref} {...props} />;
  },
);

export type ComboboxPopoverProps = Omit<
  AriakitComboboxPopoverProps,
  "children"
> & {
  children: React.ReactNode;
};

export const ComboboxPopover = forwardRef<HTMLDivElement, ComboboxPopoverProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <AriakitComboboxPopover
        ref={ref}
        className={clsx(className, "p-1")}
        render={(popoverProps) => (
          <PopoverCard {...popoverProps}>{children}</PopoverCard>
        )}
        {...props}
      />
    );
  },
);

export type ComboboxSeparatorProps = AriakitComboboxSeparatorProps &
  ListSeparatorClassNameProps;

export const ComboboxSeparator = forwardRef<
  HTMLHRElement,
  ComboboxSeparatorProps
>(({ className, ...props }, ref) => {
  const listSeparatorClassName = getListSeparatorClassName({ className });
  return (
    <AriakitComboboxSeparator
      ref={ref}
      className={listSeparatorClassName}
      {...props}
    />
  );
});
