import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Button } from "@/components/Button";
import { Icon } from "@/components/Icon";
import {
  Slider,
  SliderBody,
  SliderDismiss,
  SliderFooter,
  SliderHeading,
  SliderStore,
} from "@/components/Slider";
import { useAxios } from "@/containers/AxiosContext";
import { HasManyField } from "@/containers/fields/HasManyField";
import { PasswordField } from "@/containers/fields/PasswordField";
import { TextInputField } from "@/containers/fields/TextInputField";
import { ExpandUserField } from "@/containers/routes/users/Users";
import { EntityOptions } from "@/requests/AbstractRessources";
import { useGroup } from "@/requests/Group";
import { UserForm, UserReturn } from "@/requests/User";
import { validations } from "@/utils/validations";

const UserGroupField = () => {
  const { authAxios } = useAxios();
  const group = useGroup(authAxios);

  const query = useCallback(
    async (options?: EntityOptions) => {
      return await group.get({ params: options?.params });
    },
    [group],
  );

  return <HasManyField label="Rôle" name="groups" query={query} />;
};

export const UserSlider = (props: {
  user: UserReturn<ExpandUserField>;
  defaultValues?: Partial<UserForm> | null;
  slider: SliderStore;
}) => {
  const queryClient = useQueryClient();
  const methods = useForm<UserForm>();

  const mutation = useMutation({
    mutationFn: (data: UserForm) => {
      if (props.defaultValues?.id)
        return props.user.patch(props.defaultValues.id, { data });
      return props.user.register(data);
    },
    onSuccess: (user) => {
      if (props.defaultValues?.id) {
        toast.success(`${user.username}'s edited`);
      } else {
        toast.success(`${user.username} added`);
      }
      props.slider.hide();
      methods.reset(props.user.getForm());
    },
    onError: () => {
      toast.error("An error occurred");
    },
    onSettled: async () => {
      return await queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  const onSubmit = async (data: UserForm) => {
    mutation.mutate(data);
  };

  useEffect(() => {
    if (props.defaultValues) {
      methods.reset(props.defaultValues);
    } else {
      methods.reset(props.user.getForm());
    }
  }, [methods, props.defaultValues, props.user]);

  return (
    <Slider store={props.slider}>
      <SliderHeading>
        {props.defaultValues?.id ? "Edit a user" : "Add a user"}
      </SliderHeading>
      <SliderBody className="gap-4">
        <FormProvider {...methods}>
          <TextInputField
            label="First name"
            name="first_name"
            placeholder="e.g. John"
            required
            endAdornment={({ iconSize }) => (
              <Icon size={iconSize} name="person-text-rectanle" />
            )}
          />
          <TextInputField
            label="Last name"
            name="last_name"
            required
            placeholder="e.g. Doe"
            endAdornment={({ iconSize }) => (
              <Icon size={iconSize} name="person-text-rectanle" />
            )}
          />
          <TextInputField
            label="Username"
            name="username"
            required
            placeholder="e.g. JohnDo"
            endAdornment={({ iconSize }) => (
              <Icon size={iconSize} name="person-text-rectanle" />
            )}
          />
          <TextInputField
            label="Email"
            name="email"
            required
            placeholder="e.g. john@doe.fr"
            validate={{
              email: (value: string) => validations.email(value) as boolean,
            }}
            endAdornment={({ iconSize }) => (
              <Icon size={iconSize} name="mail" />
            )}
          />
          <UserGroupField />
          {!props.defaultValues && (
            <>
              <PasswordField
                name="password"
                label="Password"
                getValues={methods.getValues}
              />
              <PasswordField
                name="password2"
                label="Confirm password"
                getValues={methods.getValues}
                validate={{
                  sameAsPassword: (value: string) =>
                    methods.getValues("password") === value ||
                    ("Passwords must match" as any),
                }}
              />
            </>
          )}
        </FormProvider>
      </SliderBody>
      <SliderFooter>
        <SliderDismiss
          store={props.slider}
          variant="text"
          appearance="text"
          isLoading={mutation.isPending}
        >
          Cancel
        </SliderDismiss>
        <Button
          onClick={methods.handleSubmit(onSubmit)}
          isLoading={mutation.isPending}
        >
          {props.defaultValues?.id ? "Save modifications" : "Add user"}
        </Button>
      </SliderFooter>
    </Slider>
  );
};
