import { AxiosInstance } from "axios";
import { useMemo } from "react";

import Entity, {
  EntityReturn,
  ExpandableField,
} from "@/requests/AbstractRessources";

export interface CommercialInfo<T = string> {
  id: string;
  web_site: string;
  description: string;
  phone: string;
  ticket_site: string;
  exported_at: string;
  place: ExpandableField<T, "place">;
}

export interface CommercialInfoInitialForm {
  id?: string;
  web_site?: string;
  description?: string;
  phone?: string;
  place: string;
}

export interface CommercialInfoFinalForm extends CommercialInfoInitialForm {
  id: string;
  description: string;
  place: string;
}

export type CommercialInfoForm = CommercialInfoFinalForm;

export type CommercialInfoReturn = EntityReturn<CommercialInfo>;

const CommercialInfo = (axios: AxiosInstance): CommercialInfoReturn => {
  return {
    ...Entity("commercial-infos", axios),
  };
};

export const useCommercialInfo = (axios: AxiosInstance) => {
  return useMemo(() => CommercialInfo(axios), [axios]);
};
