import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { Button } from "@/components/Button";
import {
  Slider,
  SliderBody,
  SliderDismiss,
  SliderFooter,
  SliderHeading,
  SliderStore,
} from "@/components/Slider";
import { useAxios } from "@/containers/AxiosContext";
import { useUserAccount } from "@/containers/account/Account";
import { EnumField } from "@/containers/fields/EnumField";
import { TextInputField } from "@/containers/fields/TextInputField";
import { PaySessionsInvoiceForm, useInvoice } from "@/requests/Invoice";
import { validations } from "@/utils/validations";

const types = [
  { value: "validation", label: "Validation" },
  { value: "quality_control", label: "Quality control" },
];

export const AccountInvoicesSlider = (props: { slider: SliderStore }) => {
  const queryClient = useQueryClient();

  const { authAxios } = useAxios();
  const invoice = useInvoice(authAxios);

  const { userAccount } = useUserAccount();

  const methods = useForm<PaySessionsInvoiceForm>();

  const mutation = useMutation({
    mutationFn: (data: PaySessionsInvoiceForm) => invoice.paySessions(data),
    onSuccess: async () => {
      const userQuery = queryClient.invalidateQueries({
        queryKey: ["user", String(userAccount.id)],
      });
      const invoicesQuery = queryClient.invalidateQueries({
        queryKey: ["account-invoices", userAccount.id],
      });
      await Promise.all([userQuery, invoicesQuery]);
      props.slider.hide();
    },
  });

  const type = methods.watch("type");
  const leftToPayLookup = useMemo(() => {
    if (type === "validation") {
      return userAccount.sessions_left_to_pay;
    }
    return userAccount.quality_control_sessions_left_to_pay;
  }, [
    type,
    userAccount.sessions_left_to_pay,
    userAccount.quality_control_sessions_left_to_pay,
  ]);

  const onSubmit = (data: PaySessionsInvoiceForm) => {
    mutation.mutate({ ...data, user: userAccount.id });
  };

  return (
    <Slider store={props.slider}>
      <SliderHeading>Create an invoice</SliderHeading>
      <SliderBody className="gap-4">
        <FormProvider {...methods}>
          <EnumField label="Type" name="type" items={types} />
          <TextInputField
            name="number"
            label="Session number"
            required
            hint={`Number of sessions left to pay: ${leftToPayLookup.count}`}
            validate={{
              max: (value: string) =>
                (parseInt(value) <= leftToPayLookup.count ||
                  `Number of sessions left to pay is too high (max: ${leftToPayLookup.count})`) as boolean,
              number: (value: string) => validations.number(value) as boolean,
            }}
          />
        </FormProvider>
      </SliderBody>
      <SliderFooter>
        <SliderDismiss
          variant="text"
          appearance="text"
          isLoading={mutation.isPending}
        >
          Cancel
        </SliderDismiss>
        <Button
          isLoading={mutation.isPending}
          onClick={methods.handleSubmit(onSubmit)}
        >
          Create
        </Button>
      </SliderFooter>
    </Slider>
  );
};
