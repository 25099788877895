import React from "react";

import { Card } from "@/components/Card";

export const CardNavTitle = (props: { children: React.ReactNode }) => {
  return (
    <div className="mb-6 flex items-center gap-2 text-xl font-semibold">
      {props.children}
    </div>
  );
};

export const CardNav = (props: {
  title: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  return (
    <div
      className="my-6 flex flex-1 flex-col px-6"
      style={{ width: "calc(100% - 48px)", ...props.style }}
    >
      <h2 className="mb-4 font-semibold">{props.title}</h2>
      <Card className="flex flex-1 flex-col overflow-hidden">
        {props.children}
      </Card>
    </div>
  );
};
