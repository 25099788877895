import clsx from "clsx";
import React, { useState } from "react";

type TileState = "edit" | "read";

export type TileStore = {
  state: TileState;
  read: () => void;
  edit: () => void;
  setState: (state: TileState) => void;
};

export const useTileStore = (): TileStore => {
  const [state, setState] = useState<TileState>("read");
  return {
    state,
    read: () => setState("read"),
    edit: () => setState("edit"),
    setState,
  };
};

export const TileRead = (props: {
  children: React.ReactNode;
  store: TileStore;
}) => {
  if (props.store.state === "edit") return null;
  return <>{props.children}</>;
};

export const TileEdit = (props: {
  children: React.ReactNode;
  store: TileStore;
}) => {
  if (props.store.state === "read") return null;
  return <>{props.children}</>;
};

export const TileHeader = (props: { children: React.ReactNode }) => {
  return (
    <div className="mb-2 flex items-center justify-between text-sm">
      {props.children}
    </div>
  );
};

export const Tile = (props: {
  store?: TileStore;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={clsx(
        "w-full rounded-md bg-grey-bg-light p-3",
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};
