import { useQuery } from "@tanstack/react-query";
import React, { createContext, useEffect } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

import { Chip } from "@/components/Chip";
import { Icon } from "@/components/Icon";
import { Loader } from "@/components/Loader";
import { useAxios } from "@/containers/AxiosContext";
import { HasGroup } from "@/containers/HasGroup";
import { AccountInfos } from "@/containers/account/AccountInfos";
import { AccountInvoices } from "@/containers/account/AccountInvoices";
import { AccountNav } from "@/containers/account/AccountNav";
import { AccountSessions } from "@/containers/account/AccountSessions";
import { CardNav, CardNavTitle } from "@/containers/routes/CardNav";
import { Group } from "@/requests/Group";
import { User as UserType, useUser } from "@/requests/User";

type ExpandUserField = { groups: Group };

const UserAccountContext = createContext<{
  userAccount: UserType<ExpandUserField>;
  setUserAccount: React.Dispatch<
    React.SetStateAction<UserType<ExpandUserField>>
  >;
} | null>(null);

export const UserAccountProvider = (props: {
  children: React.ReactNode;
  user: UserType<ExpandUserField>;
}) => {
  const [userAccount, setUserAccount] = React.useState<
    UserType<ExpandUserField>
  >(props.user);

  useEffect(() => {
    setUserAccount(props.user);
  }, [props.user]);

  return (
    <UserAccountContext.Provider value={{ userAccount, setUserAccount }}>
      {props.children}
    </UserAccountContext.Provider>
  );
};

export const useUserAccount = () => {
  const context = React.useContext(UserAccountContext);

  if (!context) {
    throw new Error("useUserAccount must be used within a UserAccountProvider");
  }

  return context;
};

const AccountLayout = (props: { children: React.ReactNode }) => {
  const { userAccount } = useUserAccount();
  return (
    <CardNav title="Profile" style={{ width: "calc(100% - 207px)" }}>
      <CardNavTitle>
        {userAccount.first_name} {userAccount.last_name}{" "}
        <span className="font-normal"> @{userAccount.username}</span>
      </CardNavTitle>
      <div className="mb-12 flex w-full gap-6">
        <HasGroup group="quality_controller">
          <Chip
            scale="lg"
            className="flex w-full items-center justify-center py-4"
          >
            <Icon name="lock-shield" size={28} />
            <span className="text-lg">
              {userAccount.quality_controlled_place}
            </span>
            <span className="text-lg font-normal">Controlled places</span>
          </Chip>
        </HasGroup>
        <HasGroup group="validator">
          <Chip
            scale="lg"
            className="flex w-full items-center justify-center py-4"
          >
            <Icon name="check-circle" size={28} />
            <span className="text-lg">{userAccount.verified_places}</span>
            <span className="text-lg font-normal">Validated places</span>
          </Chip>
        </HasGroup>
      </div>
      {props.children}
    </CardNav>
  );
};

export const Account = () => {
  const { authAxios } = useAxios();
  const user = useUser<ExpandUserField>(authAxios);

  const { id } = useParams();

  const { data } = useQuery({
    queryKey: ["user", id],
    queryFn: () =>
      user.getById(id ?? "", {
        params: {
          expand: [
            "groups",
            "quality_control_sessions_left_to_pay",
            "sessions_left_to_pay",
          ],
        },
      }),
    enabled: !!id,
  });

  if (!data)
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <UserAccountProvider user={data}>
      <AccountNav />
      <Routes>
        <Route path="/" element={<Navigate replace to="infos" />} />
        <Route
          path="infos"
          element={
            <AccountLayout>
              <AccountInfos />
            </AccountLayout>
          }
        />
        <Route
          path="invoices"
          element={
            <AccountLayout>
              <AccountInvoices />
            </AccountLayout>
          }
        />
        <Route
          path="quality-control"
          element={
            <AccountLayout>
              <AccountSessions />
            </AccountLayout>
          }
        />
      </Routes>
    </UserAccountProvider>
  );
};
