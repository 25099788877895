import { DialogStore } from "@ariakit/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Button } from "@/components/Button";
import { Dialog, DialogHeading, useDialogStore } from "@/components/Dialog";
import { Icon } from "@/components/Icon";
import { Loader } from "@/components/Loader";
import { Tooltip } from "@/components/Tooltip";
import { useAxios } from "@/containers/AxiosContext";
import { TextAreaField } from "@/containers/fields/TextAreaField";
import {
  PlaceInfoForm,
  PlaceInfoFormRef,
} from "@/containers/place/PlaceInfoForm";
import { CardNav, CardNavTitle } from "@/containers/routes/CardNav";
import { City } from "@/requests/City";
import { Location } from "@/requests/Location";
import { Place } from "@/requests/Place";
import {
  SessionPlaceReviewForm,
  useSessionPlace,
} from "@/requests/SessionPlace";

type QualityControlPlace = Place<{ city: City; locations: Location }>;

const QualityControlHeader = (props: { place: QualityControlPlace }) => {
  const city = props.place.locations.find((l) => l.type === "locality");
  if (!city) return null;
  const onGoogleClick = () => {
    window.open(
      `https://www.google.com/search?q=${props.place.name}+${city.name}&source=lmns&bih=947&biw=1920&hl=fr&sa=X&ved=2ahUKEwiDqoXg8cL_AhVRpycCHewiDFIQ_AUoAHoECAEQAA`,
      "_blank",
    );
  };

  return (
    <CardNavTitle>
      <div className="flex flex-1 items-center justify-between">
        {props.place.name}
        <div className="flex gap-2">
          <Tooltip tooltip="Search the place on google">
            <Button onClick={onGoogleClick} iconOnly circle>
              <Icon name="google" />
            </Button>
          </Tooltip>
        </div>
      </div>
    </CardNavTitle>
  );
};

const QualityControlRejectDialog = (props: {
  place: QualityControlPlace;
  onClick: () => void;
  isLoading: boolean;
  dialog: DialogStore;
}) => {
  return (
    <Dialog store={props.dialog}>
      <DialogHeading store={props.dialog}>Comment</DialogHeading>
      <div className="flex flex-col gap-3">
        <TextAreaField name="refused_comment" required />
        <div className="flex items-center justify-between gap-3">
          <Button
            variant="text"
            appearance="text"
            onClick={props.dialog.hide}
            isLoading={props.isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={props.onClick}
            isLoading={props.isLoading}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export const QualityControl = () => {
  const queryClient = useQueryClient();
  const { authAxios } = useAxios();
  const sessionPlace = useSessionPlace(authAxios);
  const dialog = useDialogStore();

  const placeInfoRef = React.useRef<PlaceInfoFormRef>(null);

  const [isSubmitLoading, setIsSubmitLoading] = React.useState<boolean>(false);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["quality-control-place"],
    queryFn: () => sessionPlace.currentQualityControl(),
    retry: 0,
  });

  const methods = useForm<SessionPlaceReviewForm>();

  const onAccept = useCallback(async () => {
    await sessionPlace.review({
      status: "accepted",
    });
    await queryClient.invalidateQueries({
      queryKey: ["quality-control-place"],
    });
    setIsSubmitLoading(false);
    toast.success("Place validated");
    placeInfoRef.current?.scrollToTop();
  }, [queryClient, sessionPlace, setIsSubmitLoading, placeInfoRef]);

  const onReject = useCallback(
    async (data: SessionPlaceReviewForm) => {
      await sessionPlace.review({
        status: "refused",
        refused_comment: data.refused_comment,
      });
      await queryClient.invalidateQueries({
        queryKey: ["quality-control-place"],
      });
      dialog.hide();
      methods.reset();
      setIsSubmitLoading(false);
      toast.success("Place rejected");
    },
    [dialog, queryClient, sessionPlace, methods, setIsSubmitLoading],
  );

  const onRejectClick = () => {
    if (!placeInfoRef.current) return;
    placeInfoRef.current.onSubmitAsync(methods.handleSubmit(onReject));
  };

  const onAcceptClick = () => {
    if (!placeInfoRef.current) return;
    placeInfoRef.current.onSubmitAsync(onAccept);
  };

  return (
    <CardNav title="Quality Control">
      {isLoading && (
        <div className="flex flex-1 items-center justify-center">
          <Loader />
        </div>
      )}
      {!isLoading && data && !isError && (
        <>
          <QualityControlHeader place={data.place} />
          <FormProvider {...methods}>
            <QualityControlRejectDialog
              place={data.place}
              onClick={onRejectClick}
              isLoading={isSubmitLoading}
              dialog={dialog}
            />
          </FormProvider>
          <PlaceInfoForm
            ref={placeInfoRef}
            data={data.place}
            onSubmit={() => {}}
            setIsLoading={setIsSubmitLoading}
          />
          <div className="mt-4 flex items-center justify-between">
            <Button
              variant="danger"
              isLoading={isSubmitLoading}
              onClick={dialog.show}
            >
              Reject the place
            </Button>
            <Button
              variant="success"
              onClick={onAcceptClick}
              isLoading={isSubmitLoading}
            >
              Accept the place
            </Button>
          </div>
        </>
      )}
      {isError && (
        <div className="flex flex-1 items-center justify-center font-semibold">
          No more places available, feel free to contact the support
        </div>
      )}
    </CardNav>
  );
};
