import clsx from "clsx";
import React from "react";

type LoaderVariant =
  | "primary"
  | "text"
  | "secondary"
  | "danger"
  | "success"
  | "warning"
  | "white";

const variants: Record<LoaderVariant, string> = {
  primary: /* tw */ `bg-primary-on`,
  secondary: /* tw */ `bg-secondary-on`,
  danger: /* tw */ `bg-danger-on`,
  success: /* tw */ `bg-success-on`,
  warning: /* tw */ `bg-warning-on`,
  white: /* tw */ `bg-white`,
  text: /* tw */ `bg-text-on`,
};

type LoaderScale = "xs" | "sm" | "md" | "lg";

type LoaderProps = {
  className?: string;
  scale?: LoaderScale;
  style?: React.CSSProperties;
  variant?: LoaderVariant;
};

const scales: Record<LoaderScale, string> = {
  xs: /* tw */ `h-1 w-1`,
  sm: /* tw */ `h-2 w-2`,
  md: /* tw */ `h-2.5 w-2.5`,
  lg: /* tw */ `h-3 w-3`,
};

const parentScales: Record<LoaderScale, string> = {
  xs: /* tw */ `h-4`,
  sm: /* tw */ `h-5`,
  md: /* tw */ `h-6`,
  lg: /* tw */ `h-6`,
};

export const Loader = (props: LoaderProps) => {
  const { className, scale = "md", variant = "primary", style } = props;
  return (
    <div
      className={clsx(
        className,
        "inline-flex items-center justify-center gap-2",
        parentScales[scale],
      )}
      style={style}
    >
      <div
        className={clsx(
          "animate-loader rounded-full bg-primary-on",
          scales[scale],
          variants[variant],
        )}
      />
      <div
        className={clsx(
          "animate-loader rounded-full bg-primary-on animation-delay-200",
          scales[scale],
          variants[variant],
        )}
      />
      <div
        className={clsx(
          "animate-loader rounded-full bg-primary-on animation-delay-400",
          scales[scale],
          variants[variant],
        )}
      />
    </div>
  );
};
