import { AxiosInstance } from "axios";
import { useMemo } from "react";

import Entity, {
  Data,
  EntityReturn,
  ExpandableField,
} from "@/requests/AbstractRessources";

import { City } from "./City";
import { CommercialInfo } from "./CommercialInfo";
import { Location } from "./Location";
import { Period } from "./Period";
import { Place } from "./Place";
import { Type } from "./Type";
import { User } from "./User";

export type ExpandedPotentialDuplicate = {
  validator_user: User;
  places: Place<{
    city: City;
    commercial_info: CommercialInfo;
    periods: Period;
    locations: Location;
    types: Type;
  }>;
};

export interface PotentialDuplicate<T = string> {
  id: string;
  verified: boolean;
  validator_user: ExpandableField<T, "validator_user">;
  places: ExpandableField<T, "places">[];
}

export interface PotentialDuplicateReturn<T>
  extends EntityReturn<PotentialDuplicate<T>> {
  validate: (data: Data) => Promise<PotentialDuplicate<T>>;
  current: () => Promise<PotentialDuplicate<T>>;
}

const PotentialDuplicate = <T>(
  axios: AxiosInstance,
): PotentialDuplicateReturn<T> => {
  return {
    ...Entity("potential-duplicates", axios),
    validate(data: Data) {
      return this.request({
        method: "POST",
        url: `${this.url}validate/`,
        data,
      });
    },
    current() {
      return this.request({ method: "GET", url: `${this.url}current/` });
    },
  };
};

export const usePotentialDuplicate = <T>(axios: AxiosInstance) => {
  return useMemo(() => PotentialDuplicate<T>(axios), [axios]);
};
