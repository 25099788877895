import { useQuery } from "@tanstack/react-query";
import React, { createContext } from "react";

import { useAuth } from "@/containers/AuthContext";
import { useAxios } from "@/containers/AxiosContext";
import { Group } from "@/requests/Group";
import { User, useUser } from "@/requests/User";

type ExpandUserMeField = { groups: Group };

const UserMeContext = createContext<{
  userMe: User<ExpandUserMeField>;
} | null>(null);

const UserMeProvider = (props: { children: React.ReactNode }) => {
  const { authenticated } = useAuth();
  const { authAxios } = useAxios();
  const user = useUser<ExpandUserMeField>(authAxios);
  const { data } = useQuery({
    queryKey: ["userMe"],
    enabled: authenticated,
    queryFn: () => user.me({ params: { expand: ["groups"] } }),
  });

  if (!data) return null;

  return (
    <UserMeContext.Provider
      value={{
        userMe: data,
      }}
    >
      {props.children}
    </UserMeContext.Provider>
  );
};

const useUserMe = () => {
  const context = React.useContext(UserMeContext);
  if (!context) {
    throw new Error("useUserMe must be used within a UserMeProvider");
  }
  return context;
};

export { UserMeProvider, useUserMe };
