import { AxiosInstance } from "axios";
import { useMemo } from "react";

import Entity, { EntityReturn } from "@/requests/AbstractRessources";

export type LocationType =
  | "administrative_area_level_2"
  | "locality"
  | "administrative_area_level_1"
  | "country"
  | "postal_code";

export interface Location {
  id: string;
  name: string;
  type: LocationType;
  long_description: string;
  exported_at: string;
  bound_latitude: number;
  bound_longitude: number;
  bound_latitude_delta: number;
  bound_longitude_delta: number;
}

export type LocationReturn = EntityReturn<Location>;

const Location = (axios: AxiosInstance): LocationReturn => {
  return {
    ...Entity("locations", axios),
  };
};

export const useLocation = (axios: AxiosInstance) => {
  return useMemo(() => Location(axios), [axios]);
};
