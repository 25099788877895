import * as React from "react";

import { ComboboxItem, ComboboxItemProps } from "../Combobox";
import { SelectItem, SelectItemCheck, SelectItemProps } from "./Select";

export type SelectComboboxItemProps = Omit<ComboboxItemProps, "state"> & {
  children: React.ReactNode;
  value: Exclude<SelectItemProps["value"], undefined>;
  store: SelectItemProps["store"];
};

export const SelectComboboxItem = React.forwardRef<
  HTMLDivElement,
  SelectItemProps
>(({ children, store, value, ...props }, ref) => {
  return (
    <ComboboxItem
      ref={ref}
      focusOnHover
      {...props}
      render={(comboboxItemProps) => (
        <SelectItem store={store} value={value} {...comboboxItemProps}>
          <>
            <SelectItemCheck store={store} />
            {children}
          </>
        </SelectItem>
      )}
    />
  );
});
