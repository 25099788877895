import { Navigate, Route, Routes } from "react-router-dom";

import { useHasGroup } from "@/containers/HasGroup";
import { TasksNav } from "@/containers/routes/tasks/TasksNav";
import { QualityControl } from "@/containers/routes/tasks/quality-control/QualityControl";
import { Validating } from "@/containers/routes/tasks/validating/Validating";

import { PotentialDuplicate } from "./potential-duplicate/PotentialDuplicate";

const IndexNavigate = () => {
  const isValidator = useHasGroup({ group: "validator" });
  if (isValidator) {
    return <Navigate to="validating" />;
  }
  return <Navigate to="quality-control" />;
};

export const Tasks = () => {
  return (
    <>
      <TasksNav />
      <Routes>
        <Route path="/" element={<IndexNavigate />} />
        {/*<Route path="has-issues" element={<HasIssues />} />*/}
        <Route path="validating" element={<Validating />} />
        {/*<Route index path="cleaning" element={<Cleaning />} />*/}
        <Route path="quality-control" element={<QualityControl />} />
        <Route path="potential-duplicate" element={<PotentialDuplicate />} />
      </Routes>
    </>
  );
};
