import clsx from "clsx";
import React from "react";

type PlacePastilleVariant = "primary" | "text" | "danger" | "secondary";

const variants: Record<PlacePastilleVariant, string> = {
  primary: /* tw */ `bg-primary-on`,
  secondary: /* tw */ `bg-secondary-on`,
  danger: /* tw */ `bg-danger-on`,
  text: /* tw */ `bg-text-on`,
};

export const PlacePastille = (props: { variant: PlacePastilleVariant }) => {
  return (
    <div className={clsx("h-2 w-2 rounded-full", variants[props.variant])} />
  );
};

export const PlaceLegendCard = (props: {
  children: React.ReactNode;
  variant: PlacePastilleVariant;
}) => {
  return (
    <div className="flex items-center gap-2 rounded bg-grey-bg-light px-2 py-1  font-semibold">
      <PlacePastille variant={props.variant} />
      {props.children}
    </div>
  );
};
