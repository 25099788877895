import axios, { AxiosInstance } from "axios";
import React, { createContext } from "react";

import { useAuth } from "./AuthContext";

const AxiosContext = createContext<{
  authAxios: AxiosInstance;
  publicAxios: AxiosInstance;
} | null>(null);

const AxiosProvider = (props: { children: React.ReactNode }) => {
  const { getAccessToken, refreshAccessToken, refreshTokenRef, logout } =
    useAuth();

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const publicAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  authAxios.interceptors.request.use(
    (config) => {
      if (!config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${getAccessToken()}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  const refreshAuthLogic = (failedRequest: any) => {
    const data = {
      refresh: refreshTokenRef.current,
    };

    const options = {
      method: "POST",
      data,
      url: `${process.env.REACT_APP_API_URL}token/refresh/`,
    };

    return axios(options)
      .then(async (tokenRefreshResponse) => {
        await refreshAccessToken(tokenRefreshResponse.data.access);

        failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.access}`;

        return Promise.resolve(failedRequest.config);
      })
      .catch(() => {
        logout();
        window.location.replace("/login");
      });
  };

  authAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 503)
      ) {
        const failedRequest = await refreshAuthLogic(error);
        if (failedRequest && failedRequest.data) {
          failedRequest.data = JSON.parse(failedRequest.data);
        }
        return axios(failedRequest);
      }
    },
  );

  return (
    <AxiosContext.Provider
      value={{
        authAxios,
        publicAxios,
      }}
    >
      {props.children}
    </AxiosContext.Provider>
  );
};

const useAxios = () => {
  const context = React.useContext(AxiosContext);
  if (!context) {
    throw new Error("useAxios must be used within a AxiosProvider");
  }
  return context;
};

export { AxiosContext, AxiosProvider, useAxios };
