import { useVirtualizer as useReactVitualVirtualizer } from "@tanstack/react-virtual";
import React from "react";

export const useVirtualizer = <T>(props: {
  items: T[];
  hasNextPage: boolean;
  isLoading: boolean;
  horizontal?: boolean;
  fetchNextPage: () => void;
  estimateSize: () => number;
}) => {
  const {
    items,
    hasNextPage,
    isLoading,
    fetchNextPage,
    estimateSize,
    horizontal,
  } = props;
  const listRef = React.useRef<HTMLDivElement>(null);
  const displayCount = items.length ?? 0;

  const rowVirtualizer = useReactVitualVirtualizer({
    count: displayCount,
    getScrollElement: () => listRef.current,
    estimateSize,
    horizontal,
    overscan: 5,
  });

  const virtualItems = rowVirtualizer.getVirtualItems();

  const lastItem = virtualItems[virtualItems.length - 1];
  React.useEffect(() => {
    if (
      lastItem &&
      lastItem.index >= displayCount - 1 &&
      hasNextPage &&
      !isLoading
    ) {
      fetchNextPage();
    }
  }, [lastItem, hasNextPage, isLoading, fetchNextPage, displayCount]);

  return { listRef, virtualItems, rowVirtualizer };
};
