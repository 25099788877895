import * as React from "react";
import { FieldError, FieldErrors } from "react-hook-form";

export const ErrorHandler = (props: {
  name?: string;
  errors?: FieldErrors;
  error?: FieldError;
}) => {
  const errorMessage = React.useMemo(() => {
    if (props.error) return props.error?.message as string;
    if (props.errors && props.name)
      return props.errors?.[props.name]?.message as string;
    return "";
  }, [props]);
  return <div className="mt-2 text-sm text-danger-on">{errorMessage}</div>;
};
