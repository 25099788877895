import { AxiosInstance } from "axios";
import { useMemo } from "react";

import Entity, {
  EntityReturn,
  ExpandableField,
} from "@/requests/AbstractRessources";

export interface Type<T = string> {
  id: string;
  name: string;
  label: string;
  parent: ExpandableField<T, "parent">;
  color: string;
  exported_at: string;
}

export type TypeReturn = EntityReturn<Type>;

const Type = (axios: AxiosInstance): TypeReturn => {
  return {
    ...Entity("types", axios),
  };
};

export const useType = (axios: AxiosInstance) => {
  return useMemo(() => Type(axios), [axios]);
};
