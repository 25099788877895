import clsx from "clsx";
import React from "react";

export const Card = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={clsx("rounded-lg border p-4", props.className)}>
      {props.children}
    </div>
  );
};
