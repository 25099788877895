import {
  Dialog,
  DialogDismiss,
  DialogDismissProps,
  DialogHeading,
  DialogHeadingProps,
  DialogProps,
  DialogStoreProps,
  useDialogStore,
} from "@ariakit/react";
import clsx from "clsx";
import React from "react";

import { Button, ButtonProps } from "@/components/Button";
import { Icon } from "@/components/Icon";

export type { DialogStore as SliderStore } from "@ariakit/react";

export const useSliderStore = (props?: DialogStoreProps) => {
  return useDialogStore({ ...props, animated: true });
};

const SliderBackdrop = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div
      ref={ref}
      className={clsx(
        "fixed inset-0 z-backdrop bg-text-bg bg-opacity-50 opacity-0",
        "transition-opacity duration-300 ease-in-out",
        "data-[enter]:opacity-100",
      )}
      {...props}
    />
  );
});

type SliderDismissProps = {
  children: React.ReactNode;
} & DialogDismissProps &
  ButtonProps;

export const SliderDismiss = (props: SliderDismissProps) => {
  return <DialogDismiss render={<Button />} {...props} />;
};

type SliderHeadingProps = {
  children: React.ReactNode;
} & DialogHeadingProps;

export const SliderHeading = (props: SliderHeadingProps) => {
  return (
    <DialogHeading
      className="mb-4 flex items-center justify-between border-b pb-2 text-lg"
      {...props}
    >
      {props.children}
      <SliderDismiss iconOnly appearance="text">
        <Icon name="xmark" className="h-4 w-4" />
      </SliderDismiss>
    </DialogHeading>
  );
};

export const SliderFooter = (props: { children: React.ReactNode }) => {
  return (
    <div className="mt-4 flex shrink-0 items-center justify-between border-t pt-2">
      {props.children}
    </div>
  );
};

export const SliderBody = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={clsx(
        "flex flex-shrink flex-grow flex-col overflow-auto",
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};

export const Slider = React.forwardRef<HTMLDivElement, DialogProps>(
  (props, ref) => {
    return (
      <Dialog
        ref={ref}
        className={clsx(
          "fixed -right-96 bottom-0 top-0 z-dialog flex h-full min-w-96 flex-col justify-between bg-white p-4 ",
          "transform transition-all duration-300 ease-in-out",
          "data-[enter]:right-0",
        )}
        backdrop={<SliderBackdrop />}
        {...props}
      />
    );
  },
);
