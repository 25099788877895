import { AxiosInstance } from "axios";
import { useMemo } from "react";

import Entity, {
  EntityReturn,
  ExpandableField,
} from "@/requests/AbstractRessources";

export interface Invoice<T = string> {
  id: string;
  sessions: ExpandableField<T, "sessions">[];
  price: number;
  created_at: string;
  user: ExpandableField<T, "user">;
}

type InvoiceType = "validation" | "quality_control";

export interface PaySessionsInvoiceForm {
  number: number;
  user: string;
  type: InvoiceType;
}

export interface InvoiceReturn extends EntityReturn<Invoice> {
  paySessions: (data: PaySessionsInvoiceForm) => Promise<Invoice>;
}

const Invoice = (axios: AxiosInstance): InvoiceReturn => {
  return {
    ...Entity("invoices", axios),

    paySessions(data: PaySessionsInvoiceForm) {
      return this.request({
        method: "POST",
        url: `${this.url}pay-sessions/`,
        data,
      });
    },
  };
};

export const useInvoice = (axios: AxiosInstance) => {
  return useMemo(() => Invoice(axios), [axios]);
};
