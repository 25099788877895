import clsx from "clsx";
import * as React from "react";

import { Combobox, ComboboxProps } from "@/components/Combobox";
import { Icon } from "@/components/Icon";

interface SearchBarProps extends ComboboxProps {
  className?: string;
}

export const SearchBar = React.forwardRef<HTMLInputElement, SearchBarProps>(
  (props, ref) => {
    const { className, ...rest } = props;
    return (
      <div
        className={clsx(
          "flex max-w-60 items-center gap-2 rounded-3xl border border-grey-border-light bg-grey-bg p-2",
          "focus-within:border-primary-border hover:border-primary-border",
          className,
        )}
      >
        <Icon name="magnifyingglass" className="text-grey-on" />
        <Combobox
          ref={ref}
          autoComplete="off"
          autoFocus
          className={clsx(
            "peer",
            "w-full bg-grey-bg placeholder:text-grey-on disabled:hover:border-inherit ",
            "focus:outline-none disabled:opacity-disabled",
          )}
          {...rest}
        />
      </div>
    );
  },
);
