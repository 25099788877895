import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Button } from "@/components/Button";
import { Icon } from "@/components/Icon";
import { Nav, NavBody, NavFooter, NavHeader, NavItem } from "@/components/Nav";
import { useAuth } from "@/containers/AuthContext";

const NavRouterLink = (props: { to: string; children: React.ReactNode }) => {
  const location = useLocation();
  const current = location.pathname.includes(props.to);

  return (
    <Link to={props.to}>
      <NavItem aria-selected={current}>{props.children}</NavItem>
    </Link>
  );
};

export const AccountNav = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const onLogoutClick = () => {
    logout();
    navigate("/login");
  };
  return (
    <Nav aria-label="Profile">
      <NavHeader>Account</NavHeader>
      <NavBody>
        <NavRouterLink to={`infos`}>
          <Icon name="profile" />
          Profile
        </NavRouterLink>
        <NavRouterLink to={`invoices`}>
          <Icon name="eurosign" />
          Invoices
        </NavRouterLink>
        <NavRouterLink to={`quality-control`}>
          <Icon name="lock-shield" />
          Sessions
        </NavRouterLink>
      </NavBody>
      <NavFooter>
        <Button scale="sm" variant="danger" onClick={onLogoutClick}>
          Logout
        </Button>
      </NavFooter>
    </Nav>
  );
};
