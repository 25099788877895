import React from "react";
import { Link, useLocation } from "react-router-dom";

import { Icon } from "@/components/Icon";
import {
  Navbar,
  NavbarBody,
  NavbarFooter,
  NavbarHeader,
  NavbarItemButton,
} from "@/components/Navbar";
import { HasGroup } from "@/containers/HasGroup";
import { IsSuperuser } from "@/containers/IsSuperuser";
import { useUserMe } from "@/containers/UserMeContext";
// @ts-ignore
import { ReactComponent as Logo } from "@/images/logo.svg";

const NavRouterLink = (props: { to: string; title: string; icon: string }) => {
  const location = useLocation();
  const current = location.pathname.includes(props.to);

  return (
    <NavbarItemButton title={props.title} aria-selected={current}>
      <Link to={props.to}>
        <Icon name={`${props.icon}${current ? "-fill" : ""}`} size={20} />
      </Link>
    </NavbarItemButton>
  );
};

export const AppNav = () => {
  const { userMe } = useUserMe();

  return (
    <Navbar aria-label="Main nav" side="left" theme="dark">
      <NavbarHeader>
        <div className="flex items-center justify-center">
          <Logo />
        </div>
      </NavbarHeader>
      <NavbarBody>
        <IsSuperuser>
          <NavRouterLink to="/dashboard" title="Dashboard" icon="house" />
        </IsSuperuser>
        <HasGroup
          group={[
            "cleaner",
            "validator",
            "quality_controller",
            "potential_duplicate_validator",
          ]}
        >
          <NavRouterLink to="/tasks" title="Tasks" icon="clipboard" />
        </HasGroup>
        <IsSuperuser>
          <NavRouterLink to="/places" title="Places" icon="museum-web" />
        </IsSuperuser>
        <IsSuperuser>
          <NavRouterLink to="/users" title="Users" icon="people" />
        </IsSuperuser>
      </NavbarBody>
      <NavbarFooter>
        <IsSuperuser>
          <NavRouterLink to="/admin" title="Admin" icon="gearshape" />
        </IsSuperuser>
        <NavRouterLink
          to={`/profile/${userMe.id}`}
          title="Profile"
          icon="profile"
        />
      </NavbarFooter>
    </Navbar>
  );
};
