import * as React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { TextInputLabel } from "@/components/TextInput";
import { TimePicker, TimePickerProps } from "@/components/TimePicker";
import { ErrorHandler } from "@/containers/fields/ErrorHandler";

type TimePickerFieldProps = {
  label: string;
  name: string;
  hint?: React.ReactNode;
  validate?: Record<string, (v: any) => boolean>;
  className?: string;
} & Omit<Omit<TimePickerProps, "onChange">, "value">;

export const TimePickerField = (props: TimePickerFieldProps) => {
  const {
    label,
    name,
    validate,
    required = false,
    scale = "md",
    hint,
    className,
    ...rest
  } = props;

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    register(name, {
      required: { value: required, message: "This field is required" },
      validate,
    });
  }, [register, validate, required, name]);

  const value = watch(name);
  const onChange = (value: string) => {
    setValue(name, value);
  };

  return (
    <div className={className}>
      <TextInputLabel required={required} scale={scale}>
        {label}
      </TextInputLabel>
      <TimePicker
        scale={scale}
        aria-invalid={errors[name] ? "true" : "false"}
        value={value}
        required={required}
        onChange={onChange}
        {...rest}
      />
      <ErrorHandler name={name} errors={errors} />
    </div>
  );
};
