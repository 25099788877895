import { AxiosInstance } from "axios";
import { useMemo } from "react";

import Entity, { EntityOptions } from "@/requests/AbstractRessources";

export interface GoogleImage {
  displayLink: string;
  fileFormat: string;
  htmlSnippet: string;
  htmlTitle: string;
  image: {
    contextLink: string;
    height: number;
    width: number;
    thumbnailLink: string;
    thumbnailHeight: number;
    thumbnailWidth: number;
  };
  link: string;
  mime: string;
  snippet: string;
  title: string;
  kind: string;
}

type GoogleImageGetResponse = {
  images: GoogleImage[];
  start: number;
};

interface GoogleImageReturn {
  get: (options?: EntityOptions) => Promise<GoogleImageGetResponse>;
}

const GoogleImage = (axios: AxiosInstance): GoogleImageReturn => {
  return {
    async get({
      params = {},
      headers = {},
    }: EntityOptions = {}): Promise<GoogleImageGetResponse> {
      const start = params?.start ?? 0;
      const { images } = await Entity("image-suggestions", axios).request({
        params: {
          ...params,
        },
        headers,
      });

      return { images, start: images.length ? start + 10 : null };
    },
  };
};

export const useGoogleImage = (axios: AxiosInstance) => {
  return useMemo(() => GoogleImage(axios), [axios]);
};
