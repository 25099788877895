import React, { createContext, useCallback, useRef, useState } from "react";

const AuthContext = createContext<{
  authenticated: boolean;
  getAccessToken: () => string | null;
  logout: () => void;
  login: (accessToken: string, refreshToken: string) => void;
  refreshAccessToken: (accessToken: string) => void;
  refreshTokenRef: React.MutableRefObject<string | null>;
} | null>(null);

const AuthProvider = (props: { children: React.ReactNode }) => {
  const [authenticated, setAuthenticated] = useState<boolean>(true);
  const refreshTokenRef = useRef<string | null>(
    localStorage.getItem("refreshToken"),
  );

  const logout = useCallback(async () => {
    localStorage.setItem("accessToken", "");
    localStorage.setItem("refreshToken", "");
    setAuthenticated(false);
  }, []);

  const login = useCallback(
    async (accessToken: string, refreshToken: string) => {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      refreshTokenRef.current = refreshToken;
      setAuthenticated(true);
    },
    [],
  );

  const getAccessToken = useCallback(() => {
    return localStorage.getItem("accessToken");
  }, []);

  const refreshAccessToken = useCallback(async (accessToken: string) => {
    localStorage.setItem("accessToken", accessToken);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        getAccessToken,
        logout,
        refreshAccessToken,
        refreshTokenRef,
        login,
        authenticated,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export { AuthProvider, AuthContext, useAuth };
