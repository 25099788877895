import { useLayoutEffect, useState } from "react";

/**
 * This hook is used to check if the ref element has overflow in the `x` or `y` dimension, it means that
 * its content is too long to fit in the element. Useful to handle conditional rendering or styling.
 * The hook has an opional dependency array to control when to recalculate the `hasOverflow` state.
 */

type OverflowDimension = "x" | "y";

type HasOverflowProps = {
  ref: React.RefObject<HTMLElement>;
  dimension: OverflowDimension;
  dependencies?: React.DependencyList;
};

export const useHasOverflow = ({
  ref,
  dimension = "y",
  dependencies = [],
}: HasOverflowProps) => {
  const [hasOverflow, setHasOverflow] = useState<boolean | null>(null);

  const measure = () => {
    if (ref === undefined) {
      throw new Error(
        "useHasOverflow expects to be called with a valid HTML element ref.",
      );
    }
    if (!ref.current) return;
    const { clientWidth, scrollWidth, clientHeight, scrollHeight } =
      ref.current;
    switch (dimension) {
      case "x": {
        setHasOverflow(scrollWidth > clientWidth);
        break;
      }
      case "y": {
        setHasOverflow(scrollHeight > clientHeight);
        break;
      }
    }
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", measure);
    measure();
    return () => {
      window.removeEventListener("resize", measure);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, dimension, ...dependencies]);

  return hasOverflow;
};
