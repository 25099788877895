import { AxiosInstance } from "axios";
import { useMemo } from "react";

import Entity, { EntityReturn } from "@/requests/AbstractRessources";

export interface Country {
  id: string;
  name: string;
}

const Country = (axios: AxiosInstance): EntityReturn<Country> => {
  return {
    ...Entity("countries", axios),
  };
};

export const useCountry = (axios: AxiosInstance) => {
  return useMemo(() => Country(axios), [axios]);
};
