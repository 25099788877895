import { useInfiniteQuery } from "@tanstack/react-query";
import Compressor from "compressorjs";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import { Button } from "@/components/Button";
import { Icon } from "@/components/Icon";
import { TextInputLabel } from "@/components/TextInput";
import { useAxios } from "@/containers/AxiosContext";
import { GoogleImage, useGoogleImage } from "@/requests/GoogleImage";
import { Image, ImageForm } from "@/requests/Image";
import { Place } from "@/requests/Place";
import { useVirtualizer } from "@/utils/useVirtualizer";

type PlaceImageState = {
  placeId: Place["id"];
  images: ImageForm[];
  addImage: (image: ImageForm) => void;
  removeImage: (image: ImageForm) => void;
  getBase64Images: () => Promise<ImageForm[]>;
};

export const usePlaceImageState = (props: {
  initialImages: Image[];
  placeId: Place["id"];
}): PlaceImageState => {
  const [images, setImages] = React.useState<ImageForm[]>(props.initialImages);

  const addImage = (image: ImageForm) => {
    setImages([...images, image]);
  };

  const removeImage = (image: ImageForm) => {
    setImages(images.filter((i) => i.name !== image.name));
  };

  const getBase64Images = async (): Promise<ImageForm[]> => {
    const base64Promises = images.map(async (image) => {
      const base64image = await fetch(image.image)
        .then((response) => response.blob())
        .then((blob: Blob): Promise<Blob> => {
          return new Promise((res) => {
            if (
              blob.size < 300000 ||
              !image.width ||
              !image.height ||
              image.id
            ) {
              res(blob);
              return;
            }
            new Compressor(blob, {
              quality: 0.8,
              width: image.width / 2,
              height: image.height / 2,
              success: (result: Blob) => {
                res(result);
              },
            });
          });
        })
        .then((blob: Blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          return new Promise((res) => {
            reader.onloadend = () => {
              res(reader.result as string);
            };
          });
        });
      return {
        ...image,
        image: base64image as string,
      };
    });
    return Promise.all(base64Promises);
  };

  React.useEffect(() => {
    setImages(
      props.initialImages.map((image) => ({
        ...image,
        image: `${process.env.REACT_APP_API_URL}image-proxy/?url=${image.image}`,
      })),
    );
  }, [props.initialImages]);

  return {
    placeId: props.placeId,
    images,
    addImage,
    removeImage,
    getBase64Images,
  };
};

export const PlaceImageSuggestion = (props: { state: PlaceImageState }) => {
  const { authAxios } = useAxios();
  const googleImage = useGoogleImage(authAxios);
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const { data, hasNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: ["place-info-suggestions", props.state.placeId],
    queryFn: ({ pageParam: start }) =>
      googleImage.get({
        params: {
          place: props.state.placeId,
          rights:
            "cc_publicdomain|cc_attribute|cc_sharealike|cc_noncommercial|cc_nonderived",
          start,
        },
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage: any) => lastPage.start,
    enabled: displaySuggestions,
  });

  const items: GoogleImage[] =
    data?.pages
      .map((page) => page.images)
      .flat()
      .filter(
        (image) =>
          !props.state.images.find(
            (stateImage) => stateImage.name === image.title,
          ),
      ) ?? [];

  const { listRef, virtualItems } = useVirtualizer({
    items,
    hasNextPage,
    isLoading: isFetchingNextPage,
    fetchNextPage: () => {},
    horizontal: true,
    estimateSize: () => 300,
  });

  const onAddClick = (item: GoogleImage) => {
    props.state.addImage({
      id: null,
      image: item.link,
      place: props.state.placeId,
      name: item.title,
      is_window_image: true,
      width: item.image.width,
      height: item.image.height,
    });
  };

  if (!displaySuggestions) {
    return (
      <div className="flex items-center justify-center">
        <Button onClick={() => setDisplaySuggestions(true)}>
          Show suggestions
        </Button>
      </div>
    );
  }

  return (
    <>
      <TextInputLabel scale="sm">Suggested images</TextInputLabel>
      <div
        ref={listRef}
        className="mt-4 min-h-60 max-w-full overflow-x-auto overflow-y-hidden"
      >
        {!!virtualItems.length && (
          <div
            className="flex min-h-60 items-center justify-center"
            style={{
              position: "relative",
            }}
          >
            {virtualItems.map((virtualItem) => {
              const item = items[virtualItem.index];
              return (
                <div
                  key={virtualItem.index}
                  className="flex items-center"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: virtualItem.size,
                    height: "100%",
                    transform: `translateX(${virtualItem.start}px)`,
                  }}
                >
                  <div className="relative">
                    <Button
                      iconOnly
                      circle
                      className="absolute -right-1 -top-3"
                      onClick={() => onAddClick(item)}
                    >
                      <Icon name="plus" />
                    </Button>
                    <img
                      src={item.link}
                      alt=""
                      width={300}
                      className="max-h-52 px-2"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export const PlaceImageDropzone = (props: { state: PlaceImageState }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        props.state.addImage({
          id: null,
          is_window_image: true,
          image: URL.createObjectURL(file),
          name: file.name,
          place: props.state.placeId,
        });
      });
    },
    onDropRejected: (fileRejections) => {
      let fileInvalidType = false;
      fileRejections.forEach((fileRejection) => {
        fileRejection.errors.forEach((error) => {
          if (error.code === "file-invalid-type") {
            fileInvalidType = true;
          }
        });
      });
      if (fileInvalidType) {
        toast.error("Wrong file type");
      }
    },
  });

  return (
    <div
      className="flex min-h-36 cursor-pointer items-center justify-center rounded border-2 border-dotted border-primary-on bg-secondary-bg"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div className="flex flex-col items-center justify-center gap-2 text-primary-on">
        <Icon name="photo-rectangle" size={36} />
        <span className="font-semibold">
          Drag &apos;n&apos; drop some files here, or click to select files
        </span>
        <span className="text-sm">Max file size is 10Mb</span>
      </div>
    </div>
  );
};

const PlaceImageDisplay = (props: { state: PlaceImageState }) => {
  if (!props.state.images.length) return null;

  return (
    <>
      <TextInputLabel scale="sm">Place images</TextInputLabel>
      <div className="flex min-h-60 items-center gap-2">
        {props.state.images.map((image, index) => (
          <div key={index} className="relative">
            <Button
              iconOnly
              circle
              className="absolute -right-1 -top-3"
              onClick={() => props.state.removeImage(image)}
            >
              <Icon name="trash" />
            </Button>
            <img
              src={image.image}
              alt=""
              width={300}
              className="max-h-52 px-2"
            />
          </div>
        ))}
      </div>
    </>
  );
};

export const PlaceImages = (props: { state: PlaceImageState }) => {
  return (
    <>
      <PlaceImageSuggestion state={props.state} />
      <PlaceImageDropzone state={props.state} />
      <PlaceImageDisplay state={props.state} />
    </>
  );
};
