import { AxiosInstance } from "axios";
import { useMemo } from "react";

import Entity, { EntityReturn } from "@/requests/AbstractRessources";

export type SessionStatus =
  | "pending"
  | "waiting_reviewer"
  | "in_review"
  | "refused"
  | "accepted";

export interface Session {
  id: string;
  created_at: string;
  user: string;
  quality_control_user: string;
  refused_places: number;
  status: SessionStatus;
  validation_invoice: string;
  quality_control_invoice: string;
}

export type SessionReturn = EntityReturn<Session>;

const Session = (axios: AxiosInstance): SessionReturn => {
  return {
    ...Entity("sessions", axios),
  };
};

export const useSession = (axios: AxiosInstance) => {
  return useMemo(() => Session(axios), [axios]);
};
