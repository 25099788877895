import React from "react";

import { useUserMe } from "@/containers/UserMeContext";

export const useIsSuperuser = () => {
  const { userMe } = useUserMe();
  if (!userMe) return false;
  return userMe.is_superuser;
};

export const IsSuperuser = (props: { children: React.ReactNode }) => {
  const isSuperuser = useIsSuperuser();

  if (!isSuperuser) return null;

  return <>{props.children}</>;
};
