import { useMutation } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Button } from "@/components/Button";
import { Card } from "@/components/Card";
import { Icon } from "@/components/Icon";
import { useAuth } from "@/containers/AuthContext";
import { useAxios } from "@/containers/AxiosContext";
import { TextInputField } from "@/containers/fields/TextInputField";
// @ts-ignore
import { ReactComponent as Logo } from "@/images/logo.svg";
import { TokenForm, useToken } from "@/requests/Token";

export const UsernameField = () => {
  return <TextInputField label="Username" name="username" required />;
};

export const PasswordField = () => {
  const [type, setType] = React.useState<"password" | "text">("password");
  const toggle = useCallback(() => {
    setType((type) => (type === "password" ? "text" : "password"));
  }, []);

  return (
    <TextInputField
      label="Password"
      name="password"
      type={type}
      required
      endAdornment={({ buttonScale }) => (
        <Button appearance="text" iconOnly scale={buttonScale} variant="text">
          <Icon
            name={type === "password" ? "eye" : "eye-slash"}
            onClick={toggle}
          />
        </Button>
      )}
    />
  );
};

export const Login = () => {
  const { login } = useAuth();
  const { publicAxios } = useAxios();
  const navigate = useNavigate();
  const token = useToken(publicAxios);
  const methods = useForm<TokenForm>();
  const mutation = useMutation({
    mutationFn: (data: TokenForm) => token.create(data),
  });

  const onSubmit = async (data: TokenForm) => {
    try {
      const response = await mutation.mutateAsync(data);
      login(response.access, response.refresh);
      navigate("/");
    } catch (error) {
      toast.error("Invalid username or password");
    }
  };

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <FormProvider {...methods}>
        <Card className="item flex w-96 flex-col gap-4">
          <div className="flex  items-center justify-center">
            <Logo className="h-20 w-20" />
          </div>
          <UsernameField />
          <PasswordField />
          <Button
            onClick={methods.handleSubmit(onSubmit)}
            className="w-full"
            isLoading={mutation.isPending}
          >
            Login
          </Button>
        </Card>
      </FormProvider>
    </div>
  );
};
