import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { EnumSelect, useEnumSelectState } from "@/components/Select/EnumSelect";

interface BooleanItem {
  name: string;
  value: string;
}

export const BooleanFilter = (props: {
  name: string;
  label: string;
  trueLabel: string;
  falseLabel: string;
}) => {
  const { name, label, trueLabel, falseLabel } = props;

  const { register, watch, setValue } = useFormContext();

  useEffect(() => {
    register(name);
  }, [register, name]);

  const formValue = watch(name);

  const items: BooleanItem[] = useMemo(
    () => [
      {
        name: trueLabel,
        value: "true",
      },
      {
        name: falseLabel,
        value: "false",
      },
    ],
    [trueLabel, falseLabel],
  );

  const select = useEnumSelectState({
    value: items.find((item) => item.value === formValue) || null,
    items,
    onChange: (item) => setValue(name, item?.value ?? null),
    labelSelector: (item) => item.name,
    valueSelector: (item) => item.value,
  });

  return (
    <div>
      <EnumSelect
        state={select}
        placeholder={label}
        scale="sm"
        popoverSameWidth={false}
      />
    </div>
  );
};
