import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Router } from "@/Router";
import { AuthProvider } from "@/containers/AuthContext";
import { AxiosProvider } from "@/containers/AxiosContext";

import "./index.css";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AxiosProvider>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            draggable
            pauseOnHover
            closeOnClick
            hideProgressBar
            theme="colored"
          />
          <Router />
        </AxiosProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
