import { useInfiniteQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import React from "react";

import { Button } from "@/components/Button";
import { Chip } from "@/components/Chip";
import { Icon } from "@/components/Icon";
import { Tooltip } from "@/components/Tooltip";
import { useAxios } from "@/containers/AxiosContext";
import { useUserAccount } from "@/containers/account/Account";
import {
  SessionPlaceDialogProvider,
  SessionPlacesDialog,
  useSessionPlaceDialog,
} from "@/containers/account/AccoutSessionPlaces";
import { List, ListTitle, useListState } from "@/containers/list/List";
import { Session, SessionStatus, useSession } from "@/requests/Session";

const sessionNameTdProps = {
  render: (props: { item: Session }) => {
    return <div>{props.item.id}</div>;
  },
  minWidth: 400,
};

const sessionCreatedAtTdProps = {
  render: (props: { item: Session }) => {
    return <div>{dayjs(props.item.created_at).format("DD/MM/YYYY")}</div>;
  },
  minWidth: 200,
};

const sessionRefusedPlacesTdProps = {
  render: (props: { item: Session }) => {
    return <Chip scale="sm">{props.item.refused_places}</Chip>;
  },
  minWidth: 100,
};

const getStatusColor = (status: SessionStatus) => {
  switch (status) {
    case "pending":
      return "warning";
    case "accepted":
      return "success";
    case "refused":
      return "danger";
    case "waiting_reviewer":
      return "secondary";
    default:
      return "text";
  }
};

const getStatusText = (status: SessionStatus) => {
  switch (status) {
    case "pending":
      return "Pending";
    case "accepted":
      return "Accepted";
    case "refused":
      return "Refused";
    case "in_review":
      return "In review";
    case "waiting_reviewer":
      return "Waiting reviewer";
    default:
      return "Unknown";
  }
};

const sessionStatusTdProps = {
  render: (props: { item: Session }) => {
    return (
      <Chip scale="sm" tint={getStatusColor(props.item.status)}>
        {getStatusText(props.item.status)}
      </Chip>
    );
  },
  minWidth: 200,
};

const SessionType = (props: { item: Session }) => {
  const { userAccount } = useUserAccount();
  return (
    <Chip
      scale="sm"
      tint={props.item.user === userAccount.id ? "primary" : "text"}
    >
      {props.item.user === userAccount.id ? "Validator" : "Quality controller"}
    </Chip>
  );
};

const sessionTypeTdProps = {
  render: (props: { item: Session }) => <SessionType item={props.item} />,
  minWidth: 200,
};

const SessionPayment = (props: { item: Session }) => {
  const { userAccount } = useUserAccount();
  const invoiceType =
    props.item.user === userAccount.id
      ? "validation_invoice"
      : "quality_control_invoice";
  return (
    <Tooltip
      tooltip={
        props.item[invoiceType] ? `Invoice: ${props.item[invoiceType]}` : null
      }
    >
      <Chip scale="sm" tint={props.item[invoiceType] ? "success" : "danger"}>
        {props.item[invoiceType] ? "Paid" : "Not paid"}
      </Chip>
    </Tooltip>
  );
};

const sessionPaymentTdProps = {
  render: (props: { item: Session }) => {
    return <SessionPayment item={props.item} />;
  },
  minWidth: 100,
};

const ActionTd = (props: { item: Session }) => {
  const { open } = useSessionPlaceDialog();

  return (
    <Tooltip tooltip="Show places">
      <Button
        onClick={() => open(props.item)}
        iconOnly
        appearance="text"
        variant="text"
      >
        <Icon name="eye" />
      </Button>
    </Tooltip>
  );
};

const sessionActionTdProps = {
  render: (props: { item: Session }) => {
    return <ActionTd item={props.item} />;
  },
};

const sessionHeaders = [
  {
    render: () => "Session ID",
    minWidth: 400,
  },
  {
    render: () => "Date",
    minWidth: 200,
  },
  {
    render: () => "Failed",
    minWidth: 100,
  },
  {
    render: () => "Status",
    minWidth: 200,
  },
  {
    render: () => "Role",
    minWidth: 200,
  },
  {
    render: () => "Payment",
    minWidth: 100,
  },
];

export const AccountSessions = () => {
  const { authAxios } = useAxios();
  const session = useSession(authAxios);
  const { userAccount } = useUserAccount();

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ["account-sessions", userAccount.id],
      initialPageParam: 1,
      getNextPageParam: (lastPage: any) => lastPage.next,
      queryFn: ({ pageParam: page }) =>
        session.get({
          params: {
            page,
            user_agent: userAccount.id,
          },
        }),
    });

  const sessionList = useListState({
    items: data?.pages.flatMap((page) => page.results) ?? [],
    fetchNextPage,
    isLoading: isFetchingNextPage,
    hasNextPage,
    totalCount: data?.pages[0].count ?? 0,
    columns: [
      sessionNameTdProps,
      sessionCreatedAtTdProps,
      sessionRefusedPlacesTdProps,
      sessionStatusTdProps,
      sessionTypeTdProps,
      sessionPaymentTdProps,
    ],
    action: sessionActionTdProps,
    headers: sessionHeaders,
  });

  return (
    <SessionPlaceDialogProvider>
      <ListTitle state={sessionList}>Sessions</ListTitle>
      <List state={sessionList} isLoading={!data && isFetching} />
      <SessionPlacesDialog />
    </SessionPlaceDialogProvider>
  );
};
