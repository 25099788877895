import { AxiosInstance } from "axios";
import { useMemo } from "react";

import Entity, {
  EntityReturn,
  ExpandableField,
} from "@/requests/AbstractRessources";
import { City } from "@/requests/City";
import { CommercialInfo } from "@/requests/CommercialInfo";
import { Location } from "@/requests/Location";
import { Period } from "@/requests/Period";
import { Place } from "@/requests/Place";
import { Type } from "@/requests/Type";

type SessionPlaceStatus = "accepted" | "refused";

export interface SessionPlace<T = string> {
  id: string;
  place: ExpandableField<T, "place">;
  session: number;
  sent: number;
  reviewed: number;
  status: SessionPlaceStatus;
  refused_comment: string;
}

export interface SessionPlaceReviewForm {
  status: SessionPlaceStatus;
  refused_comment?: string;
}

export type SessionPlaceReportReason =
  | "form_error"
  | "unwanted_place"
  | "no_type";

export interface SessionPlaceReportForm {
  reason: SessionPlaceReportReason;
}

export interface SessionPlaceReturn<T> extends EntityReturn<SessionPlace<T>> {
  current(): Promise<
    SessionPlace<{
      place: Place<{
        city: City;
        commercial_info: CommercialInfo;
        types: Type;
        locations: Location;
        periods: Period;
      }>;
    }>
  >;
  send(): Promise<SessionPlace<{ place: Place }>>;
  currentQualityControl(): Promise<
    SessionPlace<{
      place: Place<{
        city: City;
        commercial_info: CommercialInfo;
        types: Type;
        locations: Location;
        periods: Period;
      }>;
    }>
  >;
  review(data: SessionPlaceReviewForm): Promise<SessionPlace>;
  report(data: SessionPlaceReportForm): Promise<SessionPlace>;
}

const SessionPlace = <T>(axios: AxiosInstance): SessionPlaceReturn<T> => {
  return {
    ...Entity("session-places", axios),
    current() {
      return this.request({
        method: "GET",
        url: `${this.url}current/`,
      });
    },
    send() {
      return this.request({
        method: "GET",
        url: `${this.url}send/`,
      });
    },
    currentQualityControl() {
      return this.request({
        method: "GET",
        url: `${this.url}current-quality-control/`,
      });
    },
    review(data: SessionPlaceReviewForm) {
      return this.request({
        method: "POST",
        url: `${this.url}review/`,
        data,
      });
    },
    report(data: SessionPlaceReportForm) {
      return this.request({
        method: "POST",
        url: `${this.url}report/`,
        data,
      });
    },
  };
};

export const useSessionPlace = <T>(axios: AxiosInstance) => {
  return useMemo(() => SessionPlace<T>(axios), [axios]);
};
