import { AxiosInstance } from "axios";
import { useMemo } from "react";

import Entity, { EntityReturn } from "@/requests/AbstractRessources";

export interface Group {
  id: string;
  name: string;
  permissions: number[];
}

export type GroupReturn = EntityReturn<Group>;

const Group = (axios: AxiosInstance): GroupReturn => {
  return {
    ...Entity("groups", axios),
  };
};

export const useGroup = (axios: AxiosInstance) => {
  return useMemo(() => Group(axios), [axios]);
};
