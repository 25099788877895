import ReactSwitch from "react-switch";

type SwitchProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  scale?: SwitchScales;
};

type SwitchScales = "md" | "lg";

type SwitchWidthHeight = {
  width: number;
  height: number;
};

const scales: Record<SwitchScales, SwitchWidthHeight> = {
  md: {
    width: 40,
    height: 24,
  },
  lg: {
    width: 48,
    height: 28,
  },
};

export const Switch = (props: SwitchProps) => {
  const { scale = "md", onChange, checked } = props;
  return (
    <ReactSwitch
      checked={checked}
      onChange={onChange}
      onColor="#ED805D"
      offColor="#F4F4F4"
      uncheckedIcon={false}
      checkedIcon={false}
      width={scales[scale].width}
      height={scales[scale].height}
    />
  );
};
