import { AxiosInstance } from "axios";
import { useMemo } from "react";

import Entity, { EntityOptions } from "@/requests/AbstractRessources";
import { Location } from "@/requests/Location";

export interface GoogleLocation {
  address: string;
  latitude: number;
  longitude: number;
  locations: Location[];
}

interface GoogleLocationReturn {
  get: (options?: EntityOptions) => Promise<GoogleLocation>;
}

const GoogleLocation = (axios: AxiosInstance): GoogleLocationReturn => {
  return {
    async get({
      params = {},
      headers = {},
    }: EntityOptions = {}): Promise<GoogleLocation> {
      const { locations } = await Entity("location-suggestions", axios).request(
        {
          params: {
            ...params,
          },
          headers,
        },
      );

      return locations;
    },
  };
};

export const useGoogleLocation = (axios: AxiosInstance) => {
  return useMemo(() => GoogleLocation(axios), [axios]);
};
