import { AxiosInstance } from "axios";
import { useMemo } from "react";

import Entity, { EntityOptions } from "@/requests/AbstractRessources";

export interface OpenaiType {
  type: string;
  sub_type: string;
}

export interface OpenaiTypeReturn {
  get: (options?: EntityOptions) => Promise<OpenaiType>;
}

const OpenaiType = (axios: AxiosInstance): OpenaiTypeReturn => {
  return {
    async get({
      params = {},
      headers = {},
    }: EntityOptions = {}): Promise<OpenaiType> {
      const { types } = await Entity("type-suggestions", axios).request({
        params: {
          ...params,
        },
        headers,
      });

      return types;
    },
  };
};

export const useOpenaiType = (axios: AxiosInstance) => {
  return useMemo(() => OpenaiType(axios), [axios]);
};
