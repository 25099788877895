import { AxiosInstance } from "axios";
import { useMemo } from "react";

import Entity from "@/requests/AbstractRessources";

export interface Statistics {
  image_number: number;
  image_clean_number: number;
  place_number: number;
  place_clean_number: number;
  user_number: number;
  validated_place_number: number;
  has_issue_place_number: number;
  session_left_to_clean: number;
}

interface StatisticsReturn {
  get: () => Promise<Statistics>;
}

const Statistics = (axios: AxiosInstance): StatisticsReturn => {
  return {
    async get(): Promise<Statistics> {
      const { statistics } = await Entity("statistics", axios).request({});
      return statistics;
    },
  };
};

export const useStatistics = (axios: AxiosInstance) => {
  return useMemo(() => Statistics(axios), [axios]);
};
