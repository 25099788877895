import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useRef, useState } from "react";

import { Button } from "@/components/Button";
import { Loader } from "@/components/Loader";
import { useAxios } from "@/containers/AxiosContext";
import {
  PlaceInfoForm,
  PlaceInfoFormRef,
} from "@/containers/place/PlaceInfoForm";
import {
  ExpandedPotentialDuplicate,
  usePotentialDuplicate,
} from "@/requests/PotentialDuplicate";

import { CardNav } from "../../CardNav";

const PlaceInfoFormContainer = (props: {
  onSubmit: (placeId: string) => void;
  isSubmitting: boolean;
  data: ExpandedPotentialDuplicate["places"];
  isLoading?: boolean;
}) => {
  const { onSubmit, isSubmitting, data, isLoading } = props;

  const placeInfoRef = useRef<PlaceInfoFormRef>(null);

  const onClick = useCallback(() => {
    placeInfoRef.current?.onSubmit();
    onSubmit(data.id);
  }, [onSubmit, data.id]);

  return (
    <PlaceInfoForm
      ref={placeInfoRef}
      data={data}
      className="relative w-1/2 rounded border p-2"
    >
      {isSubmitting && (
        <Button
          className="sticky -right-0 -top-0 z-50"
          variant="success"
          onClick={onClick}
          isLoading={isLoading}
        >
          Keep this place
        </Button>
      )}
    </PlaceInfoForm>
  );
};

const PotentialDuplicateFooter = (props: {
  onDuplicateClick: () => void;
  onNotDuplicateClick: () => void;
  isLoading?: boolean;
}) => {
  return (
    <div className="mt-4 flex items-center justify-between">
      <Button
        isLoading={props.isLoading}
        variant="danger"
        onClick={props.onNotDuplicateClick}
      >
        Not a duplicate
      </Button>
      <Button isLoading={props.isLoading} onClick={props.onDuplicateClick}>
        Is a duplicate
      </Button>
    </div>
  );
};

export const PotentialDuplicate = () => {
  const queryClient = useQueryClient();
  const { authAxios } = useAxios();
  const potentialDuplicate =
    usePotentialDuplicate<ExpandedPotentialDuplicate>(authAxios);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["potential-duplicate"],
    queryFn: () => potentialDuplicate.current(),
    retry: 0,
  });

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["potential-duplicate"],
    mutationFn: (data: { is_duplicate: boolean; good_place?: string }) =>
      potentialDuplicate.validate(data),
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onDuplicateClick = useCallback(() => {
    setIsSubmitting(true);
  }, []);

  const onNotDuplicateClick = useCallback(async () => {
    if (!data) return;
    await mutateAsync({
      is_duplicate: false,
    });
    queryClient.invalidateQueries({ queryKey: ["potential-duplicate"] });
  }, [data, mutateAsync, queryClient]);

  const onKeepPlaceClick = useCallback(
    async (placeId: string) => {
      if (!data) return;
      await mutateAsync({
        is_duplicate: true,
        good_place: placeId,
      });
      queryClient.invalidateQueries({ queryKey: ["potential-duplicate"] });
    },
    [data, mutateAsync, queryClient],
  );

  return (
    <CardNav title="Potential duplicate">
      {isLoading && (
        <div className="flex flex-1 items-center justify-center">
          <Loader />
        </div>
      )}
      {!isLoading && data && !isError && (
        <>
          <div className="flex flex-1 gap-6 overflow-auto">
            {data.places.map((place) => (
              <PlaceInfoFormContainer
                key={place.id}
                data={place}
                onSubmit={onKeepPlaceClick}
                isSubmitting={isSubmitting}
                isLoading={isPending}
              />
            ))}
          </div>
          <PotentialDuplicateFooter
            onDuplicateClick={onDuplicateClick}
            onNotDuplicateClick={onNotDuplicateClick}
            isLoading={isPending}
          />
        </>
      )}
      {isError && (
        <div className="flex flex-1 items-center justify-center font-semibold">
          No more potential duplicate available, feel free to contact the
          support
        </div>
      )}
    </CardNav>
  );
};
