import { AxiosInstance } from "axios";
import { useMemo } from "react";

import Entity, {
  EntityReturn,
  ExpandableField,
} from "@/requests/AbstractRessources";

type PeriodDay = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export interface Period<T = string> {
  id: string;
  day: PeriodDay;
  start_hour: number;
  end_hour: number;
  start_min: number;
  end_min: number;
  period_number: number;
  exported_at: string;
  place: ExpandableField<T, "place">;
}

export interface PeriodInitialForm {
  id?: string;
  day?: PeriodDay;
  start_hour?: string;
  end_hour?: string;
  period_number?: number;
  place?: string;
}

export interface PeriodFinalForm extends PeriodInitialForm {
  day: PeriodDay;
  start_hour: string;
  end_hour: string;
  place: string;
}

export interface PeriodForm {
  id?: string;
  day: PeriodDay;
  start_hour: number;
  start_min: number;
  end_hour: number;
  end_min: number;
  place: string;
}

export interface PeriodReturn extends EntityReturn<Period> {
  bulkUpdate(placeId: string, data: PeriodForm[]): Promise<Period[]>;
}

const Period = (axios: AxiosInstance): PeriodReturn => {
  return {
    ...Entity("periods", axios),

    bulkUpdate(placeId, data) {
      return this.request({
        method: "PUT",
        url: `${this.url}place/${placeId}/bulk-update/`,
        data,
      });
    },
  };
};

export const usePeriod = (axios: AxiosInstance) => {
  return useMemo(() => Period(axios), [axios]);
};
