import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { Button } from "@/components/Button";
import { useComboboxStore } from "@/components/Combobox";
import { Icon } from "@/components/Icon";
import { useSliderStore } from "@/components/Slider";
import { Switch } from "@/components/Switch";
import { Tooltip } from "@/components/Tooltip";
import { useAxios } from "@/containers/AxiosContext";
import {
  Column,
  ColumnHeader,
  List,
  ListTitle,
  ListToolbar,
  useListState,
} from "@/containers/list/List";
import { CardNav } from "@/containers/routes/CardNav";
import { UserEditPasswordSlider } from "@/containers/routes/users/UserEditPasswordSlider";
import { UserSlider } from "@/containers/routes/users/UserSlider";
import { Group } from "@/requests/Group";
import { User, useUser } from "@/requests/User";
import { useDebounce } from "@/utils/useDebounce";

export type ExpandUserField = { groups: Group };

type UserTableTdProps = {
  item: User<ExpandUserField>;
  children: React.ReactNode;
};

const UserTableTd = (props: UserTableTdProps) => {
  const { item } = props;
  return (
    <>
      <Link to={`/users/${item.id}`}>{props.children}</Link>
    </>
  );
};

const userUsernameTdProps: Column<User<ExpandUserField>> = {
  render: (props: { item: User<ExpandUserField> }) => {
    return <UserTableTd item={props.item}>{props.item.username}</UserTableTd>;
  },
};

const userNameTdProps: Column<User<ExpandUserField>> = {
  render: (props: { item: User<ExpandUserField> }) => {
    return (
      <UserTableTd item={props.item}>
        <div className="flex flex-col">
          {props.item.first_name} {props.item.last_name}
          <span className="text-text-on-light">{props.item.email}</span>
        </div>
      </UserTableTd>
    );
  },
  minWidth: 400,
};

const userValidatedPlacesTdProps: Column<User<ExpandUserField>> = {
  render: (props: { item: User<ExpandUserField> }) => {
    return (
      <UserTableTd item={props.item}>{props.item.verified_places}</UserTableTd>
    );
  },
  minWidth: 150,
};

const userControlledPlacesTdProps: Column<User<ExpandUserField>> = {
  render: (props: { item: User<ExpandUserField> }) => {
    return (
      <UserTableTd item={props.item}>
        {props.item.quality_controlled_place}
      </UserTableTd>
    );
  },
  minWidth: 150,
};

const userRoleTdProps: Column<User<ExpandUserField>> = {
  render: (props: { item: User<ExpandUserField> }) => {
    return (
      <UserTableTd item={props.item}>
        {props.item.groups.map((group) => group.name).join(", ")}
      </UserTableTd>
    );
  },
  minWidth: 150,
};

const UserTableRowActionButton = (props: {
  icon: string;
  tooltip: string;
  onClick: () => void;
}) => {
  return (
    <Tooltip tooltip={props.tooltip}>
      <Button iconOnly appearance="text" variant="text" onClick={props.onClick}>
        <Icon name={props.icon} />
      </Button>
    </Tooltip>
  );
};

const UserSlidersContext = React.createContext<{
  openUserSlider: (user: User<ExpandUserField>) => void;
  openUserEditPasswordSlider: (user: User<ExpandUserField>) => void;
} | null>(null);

const UserSlidersProvider = (props: { children: React.ReactNode }) => {
  const { authAxios } = useAxios();
  const user = useUser(authAxios);

  const userSlider = useSliderStore();
  const userEditPasswordSlider = useSliderStore();

  const [currentUser, setCurrentUser] =
    React.useState<User<ExpandUserField> | null>(null);

  const openUserSlider = (user: User<ExpandUserField>) => {
    setCurrentUser(user);
    userSlider.show();
  };

  const openUserEditPasswordSlider = (user: User<ExpandUserField>) => {
    setCurrentUser(user);
    userEditPasswordSlider.show();
  };

  const defaultValues = useMemo(() => {
    if (!currentUser) return null;
    return {
      ...currentUser,
      groups: currentUser?.groups?.map((group) => group.id),
    };
  }, [currentUser]);

  return (
    <UserSlidersContext.Provider
      value={{ openUserSlider, openUserEditPasswordSlider }}
    >
      {props.children}

      <UserSlider
        user={user}
        slider={userSlider}
        defaultValues={defaultValues}
      />
      <UserEditPasswordSlider
        slider={userEditPasswordSlider}
        user={user}
        userEntity={currentUser}
      />
    </UserSlidersContext.Provider>
  );
};

const useUserSlider = () => {
  const context = React.useContext(UserSlidersContext);
  if (!context) {
    throw new Error("useUserSlider must be used within a UserSlidersProvider");
  }
  return context;
};

const UserActionsTd = (props: { item: User<ExpandUserField> }) => {
  const { authAxios } = useAxios();
  const user = useUser(authAxios);
  const userSlider = useUserSlider();

  const [checked, setChecked] = React.useState<boolean>(props.item.is_usable);

  const turnOnMutation = useMutation({
    mutationFn: () => user.patch(props.item.id, { data: { is_usable: true } }),
    onSuccess: () => {
      toast.success(`${props.item.username} is now usable`);
    },
  });

  const turnOffMutation = useMutation({
    mutationFn: () => user.patch(props.item.id, { data: { is_usable: false } }),
    onSuccess: () => {
      toast.success(`${props.item.username} is now unusable`);
    },
  });

  const onChange = (checked: boolean) => {
    if (checked) {
      turnOnMutation.mutate();
    } else {
      turnOffMutation.mutate();
    }
    setChecked(checked);
  };

  return (
    <div className="flex gap-2">
      <UserTableRowActionButton
        icon="pencil"
        tooltip="Edit user"
        onClick={() => userSlider.openUserSlider(props.item)}
      />
      <UserTableRowActionButton
        icon="lock"
        tooltip="Change password"
        onClick={() => userSlider.openUserEditPasswordSlider(props.item)}
      />
      <Switch checked={checked} onChange={onChange} />
    </div>
  );
};

const userActionsTdProps: Column<User<ExpandUserField>> = {
  render: (props: { item: User<ExpandUserField> }) => (
    <UserActionsTd item={props.item} />
  ),
};

const usersHeaders: ColumnHeader[] = [
  {
    render: () => "Username",
  },
  {
    render: () => "Name",
    minWidth: 400,
  },
  {
    render: () => "Validated places",
    minWidth: 150,
  },
  {
    render: () => "Controlled places",
    minWidth: 150,
  },
  {
    render: () => "Role",
    minWidth: 150,
  },
];

const userFields = [
  "id",
  "username",
  "first_name",
  "last_name",
  "email",
  "groups",
  "quality_controlled_place",
  "verified_places",
  "is_usable",
];

const UserAddButton = () => {
  const userSlider = useUserSlider();

  return (
    <Button scale="lg" onClick={() => userSlider.openUserSlider(null as any)}>
      <Icon name="plus" />
      Add a user
    </Button>
  );
};

export const Users = () => {
  const { authAxios } = useAxios();
  const user = useUser<ExpandUserField>(authAxios);

  const combobox = useComboboxStore();
  const search = combobox.useState("value");

  const userSlider = useSliderStore();

  const debouncedSearch = useDebounce(search, 500);

  const { data, fetchNextPage, isFetchingNextPage, isFetching, hasNextPage } =
    useInfiniteQuery({
      queryKey: ["users", debouncedSearch],
      initialPageParam: 1,
      queryFn: ({ pageParam: page = 1 }) =>
        user.get({
          params: {
            page,
            search: debouncedSearch,
            fields: userFields,
            expand: ["groups"],
          },
        }),
      getNextPageParam: (lastPage: any) => lastPage.next,
    });

  const userList = useListState({
    items: data?.pages.flatMap((page) => page.results) ?? [],
    fetchNextPage,
    isLoading: isFetchingNextPage,
    hasNextPage,
    totalCount: data?.pages[0].count ?? 0,
    headers: usersHeaders,
    action: userActionsTdProps,
    columns: [
      userUsernameTdProps,
      userNameTdProps,
      userValidatedPlacesTdProps,
      userControlledPlacesTdProps,
      userRoleTdProps,
    ],
  });

  return (
    <UserSlidersProvider>
      <CardNav title="Users">
        <ListTitle state={userList}>Fyndee members</ListTitle>
        <ListToolbar combobox={combobox}>
          <UserAddButton />
        </ListToolbar>
        <List state={userList} isLoading={!data && isFetching} />
        <UserSlider user={user} slider={userSlider} defaultValues={{}} />
      </CardNav>
    </UserSlidersProvider>
  );
};
